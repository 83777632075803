import React from 'react';
import { Link } from 'react-router-dom';
import { ChevronRight, Home } from 'lucide-react';

export interface BreadcrumbItem {
  label: string;
  href?: string;
}

interface BreadcrumbsProps {
  items: BreadcrumbItem[];
  className?: string;
}

export const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ items, className = '' }) => {
  return (
    <nav aria-label="Breadcrumb" className={`text-sm ${className}`}>
      <ol className="flex flex-wrap items-center space-x-1 md:space-x-2">
        <li className="flex items-center">
          <Link
            to="/"
            className="text-gray-500 hover:text-amber-800 transition-colors flex items-center"
            aria-label="Home"
          >
            <Home className="w-4 h-4" />
            <span className="sr-only md:not-sr-only md:ml-1">Home</span>
          </Link>
        </li>

        {items.map((item, index) => (
          <li key={index} className="flex items-center">
            <ChevronRight className="w-4 h-4 text-gray-400 mx-1" aria-hidden="true" />
            {item.href ? (
              <Link
                to={item.href}
                className={`${
                  index === items.length - 1
                    ? 'text-gray-900 font-medium'
                    : 'text-gray-500 hover:text-amber-800 transition-colors'
                }`}
              >
                {item.label}
              </Link>
            ) : (
              <span className="text-gray-900 font-medium" aria-current="page">
                {item.label}
              </span>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};
