import React, { useState } from 'react';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';
import { migrateImagesToCloudinary, cleanupSupabaseStorage } from '@/lib/cloudinary';
import { toast } from 'sonner';
import { CloudIcon, Trash2 } from 'lucide-react';

export const MigrateToCloudinary = () => {
  const [migrating, setMigrating] = useState(false);
  const [cleaning, setCleaning] = useState(false);
  const [progress, setProgress] = useState({ current: 0, total: 0 });

  const handleMigration = async () => {
    if (
      !confirm(
        'Are you sure you want to migrate all images to Cloudinary? This process cannot be undone.'
      )
    ) {
      return;
    }

    setMigrating(true);
    const toastId = toast.loading('Starting migration...');

    try {
      const { migrated, errors } = await migrateImagesToCloudinary((current, total, error) => {
        setProgress({ current, total });
        if (error) {
          toast.error(error, { duration: 3000 });
        } else {
          toast.loading(`Migrating images: ${current}/${total}`, { id: toastId });
        }
      });

      if (errors === 0) {
        toast.success(`Successfully migrated ${migrated} images to Cloudinary`, { id: toastId });
      } else {
        toast.error(
          `Migration completed with ${errors} errors. ${migrated} images were successful.`,
          { id: toastId }
        );
      }
    } catch (error: any) {
      console.error('Migration failed:', error);
      toast.error(`Migration failed: ${error.message}`, { id: toastId });
    } finally {
      setMigrating(false);
      setProgress({ current: 0, total: 0 });
    }
  };

  const handleCleanup = async () => {
    if (
      !confirm(
        'Are you sure you want to clean up Supabase Storage? This will delete all images from storage.'
      )
    ) {
      return;
    }

    setCleaning(true);
    const toastId = toast.loading('Starting cleanup...');

    try {
      const { deleted, total } = await cleanupSupabaseStorage((current, total) => {
        setProgress({ current, total });
        toast.loading(`Cleaning up storage: ${current}/${total}`, { id: toastId });
      });

      toast.success(`Successfully cleaned up ${deleted}/${total} files from storage`, {
        id: toastId,
      });
    } catch (error: any) {
      console.error('Cleanup failed:', error);
      toast.error(`Cleanup failed: ${error.message}`, { id: toastId });
    } finally {
      setCleaning(false);
      setProgress({ current: 0, total: 0 });
    }
  };

  return (
    <Card className="p-6 bg-white rounded-lg shadow">
      <h3 className="text-lg font-medium mb-4">Image Storage Migration</h3>
      <p className="text-gray-600 mb-4">
        Migrate product images from Supabase Storage to Cloudinary for improved performance and
        features. The migration is done in batches with retries to handle any temporary failures.
      </p>
      <div className="flex flex-col sm:flex-row gap-4">
        <Button
          onClick={handleMigration}
          disabled={migrating || cleaning}
          className="flex items-center gap-2"
        >
          {migrating ? (
            <>
              <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-white"></span>
              Migrating... ({progress.current}/{progress.total})
            </>
          ) : (
            <>
              <CloudIcon className="w-4 h-4" />
              Migrate to Cloudinary
            </>
          )}
        </Button>
        <Button
          variant="secondary"
          onClick={handleCleanup}
          disabled={migrating || cleaning}
          className="flex items-center gap-2"
        >
          {cleaning ? (
            <>
              <span className="animate-spin rounded-full h-4 w-4 border-b-2 border-gray-600"></span>
              Cleaning... ({progress.current}/{progress.total})
            </>
          ) : (
            <>
              <Trash2 className="w-4 h-4" />
              Cleanup Storage
            </>
          )}
        </Button>
      </div>
    </Card>
  );
};
