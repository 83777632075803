import React from 'react';
import { Link } from 'react-router-dom';
import { useBlogPosts } from '../Blog/hooks/useBlogPosts';
import { ImageHandler } from '../ui/ImageHandler';
import { Button } from '../ui/Button';
import { ArrowRight, Calendar, User } from 'lucide-react';

export const BlogPreview: React.FC = () => {
  const { posts, loading } = useBlogPosts();

  // Get the latest 3 posts
  const recentPosts = posts.slice(0, 3);

  if (loading) {
    return (
      <section className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-8">
            <h2 className="text-3xl font-medium mb-4">Artist Profiles</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Loading our latest insights and stories...
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="animate-pulse">
                <div className="aspect-[4/3] bg-gray-200 rounded-lg mb-4"></div>
                <div className="bg-gray-200 h-6 w-3/4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (recentPosts.length === 0) {
    return null;
  }

  return (
    <section className="py-12 bg-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-8">
          <h2 className="text-3xl font-medium mb-4">Artist Profiles</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Explore our insights on art trends, artist spotlights, and collecting tips.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {recentPosts.map(post => (
            <article key={post.slug} className="group">
              <Link
                to={`/blog/${post.slug}`}
                className="block aspect-[4/3] rounded-lg overflow-hidden mb-4"
              >
                <ImageHandler
                  src={post.featuredImage}
                  alt={post.title}
                  className="w-full h-full object-cover transition-transform group-hover:scale-105 duration-300"
                  loading="lazy"
                />
              </Link>

              <div>
                <div className="mb-2">
                  {post.categories.slice(0, 1).map(category => (
                    <Link
                      key={category.slug}
                      to={`/blog/category/${category.slug}`}
                      className="text-xs font-medium text-amber-800 hover:text-amber-900"
                    >
                      {category.name}
                    </Link>
                  ))}
                </div>

                <h3 className="text-lg font-medium mb-2 group-hover:text-amber-800 transition-colors line-clamp-2">
                  <Link to={`/blog/${post.slug}`}>{post.title}</Link>
                </h3>

                <div className="flex items-center gap-4 text-sm text-gray-500 mb-2">
                  <span className="flex items-center gap-1">
                    <Calendar className="w-4 h-4" />
                    {post.date}
                  </span>
                  <span className="flex items-center gap-1">
                    <User className="w-4 h-4" />
                    {post.author}
                  </span>
                </div>

                <p className="text-gray-600 mb-4 line-clamp-2">{post.excerpt}</p>

                <Link
                  to={`/blog/${post.slug}`}
                  className="text-amber-800 hover:text-amber-900 font-medium text-sm inline-flex items-center"
                >
                  Read More
                  <ArrowRight className="ml-1 w-4 h-4" />
                </Link>
              </div>
            </article>
          ))}
        </div>

        <div className="text-center mt-8">
          <Link to="/blog">
            <Button variant="secondary">View All Articles</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
