import React from 'react';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { PayPalButton } from './PayPalButton';

const paypalOptions = {
  'client-id': import.meta.env.VITE_PAYPAL_CLIENT_ID,
  currency: 'USD',
  intent: 'capture',
};

interface PaymentButtonsProps {
  amount: number;
  productName: string;
  productId: string;
  onClose?: () => void;
}

export const PaymentButtons: React.FC<PaymentButtonsProps> = props => {
  return (
    <div className="space-y-3">
      <PayPalScriptProvider options={paypalOptions}>
        <PayPalButton {...props} />
      </PayPalScriptProvider>
    </div>
  );
};
