import { createClient } from '@supabase/supabase-js';
import { toast } from 'sonner';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  console.error('Missing Supabase environment variables');
  toast.error('Database configuration error. Please check your environment variables.');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    autoRefreshToken: true,
    persistSession: true,
    detectSessionInUrl: false,
  },
  global: {
    headers: {
      'x-application-name': 'show-pony-gallery',
    },
  },
  realtime: {
    params: {
      eventsPerSecond: 10,
    },
  },
  // Add custom fetch with retry logic and timeout
  fetch: async (url, options = {}) => {
    const MAX_RETRIES = 3;
    const INITIAL_RETRY_DELAY = 1000;
    const MAX_RETRY_DELAY = 5000;
    const TIMEOUT = 30000; // 30 seconds

    let attempt = 0;
    let lastError;

    while (attempt < MAX_RETRIES) {
      try {
        const controller = new AbortController();
        const signal = options.signal || controller.signal;
        const timeoutId = setTimeout(() => controller.abort(), TIMEOUT);

        const response = await fetch(url, {
          ...options,
          signal,
          headers: {
            ...options.headers,
            'Cache-Control': 'no-cache',
            Pragma: 'no-cache',
          },
        });

        clearTimeout(timeoutId);

        if (!response.ok) {
          const error = await response.json().catch(() => ({}));
          throw new Error(error.message || `HTTP error! status: ${response.status}`);
        }

        return response;
      } catch (error: any) {
        lastError = error;
        attempt++;

        if (attempt >= MAX_RETRIES || error.name === 'AbortError') {
          break;
        }

        const delay = Math.min(INITIAL_RETRY_DELAY * Math.pow(2, attempt - 1), MAX_RETRY_DELAY);

        console.warn(
          `Supabase fetch attempt ${attempt} failed:`,
          error.name === 'AbortError' ? 'Request timed out' : error.message,
          `Retrying in ${delay}ms...`
        );

        await new Promise(resolve => setTimeout(resolve, delay));
      }
    }

    throw lastError;
  },
});

// Add connection status check
export const checkSupabaseConnection = async () => {
  try {
    const start = Date.now();
    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 5000); // 5 second timeout

    const { data, error } = await supabase
      .from('products')
      .select('id')
      .limit(1)
      .abortSignal(controller.signal);

    clearTimeout(timeoutId);
    const duration = Date.now() - start;

    if (error) {
      console.error('Supabase connection check failed:', error);
      return {
        connected: false,
        error: error.message,
        latency: duration,
      };
    }

    return {
      connected: true,
      latency: duration,
    };
  } catch (error: any) {
    console.error('Supabase connection check failed:', error);
    return {
      connected: false,
      error: error.message || 'Connection failed',
      latency: null,
    };
  }
};

// Add error handling for the connection
supabase.auth.onAuthStateChange((event, session) => {
  if (event === 'SIGNED_OUT') {
    // Clear any cached data
    supabase.getSubscriptions().map(subscription => {
      supabase.removeSubscription(subscription);
    });
  }
});

// Add connection status check on init
checkSupabaseConnection().then(({ connected, error, latency }) => {
  if (!connected) {
    console.error('Initial Supabase connection failed:', error);
    toast.error('Failed to connect to database. Please refresh the page.');
  } else {
    console.log(`Connected to Supabase (${latency}ms)`);
  }
});

export default supabase;
