import React, { useState } from 'react';
import { Share2, Check } from 'lucide-react';
import { toast } from 'sonner';
import { Button } from './Button';

interface ShareButtonProps {
  title: string;
  description?: string;
  imageUrl?: string;
}

export const ShareButton: React.FC<ShareButtonProps> = ({ title, description, imageUrl }) => {
  const [copied, setCopied] = useState(false);

  // Get the canonical URL
  const getCanonicalUrl = () => {
    // Get the current URL
    const url = new URL(window.location.href);

    // Set the base URL to the production domain
    url.protocol = 'https';
    url.host = 'showponygallery.com';

    // Keep the pathname and search params
    return url.toString();
  };

  const shareUrl = getCanonicalUrl();
  const shareText = `Check out ${title} on Show Pony Gallery${description ? `: ${description}` : ''}`;

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title,
          text: shareText,
          url: shareUrl,
        });
        toast.success('Shared successfully');
      } catch (error) {
        if ((error as Error).name !== 'AbortError') {
          handleCopyLink();
        }
      }
    } else {
      handleCopyLink();
    }
  };

  const handleCopyLink = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      toast.success('Link copied to clipboard');
      setTimeout(() => setCopied(false), 2000);
    } catch (error) {
      toast.error('Failed to copy link');
    }
  };

  // Update meta tags when sharing
  React.useEffect(() => {
    const metaTags = [
      { property: 'og:title', content: title },
      { property: 'og:description', content: description || '' },
      { property: 'og:image', content: imageUrl || '' },
      { property: 'og:url', content: shareUrl },
      { property: 'twitter:card', content: 'summary_large_image' },
      { property: 'twitter:title', content: title },
      { property: 'twitter:description', content: description || '' },
      { property: 'twitter:image', content: imageUrl || '' },
    ];

    // Add meta tags
    metaTags.forEach(({ property, content }) => {
      let meta = document.querySelector(`meta[property="${property}"]`);
      if (!meta) {
        meta = document.createElement('meta');
        meta.setAttribute('property', property);
        document.head.appendChild(meta);
      }
      meta.setAttribute('content', content);
    });

    // Cleanup
    return () => {
      metaTags.forEach(({ property }) => {
        const meta = document.querySelector(`meta[property="${property}"]`);
        if (meta) {
          meta.remove();
        }
      });
    };
  }, [title, description, imageUrl, shareUrl]);

  return (
    <Button
      variant="secondary"
      size="sm"
      onClick={handleShare}
      className="flex items-center gap-2 min-h-[36px] min-w-[90px] px-4"
    >
      {copied ? <Check className="w-4 h-4" /> : <Share2 className="w-4 h-4" />}
      <span className="text-sm whitespace-nowrap">Share</span>
    </Button>
  );
};
