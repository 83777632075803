import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { X, GripVertical, Wand2, Loader2 } from 'lucide-react';
import { ImageHandler } from '../ui/ImageHandler';

interface DraggableImageProps {
  url: string;
  index: number;
  onRemove: () => void;
  onRemoveBackground: () => void;
  isProcessing?: boolean;
}

export const DraggableImage: React.FC<DraggableImageProps> = ({
  url,
  index,
  onRemove,
  onRemoveBackground,
  isProcessing,
}) => {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
    id: url,
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    zIndex: isDragging ? 50 : undefined,
    opacity: isDragging ? 0.5 : undefined,
  };

  return (
    <div
      ref={setNodeRef}
      style={style}
      className={`relative group ${isDragging ? 'ring-2 ring-amber-500' : ''}`}
    >
      <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
        <ImageHandler
          src={url}
          alt={`Product ${index + 1}`}
          className="w-full h-full object-cover"
          priority={index === 0}
        />
        {isProcessing && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
            <div className="flex flex-col items-center gap-2">
              <Loader2 className="w-8 h-8 text-white animate-spin" />
              <p className="text-white text-sm">Processing image...</p>
            </div>
          </div>
        )}
      </div>

      {/* Controls */}
      <div className="absolute inset-x-0 top-2 px-2 flex justify-between items-start">
        {/* Left side - Main Photo label, Drag Handle, and Background Removal */}
        <div className="flex flex-col gap-2">
          {index === 0 && (
            <div className="bg-white/90 text-gray-700 px-2 py-1 rounded-full text-sm font-medium shadow-sm">
              Main Photo
            </div>
          )}
          <div className="flex items-center gap-2">
            <button
              {...attributes}
              {...listeners}
              className="p-1.5 bg-white/90 rounded-full hover:bg-white shadow-sm cursor-grab active:cursor-grabbing"
              onClick={e => e.stopPropagation()}
            >
              <GripVertical className="w-4 h-4 text-gray-700" />
            </button>
            {index === 0 && (
              <button
                onClick={e => {
                  e.preventDefault();
                  e.stopPropagation();
                  onRemoveBackground();
                }}
                disabled={isProcessing}
                className="p-1.5 bg-white/90 rounded-full hover:bg-white shadow-sm disabled:opacity-50 disabled:cursor-not-allowed group/wand"
                title="Remove background"
              >
                <Wand2 className="w-4 h-4 text-amber-600 group-hover/wand:text-amber-700" />
              </button>
            )}
          </div>
        </div>

        {/* Right side - Remove button */}
        <button
          type="button"
          onClick={e => {
            e.preventDefault();
            e.stopPropagation();
            onRemove();
          }}
          disabled={isProcessing}
          className="p-1.5 bg-white/90 rounded-full hover:bg-white transition-colors disabled:opacity-50 shadow-sm"
        >
          <X className="w-4 h-4 text-gray-700" />
        </button>
      </div>
    </div>
  );
};
