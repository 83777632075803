import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import MainLayout from './layouts/MainLayout';
import { AdminLayout } from './components/admin/AdminLayout';
import { LoginForm } from './components/admin/LoginForm';
import { Toaster } from 'sonner';

function App() {
  return (
    <HelmetProvider>
      <Routes>
        <Route path="/admin/login" element={<LoginForm />} />
        <Route path="/admin/*" element={<AdminLayout />} />
        <Route path="/*" element={<MainLayout />} />
      </Routes>
      <Toaster position="top-right" closeButton richColors />
    </HelmetProvider>
  );
}

export default App;
