import React from 'react';
import { cn } from '@/lib/utils';

interface CardProps {
  className?: string;
  children: React.ReactNode;
}

export const Card = ({ className, children }: CardProps) => (
  <div
    className={cn('bg-white p-8 rounded-lg shadow-sm hover:shadow-md transition-shadow', className)}
  >
    {children}
  </div>
);
