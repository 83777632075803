import React, { useState, useRef } from 'react';
import { ImageHandler } from '../ui/ImageHandler';

interface ProductItemProps {
  product: any;
  onSelect: (product: any) => void;
  onImageError: (productId: string, imageUrl: string) => string;
}

export const ProductItem: React.FC<ProductItemProps> = ({ product, onSelect, onImageError }) => {
  const carouselRef = useRef<HTMLDivElement>(null);
  const [touchStartX, setTouchStartX] = useState<number | null>(null);
  const [touchStartY, setTouchStartY] = useState<number | null>(null);
  const [isSwiping, setIsSwiping] = useState(false);

  const handleTouchStart = (e: React.TouchEvent) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    setIsSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (touchStartX === null || touchStartY === null) return;

    const touchEndX = e.touches[0].clientX;
    const touchEndY = e.touches[0].clientY;
    const deltaX = touchStartX - touchEndX;
    const deltaY = touchStartY - touchEndY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 30) {
      setIsSwiping(true);
      e.preventDefault();
    }
  };

  const handleTouchEnd = () => {
    setTouchStartX(null);
    setTouchStartY(null);
    setIsSwiping(false);
  };

  return (
    <article
      className="group cursor-pointer"
      onClick={() => onSelect(product)}
      role="button"
      tabIndex={0}
      onKeyDown={e => {
        if (e.key === 'Enter' || e.key === ' ') {
          e.preventDefault();
          onSelect(product);
        }
      }}
      itemScope
      itemType="https://schema.org/Product"
    >
      <meta itemProp="name" content={product.name} />
      <meta itemProp="description" content={product.description} />
      <meta itemProp="price" content={product.price.toString()} />
      <meta itemProp="priceCurrency" content="USD" />

      <div className="relative bg-gray-100 rounded-xl overflow-hidden">
        <div className="aspect-placeholder" data-ratio="3:4">
          <div
            ref={carouselRef}
            className="aspect-[4/3] flex snap-x snap-mandatory overflow-x-hidden scroll-smooth touch-pan-y"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}
          >
            {product.images.map((image: string, index: number) => (
              <div key={image} className="flex-none w-full h-full snap-center">
                <ImageHandler
                  src={image}
                  alt={`${product.name} - View ${index + 1}`}
                  className="w-full h-full object-contain bg-white"
                  priority={index === 0}
                  onError={() => onImageError(product.id, image)}
                  itemProp="image"
                />
              </div>
            ))}
          </div>
        </div>

        {product.sold_out && (
          <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
            <div className="bg-red-500 text-white px-3 py-1 text-sm rounded-full transform -rotate-12 shadow-lg">
              Sold
            </div>
          </div>
        )}
      </div>
      <div className="mt-3">
        <h2 className="text-lg font-medium text-gray-900 line-clamp-1">{product.name}</h2>
        <p className="text-lg font-medium text-gray-900 mt-1">${product.price.toLocaleString()}</p>
        <div className="flex items-center gap-1 mt-1 text-sm">
          <span className="text-gray-600">{product.category}</span>
          {product.subcategory && (
            <>
              <span className="text-gray-400">•</span>
              <span className="text-gray-600">{product.subcategory}</span>
            </>
          )}
        </div>
      </div>
    </article>
  );
};
