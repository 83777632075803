import React, { useEffect } from 'react';
import { PayPalButtons, usePayPalScriptReducer } from '@paypal/react-paypal-js';
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';

interface PayPalButtonProps {
  amount: number;
  productName: string;
  productId: string;
  onClose?: () => void;
}

export const PayPalButton: React.FC<PayPalButtonProps> = ({
  amount,
  productName,
  productId,
  onClose,
}) => {
  const [{ isPending, isRejected }] = usePayPalScriptReducer();

  useEffect(() => {
    // Cleanup on unmount
    return () => {
      // Any cleanup if needed
    };
  }, []);

  if (isPending) {
    return <div className="animate-pulse bg-gray-200 rounded-md h-12 w-full" />;
  }

  if (isRejected) {
    return (
      <div className="text-red-500 text-center p-4 border border-red-200 rounded-md bg-red-50">
        Failed to load PayPal. Please try again later.
      </div>
    );
  }

  const handleApprove = async (data: any, actions: any) => {
    try {
      const order = await actions.order.capture();

      if (order.status === 'COMPLETED') {
        // Update product as sold in database
        const { error: updateError } = await supabase
          .from('products')
          .update({
            sold_at: new Date().toISOString(),
            sold_price: amount,
            quantity: 0,
            sold_out: true,
          })
          .eq('id', productId);

        if (updateError) throw updateError;

        toast.success("Payment successful! We'll contact you about delivery.");

        if (onClose) {
          onClose();
        }

        // Reload page to show updated status
        window.location.reload();
      }
    } catch (error: any) {
      console.error('Payment error:', error);
      toast.error('Payment failed. Please try again or contact support.');
    }
  };

  return (
    <PayPalButtons
      style={{
        layout: 'vertical',
        shape: 'rect',
        height: 48,
      }}
      forceReRender={[amount, productId]}
      createOrder={(data, actions) => {
        return actions.order.create({
          purchase_units: [
            {
              description: productName,
              amount: {
                value: amount.toFixed(2),
                currency_code: 'USD',
              },
            },
          ],
          application_context: {
            shipping_preference: 'GET_FROM_FILE',
            user_action: 'PAY_NOW',
            brand_name: 'Show Pony Gallery',
          },
        });
      }}
      onApprove={handleApprove}
      onError={err => {
        console.error('PayPal error:', err);
        toast.error('An error occurred. Please try again or contact support.');
      }}
      onCancel={() => {
        toast.info('Payment cancelled');
        if (onClose) {
          onClose();
        }
      }}
    />
  );
};
