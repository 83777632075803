import React from 'react';

interface StatusFieldProps {
  initialStatus?: string;
}

export const StatusField: React.FC<StatusFieldProps> = ({ initialStatus = 'published' }) => {
  return (
    <div>
      <label htmlFor="status" className="block text-base font-medium text-gray-700 mb-2">
        Status
      </label>
      <select
        id="status"
        name="status"
        defaultValue={initialStatus}
        required
        className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
      >
        <option value="published">Published</option>
        <option value="draft">Draft</option>
      </select>
    </div>
  );
};
