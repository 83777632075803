/**
 * Format event date for display
 * @param startDate ISO date string for event start
 * @param endDate Optional ISO date string for event end
 * @returns Formatted date string
 */
export const formatEventDate = (startDate: string, endDate?: string): string => {
  const start = new Date(startDate);

  // Format options
  const options: Intl.DateTimeFormatOptions = {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };

  // If no end date, return formatted start date
  if (!endDate) {
    return start.toLocaleDateString('en-US', options);
  }

  const end = new Date(endDate);

  // If same day, return single date
  if (start.toDateString() === end.toDateString()) {
    return start.toLocaleDateString('en-US', options);
  }

  // If same month and year, return range with single month/year
  if (start.getMonth() === end.getMonth() && start.getFullYear() === end.getFullYear()) {
    return `${start.toLocaleDateString('en-US', { month: 'long' })} ${start.getDate()} - ${end.getDate()}, ${start.getFullYear()}`;
  }

  // If different months but same year
  if (start.getFullYear() === end.getFullYear()) {
    return `${start.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })} - ${end.toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}, ${start.getFullYear()}`;
  }

  // Different years
  return `${start.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })} - ${end.toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' })}`;
};

/**
 * Check if an event is in the past
 * @param date ISO date string
 * @returns boolean indicating if event is in the past
 */
export const isEventPast = (date: string): boolean => {
  const eventDate = new Date(date);
  const now = new Date();

  // Set both dates to midnight for comparison
  eventDate.setHours(0, 0, 0, 0);
  now.setHours(0, 0, 0, 0);

  return eventDate < now;
};
