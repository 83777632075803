import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { BlogForm } from './BlogForm';
import { Button } from '@/components/ui/Button';
import { toast } from 'sonner';

export const NewBlogPost: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-serif">Add New Blog Post</h1>
        <Link to="/admin/blog">
          <Button variant="secondary">Back to Blog Posts</Button>
        </Link>
      </div>
      <BlogForm
        onSuccess={() => {
          toast.success('Blog post created successfully');
          navigate('/admin/blog');
        }}
      />
    </div>
  );
};
