import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from './ui/Button';

const Hero = () => {
  const navigate = useNavigate();
  const [imageLoaded, setImageLoaded] = useState(false);

  const handleExplore = () => {
    navigate('/collection');
  };

  // Preload hero image
  useEffect(() => {
    const img = new Image();
    img.src =
      'https://res.cloudinary.com/graphichut/image/upload/f_auto,q_auto,w_1920/v1738970012/herogallery1_tbelhb.jpg';
    img.onload = () => setImageLoaded(true);
  }, []);

  return (
    <div className="relative min-h-[calc(100vh-4rem)] md:min-h-[calc(100vh-5rem)] bg-black">
      {/* Image container with object-fit cover */}
      <div className="absolute inset-0">
        <div className="relative h-full">
          <img
            src="https://res.cloudinary.com/graphichut/image/upload/f_auto,q_auto,w_1920/v1738970012/herogallery1_tbelhb.jpg"
            alt="Show Pony Gallery"
            className={`
              w-full h-full object-cover object-center
              transition-opacity duration-500
              ${imageLoaded ? 'opacity-90' : 'opacity-0'}
            `}
            loading="eager"
            fetchpriority="high"
          />
        </div>
      </div>

      {/* Placeholder while image loads */}
      {!imageLoaded && <div className="absolute inset-0 bg-gray-800 animate-pulse" />}

      {/* Overlay gradient */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/30 via-transparent to-black/30" />

      {/* Content */}
      <div className="absolute inset-0 flex flex-col items-center justify-end pb-12 md:pb-16">
        <Button
          size="lg"
          onClick={handleExplore}
          className="
            bg-white/20 hover:bg-white/30 backdrop-blur-sm 
            text-white border-2 border-white/80 
            shadow-lg hover:shadow-xl 
            transition-all duration-300 
            px-8 py-4 text-lg font-medium
            transform hover:scale-105
            motion-safe:animate-fade-up motion-safe:animate-delay-500
          "
        >
          Explore Collection
        </Button>
      </div>
    </div>
  );
};

export default Hero;
