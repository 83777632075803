import { jsPDF } from 'jspdf';

interface TearSheetOptions {
  name: string;
  category: string;
  subcategory?: string;
  price: number;
  style?: string;
  period?: string;
  condition: string;
  condition_details?: string;
  width_inches?: number;
  height_inches?: number;
  depth_inches?: number;
  seat_height_inches?: number;
  description: string;
  imageUrl: string;
}

const loadImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    if (!url) {
      reject(new Error('No image URL provided'));
      return;
    }

    const img = new Image();
    img.crossOrigin = 'anonymous';

    img.onload = () => {
      try {
        // Create a canvas to handle CORS issues
        const canvas = document.createElement('canvas');
        canvas.width = img.width;
        canvas.height = img.height;

        const ctx = canvas.getContext('2d');
        if (!ctx) {
          reject(new Error('Failed to get canvas context'));
          return;
        }

        // Draw image to canvas and convert back to image
        ctx.drawImage(img, 0, 0);
        const cleanImg = new Image();
        cleanImg.onload = () => resolve(cleanImg);
        cleanImg.onerror = () => reject(new Error('Failed to process image'));
        cleanImg.src = canvas.toDataURL('image/jpeg', 0.95);
      } catch (error) {
        reject(new Error('Failed to process image in canvas'));
      }
    };

    img.onerror = () => reject(new Error('Failed to load image'));

    // Add cache-busting parameter and handle CORS
    const cacheBuster = `?t=${Date.now()}`;
    img.src = url.includes('?') ? `${url}&t=${Date.now()}` : url + cacheBuster;
  });
};

export const generateTearSheet = async (options: TearSheetOptions) => {
  try {
    // Validate required fields
    if (!options.name) throw new Error('Product name is required');
    if (!options.category) throw new Error('Category is required');
    if (!options.price) throw new Error('Price is required');
    if (!options.condition) throw new Error('Condition is required');
    if (!options.description) throw new Error('Description is required');
    if (!options.imageUrl) throw new Error('Image URL is required');

    // Create new PDF document (8.5" x 11")
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'in',
      format: 'letter',
    });

    // Set fonts
    doc.setFont('helvetica');

    // Add header
    doc.setFontSize(16);
    doc.text('Show Pony Gallery', 4.25, 0.5, { align: 'center' });

    try {
      // Load and add image
      const img = await loadImage(options.imageUrl);
      const maxImgHeight = 3.5;
      const maxImgWidth = 6;

      // Calculate image dimensions while maintaining aspect ratio
      let imgWidth = maxImgWidth;
      let imgHeight = (img.height * imgWidth) / img.width;

      if (imgHeight > maxImgHeight) {
        imgHeight = maxImgHeight;
        imgWidth = (img.width * maxImgHeight) / img.height;
      }

      // Center the image
      const imgX = (8.5 - imgWidth) / 2;
      doc.addImage(img, 'JPEG', imgX, 0.75, imgWidth, imgHeight);

      // Start content below image
      let y = imgHeight + 1.2;

      // Product name and price
      doc.setFontSize(12);
      doc.setFont('helvetica', 'bold');
      doc.text(options.name, 1, y);
      doc.text(`$${options.price.toLocaleString()}`, 7.5, y, { align: 'right' });
      y += 0.3;

      // Details section
      doc.setFontSize(10);
      doc.setFont('helvetica', 'normal');

      // Category and subcategory
      const categoryText = `Category: ${options.category}${options.subcategory ? ` - ${options.subcategory}` : ''}`;
      doc.text(categoryText, 1, y);
      y += 0.2;

      // Style and period
      if (options.style || options.period) {
        if (options.style) {
          doc.text(`Style: ${options.style}`, 1, y);
          y += 0.2;
        }
        if (options.period) {
          doc.text(`Period: ${options.period}`, 1, y);
          y += 0.2;
        }
      }

      // Dimensions
      const dimensions = [];
      if (options.width_inches) dimensions.push(`W: ${options.width_inches}"`);
      if (options.height_inches) dimensions.push(`H: ${options.height_inches}"`);
      if (options.depth_inches) dimensions.push(`D: ${options.depth_inches}"`);
      if (options.seat_height_inches) dimensions.push(`Seat H: ${options.seat_height_inches}"`);

      if (dimensions.length > 0) {
        doc.text(`Dimensions: ${dimensions.join(' × ')}`, 1, y);
        y += 0.2;
      }

      // Condition
      doc.text(`Condition: ${options.condition}`, 1, y);
      y += 0.2;

      if (options.condition_details) {
        const conditionDetails = doc.splitTextToSize(options.condition_details, 6.5);
        doc.text(conditionDetails, 1, y);
        y += conditionDetails.length * 0.15 + 0.2;
      }

      // Description
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('Description', 1, y);
      y += 0.2;

      doc.setFont('helvetica', 'normal');
      const description = doc.splitTextToSize(options.description, 6.5);
      doc.text(description, 1, y);

      // Footer
      doc.setFontSize(9);
      const footer = [
        'Show Pony Gallery',
        '5011 S Dixie Hwy, West Palm Beach, FL 33405',
        '(917) 455-9307 • Showponygallerypb@gmail.com',
      ];

      y = 10.3;
      footer.forEach(line => {
        doc.text(line, 4.25, y, { align: 'center' });
        y += 0.15;
      });

      // Save the PDF with a clean filename
      const filename = `${options.name.replace(/[^a-z0-9]/gi, '_').toLowerCase()}_tear_sheet.pdf`;
      doc.save(filename);
    } catch (imageError) {
      console.error('Image processing error:', imageError);
      throw new Error('Failed to process product image. Please try again.');
    }
  } catch (error: any) {
    console.error('Error generating tear sheet:', error);
    throw new Error(error.message || 'Failed to generate tear sheet. Please try again.');
  }
};
