export const PRODUCT_CONDITIONS = ['Excellent', 'Very Good', 'Good', 'Fair', 'Poor'] as const;

export const ART_PERIODS = [
  'Pre-1900',
  'Art Nouveau (1890-1910)',
  'Early Modern (1900-1945)',
  'Mid-Century (1945-1960)',
  'Contemporary (1960-Present)',
  'Post-Modern (1970-Present)',
  'Other',
] as const;

export const ART_STYLES = [
  'Abstract',
  'Contemporary',
  'Expressionism',
  'Figurative',
  'Folk Art',
  'Impressionism',
  'Minimalism',
  'Modern',
  'Pop Art',
  'Realism',
  'Street Art',
  'Surrealism',
] as const;

export const FURNITURE_STYLES = [
  'American Craftsman',
  'Art Deco',
  'Art Nouveau',
  'Bauhaus',
  'Biedermeier',
  'Campaign',
  'Chippendale',
  'Danish Modern',
  'Empire',
  'Federal',
  'French Provincial',
  'Georgian',
  'Gothic Revival',
  'Hollywood Regency',
  'Italian Modern',
  'Louis XV',
  'Louis XVI',
  'Mid-Century Modern',
  'Mission',
  'Neoclassical',
  'Queen Anne',
  'Regency',
  'Renaissance Revival',
  'Rococo',
  'Scandinavian Modern',
  'Shaker',
  'Victorian',
] as const;

export type ProductCondition = (typeof PRODUCT_CONDITIONS)[number];
export type ArtPeriod = (typeof ART_PERIODS)[number];
export type ArtStyle = (typeof ART_STYLES)[number];
export type FurnitureStyle = (typeof FURNITURE_STYLES)[number];
