import { toast } from 'sonner';

const MAX_RETRIES = 3;
const RETRY_DELAY = 1000;

export const removeBackground = async (imageUrl: string): Promise<Blob> => {
  const apiKey = import.meta.env.VITE_PHOTOROOM_API_KEY;
  
  if (!apiKey) {
    throw new Error('PhotoRoom API key is not configured');
  }

  let retryCount = 0;
  let lastError: Error | null = null;

  while (retryCount < MAX_RETRIES) {
    try {
      // First fetch the image
      const imageResponse = await fetch(imageUrl);
      if (!imageResponse.ok) {
        throw new Error('Failed to fetch original image');
      }
      const imageBlob = await imageResponse.blob();

      // Validate image size
      const maxSize = 20 * 1024 * 1024; // 20MB
      if (imageBlob.size > maxSize) {
        throw new Error('Image is too large. Maximum size is 20MB');
      }

      // Create form data
      const formData = new FormData();
      formData.append('image_file', imageBlob);

      // Call PhotoRoom API
      const response = await fetch('https://sdk.photoroom.com/v1/segment', {
        method: 'POST',
        headers: {
          'x-api-key': apiKey
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please try again in a few minutes.');
        }
        if (response.status === 402) {
          throw new Error('API quota exceeded. Please try again later.');
        }
        throw new Error(errorData.message || `Failed to remove background (${response.status})`);
      }

      const processedBlob = await response.blob();
      if (!processedBlob || processedBlob.size === 0) {
        throw new Error('Empty response from background removal service');
      }

      // Validate the processed image
      if (processedBlob.type !== 'image/png') {
        throw new Error('Invalid image format returned from service');
      }

      return processedBlob;
    } catch (error: any) {
      lastError = error;
      retryCount++;

      if (retryCount < MAX_RETRIES) {
        // Wait before retrying
        await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * retryCount));
        continue;
      }

      // Log the error details
      console.error('Background removal error:', {
        attempt: retryCount,
        error: error.message,
        imageUrl
      });

      break;
    }
  }

  // If all retries failed, throw the last error
  throw lastError || new Error('Failed to remove background after multiple attempts');
};