import React, { useEffect } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import FeaturedPieces from '../components/FeaturedPieces';
import { LatestProducts } from '../components/LatestProducts';
import { Collection } from '../components/Collection';
import ContactInfo from '../components/ContactInfo';
import Footer from '../components/Footer';
import QRCodePage from '../components/QRCodePage';
import { BlogLayout } from '../components/Blog/BlogLayout';
import { BlogList } from '../components/Blog/BlogList';
import { BlogPost } from '../components/Blog/BlogPost';
import { BacklinkStrategy } from '../components/Blog/BacklinkStrategy';
import { BlogPreview } from '../components/Home/BlogPreview';
import { EventsPage } from '../components/Events/EventsPage';
import { EventDetail } from '../components/Events/EventDetail';
import { EventsPreview } from '../components/Home/EventsPreview';

const HomePage = ({ website }: { website: string }) => (
  <>
    <Helmet>
      <title>
        {website === 'showponygallery.com'
          ? 'Show Pony Gallery | Fine Art & Distinguished Acquisitions in West Palm Beach'
          : 'Show Pony Palm Beach | Fine Art & Distinguished Acquisitions'}
      </title>
      <meta
        name="description"
        content="Discover our curated collection of fine art and distinguished acquisitions in West Palm Beach. Each piece tells a unique story, thoughtfully selected for refined living."
      />
      <meta
        name="keywords"
        content="fine art, distinguished acquisitions, art gallery, West Palm Beach, antiques, art collection"
      />

      {/* Open Graph / Facebook */}
      <meta property="og:type" content="website" />
      <meta
        property="og:title"
        content={
          website === 'showponygallery.com'
            ? 'Show Pony Gallery | Fine Art & Distinguished Acquisitions'
            : 'Show Pony Palm Beach | Fine Art & Distinguished Acquisitions'
        }
      />
      <meta
        property="og:description"
        content="Discover our curated collection of fine art and distinguished acquisitions. Each piece tells a unique story, thoughtfully selected for refined living."
      />
      <meta
        property="og:image"
        content="https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg"
      />

      {/* Twitter */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta
        name="twitter:title"
        content={
          website === 'showponygallery.com'
            ? 'Show Pony Gallery | Fine Art & Distinguished Acquisitions'
            : 'Show Pony Palm Beach | Fine Art & Distinguished Acquisitions'
        }
      />
      <meta
        name="twitter:description"
        content="Discover our curated collection of fine art and distinguished acquisitions. Each piece tells a unique story, thoughtfully selected for refined living."
      />
      <meta
        name="twitter:image"
        content="https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg"
      />

      {/* Additional SEO tags */}
      <meta name="robots" content="index, follow" />
      <meta name="googlebot" content="index, follow" />
      <link rel="canonical" href={`https://${website}`} />

      {/* Structured Data */}
      <script type="application/ld+json">
        {JSON.stringify({
          '@context': 'https://schema.org',
          '@type': 'ArtGallery',
          name: website === 'showponygallery.com' ? 'Show Pony Gallery' : 'Show Pony Palm Beach',
          description: 'Fine art and distinguished acquisitions gallery in West Palm Beach',
          image:
            'https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg',
          address: {
            '@type': 'PostalAddress',
            streetAddress: '5011 S Dixie Hwy',
            addressLocality: 'West Palm Beach',
            addressRegion: 'FL',
            postalCode: '33405',
            addressCountry: 'US',
          },
          geo: {
            '@type': 'GeoCoordinates',
            latitude: '26.6744',
            longitude: '-80.0555',
          },
          openingHours: 'Mo-Sa 11:00-17:00',
          telephone: '+19174559307',
          url: `https://${website}`,
          sameAs: [
            'https://www.instagram.com/showponygallerypb/',
            'https://www.chairish.com/shop/showponygallery',
          ],
        })}
      </script>
    </Helmet>
    <div className="pt-[calc(4rem+2.5rem)] md:pt-[calc(5rem+2.5rem)]">
      <Hero />
      <LatestProducts />
      <EventsPreview />
      <FeaturedPieces />
      <BlogPreview />
    </div>
  </>
);

const ScrollToTop = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname, search]);

  return null;
};

const MainLayout = () => {
  const hostname = window.location.hostname;
  const website =
    hostname === 'showponypalmbeach.com' ? 'showponypalmbeach.com' : 'showponygallery.com';

  return (
    <div className="min-h-screen bg-white">
      <ScrollToTop />

      {/* Skip to content link for accessibility */}
      <a href="#main-content" className="skip-to-content">
        Skip to content
      </a>

      <Navbar />
      <main id="main-content" className="flex flex-col">
        <div className="pt-[calc(4rem+2.5rem)] md:pt-[calc(5rem+2.5rem)]">
          <Routes>
            <Route path="/" element={<HomePage website={website} />} />
            <Route
              path="/collection"
              element={<Collection showTitle={true} website={website as any} />}
            />
            <Route path="/contact-card" element={<QRCodePage />} />
            <Route path="/blog" element={<BlogLayout />}>
              <Route index element={<BlogList />} />
              <Route path=":slug" element={<BlogPost />} />
              <Route path="category/:categorySlug" element={<BlogList />} />
              <Route path="backlink-strategy" element={<BacklinkStrategy />} />
            </Route>
            <Route path="/events" element={<EventsPage />} />
            <Route path="/events/:slug" element={<EventDetail />} />
          </Routes>
        </div>
        <ContactInfo />
      </main>
      <Footer />
    </div>
  );
};

export default MainLayout;
