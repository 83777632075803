import React from 'react';
import { Routes, Route, Navigate, Link, useNavigate } from 'react-router-dom';
import { useAuth, signOut } from '@/lib/auth';
import { ProductForm } from './ProductForm';
import { ProductList } from './ProductList';
import { EditProduct } from './EditProduct';
import { AdminDashboard } from './AdminDashboard';
import { NewEvent } from './events/NewEvent';
import { EditEvent } from './events/EditEvent';
import { EventList } from './events/EventList';
import { BlogManagement } from './blog/BlogManagement';
import { NewBlogPost } from './blog/NewBlogPost';
import { EditBlogPost } from './blog/EditBlogPost';
import { Button } from '../ui/Button';
import { Lock, Package, Calendar, BookOpen } from 'lucide-react';

const AdminLayout: React.FC = () => {
  const { user, isLoading, error } = useAuth();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div className="min-h-screen flex items-center justify-center bg-gray-50">
        <div className="text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-stone-800 mx-auto mb-4"></div>
          <p className="text-gray-600">Loading...</p>
        </div>
      </div>
    );
  }

  if (error || !user?.isAdmin) {
    return <Navigate to="/admin/login" replace />;
  }

  const handleSignOut = async () => {
    try {
      await signOut();
      navigate('/admin/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <nav className="bg-white shadow">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex">
              <div className="flex-shrink-0 flex items-center">
                <Link to="/admin" className="flex items-center gap-2 text-xl">
                  <Lock className="w-5 h-5" />
                  <span>Admin Portal</span>
                </Link>
              </div>
              <div className="hidden sm:ml-6 sm:flex sm:space-x-8">
                <Link
                  to="/admin/products"
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                >
                  <Package className="w-4 h-4 mr-1" />
                  Products
                </Link>
                <Link
                  to="/admin/events"
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                >
                  <Calendar className="w-4 h-4 mr-1" />
                  Events
                </Link>
                <Link
                  to="/admin/blog"
                  className="inline-flex items-center px-1 pt-1 text-sm font-medium text-gray-900"
                >
                  <BookOpen className="w-4 h-4 mr-1" />
                  Blog
                </Link>
              </div>
            </div>
            <div className="flex items-center space-x-4">
              <span className="text-sm text-gray-600">Logged in as {user.email}</span>
              <Link to="/" className="text-gray-700 hover:text-amber-800">
                View Gallery
              </Link>
              <Button variant="secondary" size="sm" onClick={handleSignOut}>
                Sign Out
              </Button>
            </div>
          </div>
        </div>
      </nav>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/products" element={<AdminDashboard />} />
          <Route
            path="/products/new"
            element={
              <div className="space-y-6">
                <div className="flex justify-between items-center">
                  <h1 className="text-2xl font-serif">Add New Artwork</h1>
                  <Link to="/admin/products">
                    <Button variant="secondary">Back to Artwork</Button>
                  </Link>
                </div>
                <ProductForm />
              </div>
            }
          />
          <Route path="/products/edit/:id" element={<EditProduct />} />
          <Route
            path="/events"
            element={
              <div className="space-y-6">
                <div className="flex justify-between items-center">
                  <h1 className="text-2xl font-serif">Manage Events</h1>
                  <Link to="/admin/events/new">
                    <Button>Add New Event</Button>
                  </Link>
                </div>
                <EventList />
              </div>
            }
          />
          <Route path="/events/new" element={<NewEvent />} />
          <Route path="/events/edit/:id" element={<EditEvent />} />
          <Route path="/blog" element={<BlogManagement />} />
          <Route path="/blog/new" element={<NewBlogPost />} />
          <Route path="/blog/edit/:id" element={<EditBlogPost />} />
          <Route path="*" element={<Navigate to="/admin/products" replace />} />
        </Routes>
      </div>
    </div>
  );
};

export { AdminLayout };
export default AdminLayout;
