import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { ProductForm } from './ProductForm';
import { supabase } from '@/lib/supabase';
import { Button } from '../ui/Button';
import { toast } from 'sonner';

export const EditProduct = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [product, setProduct] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchProduct = async () => {
      if (!id) {
        navigate('/admin/products');
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const { data, error: fetchError } = await supabase
          .from('products')
          .select('*')
          .eq('id', id)
          .single();

        if (fetchError) {
          throw fetchError;
        }

        if (!data) {
          throw new Error('Product not found');
        }

        setProduct(data);
      } catch (err: any) {
        console.error('Error fetching product:', err);
        setError(err.message);
        toast.error('Failed to load product');
      } finally {
        setLoading(false);
      }
    };

    fetchProduct();
  }, [id, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-stone-800"></div>
      </div>
    );
  }

  if (error || !product) {
    return (
      <div className="text-center py-8">
        <p className="text-red-600 mb-4">{error || 'Product not found'}</p>
        <Link to="/admin/products">
          <Button variant="secondary">Back to Products</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-serif">Edit Product</h1>
        <Link to="/admin/products">
          <Button variant="secondary">Back to Products</Button>
        </Link>
      </div>
      <ProductForm
        initialData={product}
        onSuccess={() => {
          toast.success('Product updated successfully');
          navigate('/admin/products');
        }}
      />
    </div>
  );
};
