/**
 * SEO utility functions for optimizing product pages
 */

// Generate meta description from product description
export const generateMetaDescription = (description: string, maxLength = 160): string => {
  if (!description) return '';

  // Remove any HTML tags if present
  const cleanDescription = description.replace(/<\/?[^>]+(>|$)/g, '');

  // Truncate to maxLength and ensure it doesn't cut off mid-word
  if (cleanDescription.length <= maxLength) return cleanDescription;

  const truncated = cleanDescription.substring(0, maxLength);
  const lastSpaceIndex = truncated.lastIndexOf(' ');

  return truncated.substring(0, lastSpaceIndex) + '...';
};

// Generate keywords from product attributes
export const generateKeywords = (product: any): string => {
  const keywords = [
    product.name,
    product.category,
    product.subcategory,
    product.style,
    product.period,
    product.condition,
    'fine art',
    'gallery',
    'West Palm Beach',
    'vintage',
    'Show Pony Gallery',
  ];

  // Add dimensions if available
  if (product.width_inches || product.height_inches) {
    keywords.push('dimensions');
  }

  // Filter out empty values and join with commas
  return keywords.filter(Boolean).join(', ');
};

// Generate canonical URL for product
export const generateCanonicalUrl = (productId: string): string => {
  return `https://showponygallery.com/collection?productId=${productId}`;
};

// Generate breadcrumb structured data
export const generateBreadcrumbData = (product: any): string => {
  const breadcrumbData = {
    '@context': 'https://schema.org',
    '@type': 'BreadcrumbList',
    itemListElement: [
      {
        '@type': 'ListItem',
        position: 1,
        name: 'Home',
        item: 'https://showponygallery.com',
      },
      {
        '@type': 'ListItem',
        position: 2,
        name: 'Collection',
        item: 'https://showponygallery.com/collection',
      },
      {
        '@type': 'ListItem',
        position: 3,
        name: product.category,
        item: `https://showponygallery.com/collection?category=${encodeURIComponent(product.category)}`,
      },
    ],
  };

  // Add subcategory if available
  if (product.subcategory) {
    breadcrumbData.itemListElement.push({
      '@type': 'ListItem',
      position: 4,
      name: product.subcategory,
      item: `https://showponygallery.com/collection?category=${encodeURIComponent(product.category)}&subcategory=${encodeURIComponent(product.subcategory)}`,
    });

    // Add product as final item
    breadcrumbData.itemListElement.push({
      '@type': 'ListItem',
      position: 5,
      name: product.name,
      item: `https://showponygallery.com/collection?productId=${product.id}`,
    });
  } else {
    // Add product as final item (no subcategory)
    breadcrumbData.itemListElement.push({
      '@type': 'ListItem',
      position: 4,
      name: product.name,
      item: `https://showponygallery.com/collection?productId=${product.id}`,
    });
  }

  return JSON.stringify(breadcrumbData);
};

// Generate product structured data
export const generateProductStructuredData = (product: any): string => {
  const structuredData = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    name: product.name,
    description: product.description,
    image: product.images[0],
    sku: product.id,
    mpn: product.id,
    brand: {
      '@type': 'Brand',
      name: 'Show Pony Gallery',
    },
    offers: {
      '@type': 'Offer',
      url: `https://showponygallery.com/collection?productId=${product.id}`,
      priceCurrency: 'USD',
      price: product.price,
      priceValidUntil: new Date(new Date().setFullYear(new Date().getFullYear() + 1))
        .toISOString()
        .split('T')[0],
      itemCondition: `https://schema.org/${product.condition.replace(' ', '')}Condition`,
      availability: product.sold_out ? 'https://schema.org/SoldOut' : 'https://schema.org/InStock',
      seller: {
        '@type': 'Organization',
        name: 'Show Pony Gallery',
        address: {
          '@type': 'PostalAddress',
          streetAddress: '5011 S Dixie Hwy',
          addressLocality: 'West Palm Beach',
          addressRegion: 'FL',
          postalCode: '33405',
          addressCountry: 'US',
        },
      },
    },
  };

  // Add dimensions if available
  if (product.width_inches || product.height_inches || product.depth_inches) {
    structuredData['size'] =
      `${product.width_inches || 0}" × ${product.height_inches || 0}" × ${product.depth_inches || 0}"`;
  }

  // Add category and style
  if (product.category) {
    structuredData['category'] = product.category;
  }

  if (product.style || product.period) {
    structuredData['additionalProperty'] = [];

    if (product.style) {
      structuredData['additionalProperty'].push({
        '@type': 'PropertyValue',
        name: 'style',
        value: product.style,
      });
    }

    if (product.period) {
      structuredData['additionalProperty'].push({
        '@type': 'PropertyValue',
        name: 'period',
        value: product.period,
      });
    }
  }

  return JSON.stringify(structuredData);
};

// Generate local business structured data
export const generateLocalBusinessData = (): string => {
  const businessData = {
    '@context': 'https://schema.org',
    '@type': 'ArtGallery',
    name: 'Show Pony Gallery',
    description: 'Fine art and distinguished acquisitions gallery in West Palm Beach',
    image:
      'https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg',
    address: {
      '@type': 'PostalAddress',
      streetAddress: '5011 S Dixie Hwy',
      addressLocality: 'West Palm Beach',
      addressRegion: 'FL',
      postalCode: '33405',
      addressCountry: 'US',
    },
    geo: {
      '@type': 'GeoCoordinates',
      latitude: '26.6744',
      longitude: '-80.0555',
    },
    openingHours: 'Mo-Sa 11:00-17:00',
    telephone: '+19174559307',
    url: 'https://showponygallery.com',
    sameAs: [
      'https://www.instagram.com/showponygallerypb/',
      'https://www.chairish.com/shop/showponygallery',
    ],
    priceRange: '$$$$',
  };

  return JSON.stringify(businessData);
};
