import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { ChevronLeft, ChevronRight, X, FileText, ZoomIn } from 'lucide-react';
import { Product } from './types';
import { Modal } from '../ui/Modal';
import { Button } from '../ui/Button';
import { ShareButton } from '../ui/ShareButton';
import { PaymentButtons } from '../ui/PaymentButtons';
import { ImageHandler } from '../ui/ImageHandler';
import { ImageMagnifier } from '../shared/ImageMagnifier';
import { generateTearSheet } from '@/lib/pdf/tearSheet';
import { useRecentlyViewed } from '@/lib/hooks/useRecentlyViewed';
import { toast } from 'sonner';
import { generateMetaDescription, generateProductStructuredData } from '@/lib/utils/seo';
import { Breadcrumbs } from '../ui/Breadcrumbs';

interface ProductCardProps {
  product: Product;
  isSelected?: boolean;
  onClose?: () => void;
}

export const ProductCard: React.FC<ProductCardProps> = ({
  product,
  isSelected = false,
  onClose,
}) => {
  const [showModal, setShowModal] = useState(isSelected);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isHovered, setIsHovered] = useState(false);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const [isZoomed, setIsZoomed] = useState(false);
  const carouselRef = useRef<HTMLDivElement>(null);
  const touchStartX = useRef<number | null>(null);
  const touchStartY = useRef<number | null>(null);
  const [isSwiping, setIsSwiping] = useState(false);
  const { addToRecentlyViewed } = useRecentlyViewed();
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setShowModal(isSelected);
    if (isSelected) {
      addToRecentlyViewed(product);

      // Update URL with product ID for direct linking
      const url = new URL(window.location.href);
      url.searchParams.set('productId', product.id);
      window.history.replaceState({}, '', url.toString());

      // Prevent body scrolling when modal is open
      document.body.style.overflow = 'hidden';
    }
    if (!isSelected) {
      setSelectedImage(null);
      setCurrentImageIndex(0);
      setIsZoomed(false);

      // Remove product ID from URL when closing
      const url = new URL(window.location.href);
      if (url.searchParams.has('productId')) {
        url.searchParams.delete('productId');
        window.history.replaceState({}, '', url.toString());
      }

      // Restore body scrolling when modal is closed
      document.body.style.overflow = '';
    }
  }, [isSelected, product, addToRecentlyViewed]);

  useEffect(() => {
    return () => {
      setShowModal(false);
      setSelectedImage(null);
      setCurrentImageIndex(0);
      setIsZoomed(false);
      document.body.style.overflow = '';
    };
  }, []);

  const scrollToImage = (index: number) => {
    if (carouselRef.current && !isTransitioning) {
      setIsTransitioning(true);
      const scrollAmount = carouselRef.current.offsetWidth * index;
      carouselRef.current.scrollTo({
        left: scrollAmount,
        behavior: 'smooth',
      });
      setCurrentImageIndex(index);
      setTimeout(() => setIsTransitioning(false), 300);
    }
  };

  const handlePrevImage = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (!isTransitioning && !isZoomed) {
      const newIndex = currentImageIndex === 0 ? product.images.length - 1 : currentImageIndex - 1;
      scrollToImage(newIndex);
    }
  };

  const handleNextImage = (e?: React.MouseEvent) => {
    e?.stopPropagation();
    if (!isTransitioning && !isZoomed) {
      const newIndex = currentImageIndex === product.images.length - 1 ? 0 : currentImageIndex + 1;
      scrollToImage(newIndex);
    }
  };

  const handleTouchStart = (e: React.TouchEvent) => {
    if (isZoomed) return;
    touchStartX.current = e.touches[0].clientX;
    touchStartY.current = e.touches[0].clientY;
    setIsSwiping(false);
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (isZoomed || touchStartX.current === null || touchStartY.current === null) return;

    const touchEndX = e.touches[0].clientX;
    const touchEndY = e.touches[0].clientY;
    const deltaX = touchStartX.current - touchEndX;
    const deltaY = touchStartY.current - touchEndY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 30) {
      setIsSwiping(true);
      e.preventDefault();
    }
  };

  const handleTouchEnd = (e: React.TouchEvent) => {
    if (isZoomed || touchStartX.current === null) return;

    const touchEndX = e.changedTouches[0].clientX;
    const deltaX = touchStartX.current - touchEndX;

    if (isSwiping && Math.abs(deltaX) > 50) {
      if (deltaX > 0) {
        handleNextImage();
      } else {
        handlePrevImage();
      }
    }

    touchStartX.current = null;
    touchStartY.current = null;
    setIsSwiping(false);
  };

  const handleImageClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    if (!isZoomed) {
      setIsZoomed(true);
      setSelectedImage(product.images[currentImageIndex]);
    }
  };

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (showModal) {
        if (e.key === 'ArrowLeft') {
          handlePrevImage();
        } else if (e.key === 'ArrowRight') {
          handleNextImage();
        } else if (e.key === 'Escape') {
          if (isZoomed) {
            setIsZoomed(false);
            setSelectedImage(null);
          } else if (onClose) {
            onClose();
          }
        }
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [showModal, currentImageIndex, isTransitioning, isZoomed, onClose]);

  React.useEffect(() => {
    const handleScroll = () => {
      if (carouselRef.current && !isTransitioning) {
        const scrollLeft = carouselRef.current.scrollLeft;
        const width = carouselRef.current.offsetWidth;
        const newIndex = Math.round(scrollLeft / width);
        if (newIndex !== currentImageIndex) {
          setCurrentImageIndex(newIndex);
        }
      }
    };

    carouselRef.current?.addEventListener('scroll', handleScroll);
    return () => carouselRef.current?.removeEventListener('scroll', handleScroll);
  }, [currentImageIndex, isTransitioning]);

  const handleClick = () => {
    setShowModal(true);
    addToRecentlyViewed(product);

    // Update URL with product ID for direct linking
    const url = new URL(window.location.href);
    url.searchParams.set('productId', product.id);
    window.history.replaceState({}, '', url.toString());

    // Prevent body scrolling when modal is open
    document.body.style.overflow = 'hidden';
  };

  const handleClose = () => {
    setShowModal(false);
    setIsZoomed(false);
    setSelectedImage(null);

    // Remove product ID from URL when closing
    const url = new URL(window.location.href);
    if (url.searchParams.has('productId')) {
      url.searchParams.delete('productId');
      window.history.replaceState({}, '', url.toString());
    }

    // Restore body scrolling when modal is closed
    document.body.style.overflow = '';

    if (onClose) {
      onClose();
    }
  };

  // Get meta description
  const metaDescription = product.meta_description || generateMetaDescription(product.description);

  // Get canonical URL
  const canonicalUrl = `https://showponygallery.com/collection?productId=${product.id}`;

  // Generate breadcrumb items
  const breadcrumbItems = [
    {
      label: 'Collection',
      href: '/collection',
    },
    {
      label: product.category,
      href: `/collection?category=${encodeURIComponent(product.category)}`,
    },
  ];

  if (product.subcategory) {
    breadcrumbItems.push({
      label: product.subcategory,
      href: `/collection?category=${encodeURIComponent(product.category)}&subcategory=${encodeURIComponent(product.subcategory)}`,
    });
  }

  breadcrumbItems.push({
    label: product.name,
  });

  return (
    <>
      {showModal && (
        <Helmet>
          <title>{`${product.name} | Show Pony Gallery`}</title>
          <meta name="description" content={metaDescription} />
          <meta
            name="keywords"
            content={`${product.category}, ${product.subcategory || ''}, ${product.style || ''}, ${product.period || ''}, fine art, gallery, West Palm Beach`}
          />

          <meta property="og:title" content={`${product.name} | Show Pony Gallery`} />
          <meta property="og:description" content={metaDescription} />
          <meta property="og:type" content="product" />
          <meta property="og:url" content={canonicalUrl} />
          {product.images?.[0] && <meta property="og:image" content={product.images[0]} />}

          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={`${product.name} | Show Pony Gallery`} />
          <meta name="twitter:description" content={metaDescription} />
          {product.images?.[0] && <meta name="twitter:image" content={product.images[0]} />}

          <link rel="canonical" href={canonicalUrl} />

          <script type="application/ld+json">{generateProductStructuredData(product)}</script>
        </Helmet>
      )}

      <div
        onClick={handleClick}
        className="group cursor-pointer"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <div className="relative bg-gray-100 rounded-xl overflow-hidden">
          <div className="aspect-placeholder" data-ratio="3:4">
            <div
              ref={carouselRef}
              className="aspect-[4/3] flex snap-x snap-mandatory overflow-x-hidden scroll-smooth touch-pan-y"
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={handleTouchEnd}
            >
              {product.images.map((image, index) => (
                <div key={image} className="flex-none w-full h-full snap-center">
                  <ImageHandler
                    src={image}
                    alt={`${product.name} - View ${index + 1}`}
                    className="w-full h-full object-contain bg-white"
                    priority={index === 0}
                  />
                </div>
              ))}
            </div>
          </div>

          {product.images.length > 1 && isHovered && (
            <>
              <button
                onClick={handlePrevImage}
                className="absolute left-4 top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white p-2 rounded-full shadow-lg transition-all duration-200 opacity-0 group-hover:opacity-100"
                aria-label="Previous image"
              >
                <ChevronLeft className="w-5 h-5" />
              </button>
              <button
                onClick={handleNextImage}
                className="absolute right-4 top-1/2 -translate-y-1/2 bg-white/90 hover:bg-white p-2 rounded-full shadow-lg transition-all duration-200 opacity-0 group-hover:opacity-100"
                aria-label="Next image"
              >
                <ChevronRight className="w-5 h-5" />
              </button>
            </>
          )}

          {product.sold_out && (
            <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
              <div className="bg-red-500 text-white px-6 py-3 rounded-full text-lg font-medium transform -rotate-12 shadow-lg">
                Sold
              </div>
            </div>
          )}

          {product.images.length > 1 && (
            <div className="absolute bottom-3 left-1/2 -translate-x-1/2 flex items-center gap-2 px-3 py-1.5 bg-black/20 backdrop-blur-[2px] rounded-full opacity-0 group-hover:opacity-100 transition-opacity">
              {product.images.map((_, index) => (
                <button
                  key={index}
                  onClick={e => {
                    e.stopPropagation();
                    scrollToImage(index);
                  }}
                  className={`w-2 h-2 rounded-full transition-all duration-300 ${
                    index === currentImageIndex ? 'bg-white' : 'bg-white/40 hover:bg-white/60'
                  }`}
                  aria-label={`Go to image ${index + 1}`}
                />
              ))}
            </div>
          )}
        </div>

        <div className="mt-4 space-y-1">
          <h3 className="text-lg font-medium text-gray-900 line-clamp-1">{product.name}</h3>
          <p className="text-lg font-medium text-gray-900">${product.price.toLocaleString()}</p>
          <div className="flex items-center gap-1 text-sm">
            <button
              onClick={e => {
                e.stopPropagation();
                window.location.href = `/collection?category=${encodeURIComponent(product.category)}`;
              }}
              className="text-gray-600 hover:text-amber-800 transition-colors"
            >
              {product.category}
            </button>
            {product.subcategory && (
              <>
                <span className="text-gray-400">›</span>
                <button
                  onClick={e => {
                    e.stopPropagation();
                    window.location.href = `/collection?category=${encodeURIComponent(product.category)}&subcategory=${encodeURIComponent(product.subcategory || '')}`;
                  }}
                  className="text-gray-600 hover:text-amber-800 transition-colors"
                >
                  {product.subcategory}
                </button>
              </>
            )}
          </div>
        </div>
      </div>

      {showModal && (
        <div
          className="fixed inset-0 z-[100] overflow-y-auto"
          ref={modalRef}
          onClick={e => {
            // Only close if clicking directly on the backdrop
            if (e.target === modalRef.current) {
              handleClose();
            }
          }}
        >
          {/* Backdrop */}
          <div
            className="fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity"
            aria-hidden="true"
          />

          {/* Modal panel */}
          <div className="relative bg-white shadow-xl max-w-7xl mx-auto my-8">
            <button
              onClick={handleClose}
              className="absolute top-4 right-4 z-50 p-2 bg-white/90 hover:bg-white rounded-full shadow-lg transition-colors"
            >
              <X className="w-5 h-5" />
            </button>

            <div className="grid md:grid-cols-2 min-h-[90vh] md:min-h-0">
              <div className="bg-gray-50 p-4 md:p-8">
                <div className="sticky top-8 space-y-4">
                  {isZoomed ? (
                    <ImageMagnifier
                      src={selectedImage || product.images[currentImageIndex]}
                      alt={product.name}
                      onClose={() => {
                        setIsZoomed(false);
                        setSelectedImage(null);
                      }}
                    />
                  ) : (
                    <div
                      className="aspect-square bg-white rounded-xl overflow-hidden relative group"
                      onClick={handleImageClick}
                    >
                      <ImageHandler
                        src={selectedImage || product.images[currentImageIndex]}
                        alt={product.name}
                        className="w-full h-full object-contain"
                        priority={true}
                      />
                      <button
                        className="absolute top-4 right-4 p-2 bg-white/90 rounded-full shadow-lg opacity-0 group-hover:opacity-100 transition-opacity"
                        onClick={handleImageClick}
                      >
                        <ZoomIn className="w-5 h-5" />
                      </button>
                    </div>
                  )}

                  {product.images?.length > 1 && !isZoomed && (
                    <div className="grid grid-cols-3 sm:grid-cols-6 gap-2">
                      {product.images.map((image, index) => (
                        <button
                          key={index}
                          onClick={() => setSelectedImage(image)}
                          className={`relative aspect-square rounded-lg overflow-hidden border-2 transition-all ${
                            selectedImage === image ||
                            (!selectedImage && index === currentImageIndex)
                              ? 'border-amber-500 shadow-md'
                              : 'border-transparent hover:border-amber-200'
                          }`}
                        >
                          <ImageHandler
                            src={image}
                            alt={`${product.name} thumbnail ${index + 1}`}
                            className="w-full h-full object-cover"
                            fallbackClassName="w-full h-full flex items-center justify-center"
                          />
                        </button>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <div className="p-4 md:p-8 space-y-6 md:space-y-8">
                {/* Breadcrumbs */}
                <Breadcrumbs items={breadcrumbItems} className="mb-2" />

                <div>
                  <div className="flex justify-between items-start gap-4">
                    <h2 className="text-2xl md:text-3xl font-medium text-gray-900">
                      {product.name}
                    </h2>
                    <div className="flex items-center gap-2">
                      <Button
                        variant="secondary"
                        size="sm"
                        onClick={e => {
                          e.stopPropagation();
                          generateTearSheet({
                            name: product.name,
                            category: product.category,
                            subcategory: product.subcategory,
                            price: product.price,
                            style: product.style,
                            period: product.period,
                            condition: product.condition,
                            condition_details: product.condition_details,
                            width_inches: product.width_inches,
                            height_inches: product.height_inches,
                            depth_inches: product.depth_inches,
                            seat_height_inches: product.seat_height_inches,
                            description: product.description,
                            imageUrl: product.images[0],
                          })
                            .then(() => {
                              toast.success('Tear sheet generated successfully');
                            })
                            .catch(error => {
                              console.error('Error generating tear sheet:', error);
                              toast.error('Failed to generate tear sheet');
                            });
                        }}
                        className="flex items-center gap-2"
                      >
                        <FileText className="w-4 h-4" />
                        <span className="hidden sm:inline">Tear Sheet</span>
                      </Button>
                      <ShareButton
                        title={product.name}
                        description={product.description}
                        imageUrl={product.images?.[0]}
                      />
                    </div>
                  </div>
                  <p className="text-2xl font-medium text-gray-900 mt-2">
                    ${product.price.toLocaleString()}
                  </p>
                  <div className="flex items-center gap-1 mt-1">
                    <button
                      onClick={e => {
                        e.stopPropagation();
                        window.location.href = `/collection?category=${encodeURIComponent(product.category)}`;
                      }}
                      className="text-sm text-gray-600 hover:text-amber-800 transition-colors"
                    >
                      {product.category}
                    </button>
                    {product.subcategory && (
                      <>
                        <span className="text-gray-400">›</span>
                        <button
                          onClick={e => {
                            e.stopPropagation();
                            window.location.href = `/collection?category=${encodeURIComponent(product.category)}&subcategory=${encodeURIComponent(product.subcategory || '')}`;
                          }}
                          className="text-sm text-gray-600 hover:text-amber-800 transition-colors"
                        >
                          {product.subcategory}
                        </button>
                      </>
                    )}
                  </div>
                </div>

                <div className="prose prose-stone max-w-none">
                  <h3 className="text-lg font-medium text-gray-900">Description</h3>
                  <p className="text-gray-600 leading-relaxed whitespace-pre-line">
                    {product.description}
                  </p>
                </div>

                {(product.width_inches ||
                  product.height_inches ||
                  product.depth_inches ||
                  product.condition ||
                  product.style ||
                  product.period) && (
                  <div className="space-y-4">
                    <h3 className="text-lg font-medium text-gray-900">Specifications</h3>
                    <dl className="grid grid-cols-1 sm:grid-cols-2 gap-4">
                      {product.condition && (
                        <div>
                          <dt className="text-sm text-gray-600">Condition</dt>
                          <dd className="text-gray-900">{product.condition}</dd>
                        </div>
                      )}
                      {product.style && (
                        <div>
                          <dt className="text-sm text-gray-600">Style</dt>
                          <dd className="text-gray-900">{product.style}</dd>
                        </div>
                      )}
                      {product.period && (
                        <div>
                          <dt className="text-sm text-gray-600">Period</dt>
                          <dd className="text-gray-900">{product.period}</dd>
                        </div>
                      )}
                    </dl>

                    {(product.width_inches || product.height_inches || product.depth_inches) && (
                      <div className="border rounded-lg p-4 bg-gray-50">
                        <h4 className="text-sm font-medium text-gray-900 mb-2">Dimensions</h4>
                        <div className="grid grid-cols-2 gap-4">
                          {product.width_inches && (
                            <div>
                              <dt className="text-sm text-gray-600">Width</dt>
                              <dd className="text-gray-900">{product.width_inches}"</dd>
                            </div>
                          )}
                          {product.height_inches && (
                            <div>
                              <dt className="text-sm text-gray-600">Height</dt>
                              <dd className="text-gray-900">{product.height_inches}"</dd>
                            </div>
                          )}
                          {product.depth_inches && (
                            <div>
                              <dt className="text-sm text-gray-600">Depth</dt>
                              <dd className="text-gray-900">{product.depth_inches}"</dd>
                            </div>
                          )}
                          {product.seat_height_inches && (
                            <div>
                              <dt className="text-sm text-gray-600">Seat Height</dt>
                              <dd className="text-gray-900">{product.seat_height_inches}"</dd>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}

                <div className="pt-4 space-y-4">
                  {!product.sold_out ? (
                    <PaymentButtons
                      amount={product.price}
                      productName={product.name}
                      productId={product.id}
                      onClose={handleClose}
                    />
                  ) : (
                    <div className="bg-red-50 text-red-600 px-6 py-4 rounded-lg text-center">
                      This item has been sold
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
