import React, { useState } from 'react';
import { useProductData } from './FeaturedPieces/hooks/useProductData';
import { ProductCard } from './FeaturedPieces/ProductCard';
import { ImageHandler } from './ui/ImageHandler';

export const LatestProducts = () => {
  const { products, loading, error } = useProductData();
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);

  // Get latest 12 published products
  const latestProducts = products.filter(product => product.status === 'published').slice(0, 12);

  if (loading) {
    return (
      <section className="py-8 md:py-12 bg-stone-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-2xl md:text-3xl font-medium text-center mb-6">Latest Arrivals</h2>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6">
            {[...Array(12)].map((_, i) => (
              <div key={i} className="aspect-[3/4] bg-white rounded-lg animate-pulse" />
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (error || latestProducts.length === 0) {
    return null;
  }

  return (
    <section className="py-8 md:py-12 bg-stone-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-3xl font-medium text-center mb-6">Latest Arrivals</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6">
          {latestProducts.map((product, index) => (
            <div
              key={product.id}
              className="group cursor-pointer"
              onClick={() => setSelectedProduct(product)}
              role="button"
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  setSelectedProduct(product);
                }
              }}
            >
              <div className="aspect-[3/4] bg-white rounded-lg overflow-hidden flex items-center justify-center">
                <ImageHandler
                  src={product.images[0]}
                  alt={product.name}
                  className="w-full h-full object-contain"
                  priority={index < 4} // Prioritize loading first 4 images
                />
                {product.sold_out && (
                  <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
                    <div className="bg-red-500 text-white px-3 py-1 text-sm rounded-full transform -rotate-12 shadow-lg">
                      Sold
                    </div>
                  </div>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedProduct && (
        <ProductCard
          product={selectedProduct}
          isSelected={true}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </section>
  );
};
