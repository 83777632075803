import React from 'react';
import { Control, useController } from 'react-hook-form';

interface BasicFieldsProps {
  control: Control<any>;
  category?: string;
}

export const BasicFields: React.FC<BasicFieldsProps> = ({ control, category }) => {
  const { field: nameField } = useController({
    name: 'name',
    control,
    rules: { required: 'Name is required' },
  });

  const { field: descriptionField } = useController({
    name: 'description',
    control,
    rules: { required: 'Description is required' },
  });

  const { field: priceField } = useController({
    name: 'price',
    control,
    rules: { required: 'Price is required' },
  });

  const { field: quantityField } = useController({
    name: 'quantity',
    control,
    defaultValue: 1,
    rules: { required: 'Quantity is required' },
  });

  return (
    <>
      <div>
        <label htmlFor="name" className="block text-base font-medium text-gray-700 mb-2">
          Name *
        </label>
        <input
          type="text"
          id="name"
          {...nameField}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
        />
      </div>

      <div>
        <label htmlFor="description" className="block text-base font-medium text-gray-700 mb-2">
          Description *
        </label>
        <textarea
          id="description"
          {...descriptionField}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
          rows={6}
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <div>
          <label htmlFor="price" className="block text-base font-medium text-gray-700 mb-2">
            Price *
          </label>
          <input
            type="number"
            id="price"
            step="0.01"
            {...priceField}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
          />
        </div>

        <div>
          <label htmlFor="quantity" className="block text-base font-medium text-gray-700 mb-2">
            Quantity Available *
          </label>
          <input
            type="number"
            id="quantity"
            min="0"
            step="1"
            {...quantityField}
            className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
          />
        </div>
      </div>
    </>
  );
};
