import React from 'react';
import { ImageHandler } from './ImageHandler';

interface ImageUploaderProps {
  value: string[];
  onChange: (urls: string[]) => void;
  maxImages?: number;
  onError?: (error: string) => void;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  value = [],
  onChange,
  maxImages = 10,
  onError,
}) => {
  return (
    <div className="space-y-6">
      <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
        {value.map((url, index) => (
          <div key={url} className="relative group">
            <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
              <ImageHandler
                src={url}
                alt={`Product ${index + 1}`}
                className="w-full h-full object-cover"
                priority={index === 0}
                onError={() => onError?.(`Failed to load image ${index + 1}`)}
              />
            </div>
            {index === 0 && (
              <div className="absolute top-2 left-2 bg-white/90 text-gray-700 px-2 py-1 rounded-full text-sm font-medium shadow-sm">
                Main Photo
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
