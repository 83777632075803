import React from 'react';
import { Control, useController } from 'react-hook-form';

interface DimensionsFieldsProps {
  control: Control<any>;
  initialData?: any;
}

export const DimensionsFields: React.FC<DimensionsFieldsProps> = ({ control, initialData }) => {
  const { field: widthField } = useController({
    name: 'width_inches',
    control,
    defaultValue: initialData?.width_inches ?? '',
  });

  const { field: heightField } = useController({
    name: 'height_inches',
    control,
    defaultValue: initialData?.height_inches ?? '',
  });

  const { field: depthField } = useController({
    name: 'depth_inches',
    control,
    defaultValue: initialData?.depth_inches ?? '',
  });

  const handleNumberInput = (e: React.ChangeEvent<HTMLInputElement>, field: any) => {
    const value = e.target.value;
    field.onChange(value === '' ? '' : parseFloat(value));
  };

  return (
    <div className="grid grid-cols-3 gap-6">
      <div>
        <label htmlFor="width_inches" className="block text-base font-medium text-gray-700 mb-2">
          Width (inches)
        </label>
        <input
          type="number"
          id="width_inches"
          step="0.1"
          value={widthField.value}
          onChange={e => handleNumberInput(e, widthField)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
        />
      </div>
      <div>
        <label htmlFor="height_inches" className="block text-base font-medium text-gray-700 mb-2">
          Height (inches)
        </label>
        <input
          type="number"
          id="height_inches"
          step="0.1"
          value={heightField.value}
          onChange={e => handleNumberInput(e, heightField)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
        />
      </div>
      <div>
        <label htmlFor="depth_inches" className="block text-base font-medium text-gray-700 mb-2">
          Depth (inches)
        </label>
        <input
          type="number"
          id="depth_inches"
          step="0.1"
          value={depthField.value}
          onChange={e => handleNumberInput(e, depthField)}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
        />
      </div>
    </div>
  );
};
