import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { toast } from 'sonner';
import { Upload } from 'lucide-react';
import { DraggableImage } from './DraggableImage';
import { removeBackground } from '@/lib/imageUtils/backgroundRemoval';

interface ImageUploaderProps {
  value: string[];
  onChange: (urls: string[]) => void;
  maxImages?: number;
  onError?: (error: string) => void;
}

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  value = [],
  onChange,
  maxImages = 10,
  onError,
}) => {
  const [uploading, setUploading] = useState(false);
  const [processingImage, setProcessingImage] = useState<string | null>(null);

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const uploadToCloudinary = async (file: File | Blob): Promise<string> => {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('upload_preset', import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET);
    formData.append('folder', 'products');

    const response = await fetch(
      `https://api.cloudinary.com/v1_1/${import.meta.env.VITE_CLOUDINARY_CLOUD_NAME}/image/upload`,
      {
        method: 'POST',
        body: formData,
      }
    );

    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.message || 'Failed to upload image');
    }

    const data = await response.json();
    return data.secure_url;
  };

  const onDrop = useCallback(
    async (acceptedFiles: File[]) => {
      if (value.length + acceptedFiles.length > maxImages) {
        onError?.(`Maximum ${maxImages} images allowed`);
        return;
      }

      setUploading(true);
      const toastId = toast.loading('Uploading images...');

      try {
        const uploadPromises = acceptedFiles.map(async file => {
          try {
            // Validate file size
            if (file.size > 5 * 1024 * 1024) {
              // 5MB
              throw new Error('File size must be less than 5MB');
            }

            // Upload to Cloudinary
            const url = await uploadToCloudinary(file);
            return url;
          } catch (error: any) {
            console.error('Error uploading file:', error);
            throw error;
          }
        });

        const urls = await Promise.all(uploadPromises);
        onChange([...value, ...urls]);
        toast.success('Images uploaded successfully', { id: toastId });
      } catch (error: any) {
        console.error('Upload error:', error);
        onError?.(error.message || 'Failed to upload images');
        toast.error(error.message || 'Failed to upload images', { id: toastId });
      } finally {
        setUploading(false);
      }
    },
    [value, onChange, maxImages, onError]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.jpg', '.png', '.webp'],
    },
    maxSize: 5 * 1024 * 1024, // 5MB
    disabled: uploading || value.length >= maxImages,
  });

  const handleDragEnd = useCallback(
    (event: any) => {
      const { active, over } = event;

      if (over && active.id !== over.id) {
        const oldIndex = value.indexOf(active.id);
        const newIndex = value.indexOf(over.id);

        if (oldIndex !== -1 && newIndex !== -1) {
          const newOrder = arrayMove(value, oldIndex, newIndex);
          onChange(newOrder);
        }
      }
    },
    [value, onChange]
  );

  const removeImage = useCallback(
    async (urlToRemove: string) => {
      try {
        onChange(value.filter(url => url !== urlToRemove));
        toast.success('Image removed');
      } catch (error: any) {
        console.error('Error removing image:', error);
        onError?.(error.message || 'Failed to remove image');
        toast.error('Failed to remove image');
      }
    },
    [value, onChange, onError]
  );

  const handleRemoveBackground = useCallback(
    async (imageUrl: string) => {
      if (processingImage) return;

      setProcessingImage(imageUrl);
      const toastId = toast.loading('Removing background...');

      try {
        const processedBlob = await removeBackground(imageUrl);
        const newUrl = await uploadToCloudinary(processedBlob);

        // Update the images array
        const newValue = value.map(url => (url === imageUrl ? newUrl : url));
        onChange(newValue);

        toast.success('Background removed successfully', { id: toastId });
      } catch (error: any) {
        console.error('Background removal error:', error);
        toast.error(error.message || 'Failed to remove background', { id: toastId });
      } finally {
        setProcessingImage(null);
      }
    },
    [processingImage, value, onChange]
  );

  return (
    <div className="space-y-6">
      {/* Image Grid */}
      {value.length > 0 && (
        <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
          <SortableContext items={value} strategy={rectSortingStrategy}>
            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
              {value.map((url, index) => (
                <DraggableImage
                  key={url}
                  url={url}
                  index={index}
                  onRemove={() => removeImage(url)}
                  onRemoveBackground={() => handleRemoveBackground(url)}
                  isProcessing={processingImage === url}
                />
              ))}
            </div>
          </SortableContext>
        </DndContext>
      )}

      {/* Dropzone */}
      {value.length < maxImages && (
        <div
          {...getRootProps()}
          className={`
            border-2 border-dashed rounded-lg p-8 text-center cursor-pointer
            transition-colors
            ${isDragActive ? 'border-stone-400 bg-stone-50' : 'border-gray-300 hover:border-stone-400'}
            ${uploading ? 'opacity-50 cursor-not-allowed' : ''}
          `}
          onClick={e => e.stopPropagation()}
        >
          <input {...getInputProps()} />
          <Upload className="mx-auto h-12 w-12 text-gray-400" />
          <div className="mt-4">
            <p className="text-sm text-gray-600">
              {isDragActive ? 'Drop the files here' : 'Drag & drop photos here, or click to select'}
            </p>
            <p className="text-xs text-gray-500 mt-1">
              {value.length} of {maxImages} images uploaded
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
