import React from 'react';
import { Button } from '../ui/Button';

interface EventFiltersProps {
  activeFilter: 'all' | 'upcoming' | 'past';
  onFilterChange: (filter: 'all' | 'upcoming' | 'past') => void;
}

export const EventFilters: React.FC<EventFiltersProps> = ({ activeFilter, onFilterChange }) => {
  return (
    <div className="flex flex-wrap gap-2 mb-8">
      <Button
        variant={activeFilter === 'upcoming' ? 'primary' : 'secondary'}
        size="sm"
        onClick={() => onFilterChange('upcoming')}
      >
        Upcoming Events
      </Button>

      <Button
        variant={activeFilter === 'past' ? 'primary' : 'secondary'}
        size="sm"
        onClick={() => onFilterChange('past')}
      >
        Past Events
      </Button>

      <Button
        variant={activeFilter === 'all' ? 'primary' : 'secondary'}
        size="sm"
        onClick={() => onFilterChange('all')}
      >
        All Events
      </Button>
    </div>
  );
};
