import { useState, useEffect, useCallback } from 'react';
import { Product } from '@/components/FeaturedPieces/types';

const STORAGE_KEY = 'recently_viewed';
const MAX_ITEMS = 6;

export const useRecentlyViewed = () => {
  const [recentlyViewed, setRecentlyViewed] = useState<Product[]>([]);

  // Load recently viewed items from localStorage on mount
  useEffect(() => {
    try {
      const stored = localStorage.getItem(STORAGE_KEY);
      if (stored) {
        const parsed = JSON.parse(stored);
        if (Array.isArray(parsed)) {
          // Validate and clean stored data
          const validItems = parsed.filter((item): item is Product => {
            return (
              typeof item === 'object' &&
              item !== null &&
              'id' in item &&
              'name' in item &&
              'images' in item &&
              Array.isArray(item.images) &&
              item.images.length > 0
            );
          });
          setRecentlyViewed(validItems.slice(0, MAX_ITEMS));
        }
      }
    } catch (error) {
      console.error('Failed to load recently viewed items:', error);
      localStorage.removeItem(STORAGE_KEY);
    }
  }, []);

  const addToRecentlyViewed = useCallback((product: Product) => {
    if (!product?.id || !product?.images?.[0]) return;

    setRecentlyViewed(current => {
      // Remove if already exists
      const filtered = current.filter(item => item.id !== product.id);

      // Add to front of array and limit size
      const updated = [product, ...filtered].slice(0, MAX_ITEMS);

      // Save to localStorage
      try {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(updated));
      } catch (error) {
        console.error('Failed to save recently viewed:', error);
      }

      return updated;
    });
  }, []);

  return {
    recentlyViewed,
    addToRecentlyViewed,
  };
};
