import React from 'react';
import { Link } from 'react-router-dom';
import {
  Mail,
  QrCode,
  ChevronUp,
  Newspaper,
  Instagram,
  ExternalLink,
  Calendar,
} from 'lucide-react';

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className="bg-gray-900 text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {/* Column 1: About */}
          <div>
            <h3 className="text-lg font-medium mb-4">Show Pony Gallery</h3>
            <p className="text-gray-400 mb-4">
              A curated collection of fine art and distinguished acquisitions in West Palm Beach.
              Each piece tells a unique story, thoughtfully selected for refined living.
            </p>
            <div className="flex space-x-4">
              <a
                href="https://www.instagram.com/showponygallerypb/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
                aria-label="Instagram"
              >
                <Instagram className="h-5 w-5" />
              </a>
              <a
                href="https://www.chairish.com/shop/showponygallery"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
                aria-label="Chairish"
              >
                <ExternalLink className="h-5 w-5" />
              </a>
            </div>
          </div>

          {/* Column 2: Quick Links */}
          <div>
            <h3 className="text-lg font-medium mb-4">Quick Links</h3>
            <ul className="space-y-2">
              <li>
                <Link to="/collection" className="text-gray-400 hover:text-white transition-colors">
                  Collection
                </Link>
              </li>
              <li>
                <Link to="/events" className="text-gray-400 hover:text-white transition-colors">
                  Events
                </Link>
              </li>
              <li>
                <Link to="/blog" className="text-gray-400 hover:text-white transition-colors">
                  Blog
                </Link>
              </li>
              <li>
                <Link
                  to="/contact-card"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  Contact Card
                </Link>
              </li>
              <li>
                <Link to="/admin" className="text-gray-400 hover:text-white transition-colors">
                  Admin Portal
                </Link>
              </li>
            </ul>
          </div>

          {/* Column 3: Contact */}
          <div>
            <h3 className="text-lg font-medium mb-4">Contact Us</h3>
            <address className="not-italic text-gray-400 space-y-2">
              <p>5011 S Dixie Hwy</p>
              <p>West Palm Beach, FL 33405</p>
              <a
                href="tel:+19174559307"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                (917) 455-9307
              </a>
              <a
                href="mailto:Showponygallerypb@gmail.com"
                className="block text-gray-400 hover:text-white transition-colors"
              >
                Showponygallerypb@gmail.com
              </a>
            </address>
          </div>
        </div>

        <div className="border-t border-gray-800 pt-8 flex flex-col items-center gap-4">
          <button
            onClick={scrollToTop}
            className="bg-gray-800 hover:bg-gray-700 p-2 rounded-full transition-colors mb-4"
            aria-label="Scroll to top"
          >
            <ChevronUp className="h-5 w-5" />
          </button>

          <div className="flex flex-wrap justify-center gap-4">
            <a
              href="mailto:Showponygallerypb@gmail.com"
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              aria-label="Email us"
            >
              <Mail className="h-5 w-5" />
              <span>Contact Us</span>
            </a>

            <Link
              to="/events"
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              aria-label="View our events"
            >
              <Calendar className="h-5 w-5" />
              <span>Events</span>
            </Link>

            <Link
              to="/blog"
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              aria-label="Read our blog"
            >
              <Newspaper className="h-5 w-5" />
              <span>Blog</span>
            </Link>

            <Link
              to="/contact-card"
              className="flex items-center gap-2 text-gray-400 hover:text-white transition-colors"
              aria-label="View our contact card"
            >
              <QrCode className="h-5 w-5" />
              <span>Contact Card</span>
            </Link>
          </div>

          <div className="text-center text-gray-400 text-sm">
            <p>&copy; {new Date().getFullYear()} Show Pony Gallery. All rights reserved.</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
