import React from 'react';
import { Link } from 'react-router-dom';
import { Event } from './types';
import { ImageHandler } from '../ui/ImageHandler';
import { Calendar, Clock, MapPin, ArrowRight } from 'lucide-react';
import { formatEventDate, isEventPast } from './utils';

interface EventCardProps {
  event: Event;
}

export const EventCard: React.FC<EventCardProps> = ({ event }) => {
  const isPast = isEventPast(event.date);

  return (
    <article className="bg-white rounded-lg shadow-sm overflow-hidden h-full flex flex-col">
      <Link to={`/events/${event.slug}`} className="block aspect-[16/9] overflow-hidden">
        <div className="aspect-placeholder" data-ratio="16:9">
          <ImageHandler
            src={event.image}
            alt={event.title}
            className="w-full h-full object-cover transition-transform hover:scale-105 duration-300"
          />
        </div>

        {isPast && (
          <div className="absolute top-3 right-3 bg-gray-800 text-white text-xs font-medium px-2.5 py-1 rounded">
            Past Event
          </div>
        )}

        {!isPast && event.featured && (
          <div className="absolute top-3 right-3 bg-amber-600 text-white text-xs font-medium px-2.5 py-1 rounded">
            Featured
          </div>
        )}
      </Link>

      <div className="p-6 flex-1 flex flex-col">
        <div className="mb-4">
          <span className="inline-block bg-amber-100 text-amber-800 text-xs font-medium px-2.5 py-1 rounded">
            {event.type}
          </span>
        </div>

        <h3 className="text-xl font-medium mb-3">
          <Link to={`/events/${event.slug}`} className="hover:text-amber-800 transition-colors">
            {event.title}
          </Link>
        </h3>

        <div className="space-y-2 mb-4 text-gray-600 text-sm">
          <div className="flex items-start">
            <Calendar className="w-4 h-4 mt-0.5 flex-shrink-0" />
            <span className="ml-2">{formatEventDate(event.date, event.endDate)}</span>
          </div>

          {event.time && (
            <div className="flex items-start">
              <Clock className="w-4 h-4 mt-0.5 flex-shrink-0" />
              <span className="ml-2">{event.time}</span>
            </div>
          )}

          {event.location && (
            <div className="flex items-start">
              <MapPin className="w-4 h-4 mt-0.5 flex-shrink-0" />
              <span className="ml-2">{event.location}</span>
            </div>
          )}
        </div>

        <p className="text-gray-600 mb-4 line-clamp-3 flex-1">{event.description}</p>

        <Link
          to={`/events/${event.slug}`}
          className="text-amber-800 hover:text-amber-900 font-medium text-sm inline-flex items-center mt-auto"
        >
          {isPast ? 'View Details' : 'Learn More'}
          <ArrowRight className="ml-1 w-4 h-4" />
        </Link>
      </div>
    </article>
  );
};
