import { useState, useEffect } from 'react';
import { Event } from '../types';
import { supabase } from '@/lib/supabase';

export const useEvents = () => {
  const [events, setEvents] = useState<Event[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchEvents = async () => {
      try {
        setLoading(true);
        const { data, error: fetchError } = await supabase
          .from('events')
          .select(
            `
            *,
            artists:event_artists(*)
          `
          )
          .order('date', { ascending: true });

        if (fetchError) throw fetchError;

        // Transform the data to match our Event type
        const transformedEvents =
          data?.map(event => ({
            id: event.id,
            title: event.title,
            slug: event.slug,
            description: event.description,
            longDescription: event.long_description,
            image: event.image,
            date: event.date,
            endDate: event.end_date,
            time: event.time,
            location: event.location,
            type: event.type,
            featured: event.featured,
            capacity: event.capacity,
            price: event.price,
            ticketLink: event.ticket_link,
            artists: event.artists,
          })) || [];

        setEvents(transformedEvents);
        setError(null);
      } catch (err) {
        console.error('Error fetching events:', err);
        setError('Failed to load events');
      } finally {
        setLoading(false);
      }
    };

    fetchEvents();

    // Set up real-time subscription
    const subscription = supabase
      .channel('events_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'events',
        },
        () => {
          fetchEvents();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  // Get a single event by slug
  const getEventBySlug = (slug: string): Event | null => {
    return events.find(event => event.slug === slug) || null;
  };

  // Get related events (excluding the current event)
  const getRelatedEvents = (currentEventId: string, limit: number = 3): Event[] => {
    const currentEvent = events.find(event => event.id === currentEventId);
    if (!currentEvent) return [];

    // Get events with matching type
    const relatedByType = events.filter(
      event => event.id !== currentEventId && event.type === currentEvent.type
    );

    // If we have enough related by type, return those
    if (relatedByType.length >= limit) {
      return relatedByType.slice(0, limit);
    }

    // Otherwise, add other events to fill the limit
    const otherEvents = events.filter(
      event =>
        event.id !== currentEventId && !relatedByType.some(related => related.id === event.id)
    );

    return [...relatedByType, ...otherEvents].slice(0, limit);
  };

  return {
    events,
    loading,
    error,
    getEventBySlug,
    getRelatedEvents,
  };
};
