import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card } from '../ui/Card';
import { useBlogPosts } from './hooks/useBlogPosts';
import { Search, Mail, ArrowRight } from 'lucide-react';

export const BlogSidebar: React.FC = () => {
  const { posts } = useBlogPosts();
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  // Get recent posts
  const recentPosts = posts.slice(0, 5);

  const handleSearch = (e: React.FormEvent) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/blog?search=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  return (
    <div className="space-y-6">
      {/* Search */}
      <Card className="p-4">
        <h3 className="text-lg font-medium mb-3">Search</h3>
        <form onSubmit={handleSearch} className="relative">
          <input
            type="text"
            placeholder="Search artist profiles..."
            value={searchQuery}
            onChange={e => setSearchQuery(e.target.value)}
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-amber-500 focus:border-amber-500"
            aria-label="Search artist profiles"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          <button
            type="submit"
            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-amber-600 hover:text-amber-800"
            aria-label="Submit search"
          >
            <ArrowRight className="w-4 h-4" />
          </button>
        </form>
      </Card>

      {/* Recent Posts */}
      <Card className="p-4">
        <h3 className="text-lg font-medium mb-3">Recent Profiles</h3>
        <ul className="space-y-4">
          {recentPosts.map(post => (
            <li key={post.slug} className="border-b border-gray-100 pb-3 last:border-0 last:pb-0">
              <Link to={`/blog/${post.slug}`} className="hover:text-amber-800 transition-colors">
                <h4 className="font-medium line-clamp-2">{post.title}</h4>
                <p className="text-sm text-gray-500 mt-1">{post.date}</p>
              </Link>
            </li>
          ))}
        </ul>
      </Card>

      {/* Newsletter Signup */}
      <Card className="p-4 bg-amber-50">
        <h3 className="text-lg font-medium mb-3">Subscribe to Our Newsletter</h3>
        <p className="text-sm text-gray-600 mb-4">
          Stay updated with our latest artist profiles and gallery news.
        </p>
        <form className="space-y-3" onSubmit={e => e.preventDefault()}>
          <input
            type="email"
            placeholder="Your email address"
            className="w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-amber-500 focus:border-amber-500"
            aria-label="Email for newsletter"
            required
          />
          <button
            type="submit"
            className="w-full bg-amber-800 hover:bg-amber-900 text-white py-2 rounded-md transition-colors flex items-center justify-center gap-2"
          >
            <Mail className="w-4 h-4" />
            <span>Subscribe</span>
          </button>
        </form>
        <p className="text-xs text-gray-500 mt-3">
          We respect your privacy and will never share your information.
        </p>
      </Card>
    </div>
  );
};
