import React, { useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useEvents } from './hooks/useEvents';
import { EventCard } from './EventCard';
import { EventFilters } from './EventFilters';
import { Breadcrumbs } from '../ui/Breadcrumbs';
import { Calendar, Clock, MapPin } from 'lucide-react';

export const EventsPage: React.FC = () => {
  const { events, loading, error } = useEvents();
  const [filter, setFilter] = useState<'all' | 'upcoming' | 'past'>('upcoming');

  // Get current date for filtering
  const now = new Date();

  // Filter events based on selected filter
  const filteredEvents = events.filter(event => {
    const eventDate = new Date(event.date);

    if (filter === 'upcoming') {
      return eventDate >= now;
    } else if (filter === 'past') {
      return eventDate < now;
    }

    return true; // 'all' filter
  });

  // Sort events by date
  const sortedEvents = [...filteredEvents].sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);

    if (filter === 'upcoming') {
      return dateA.getTime() - dateB.getTime(); // Ascending for upcoming
    } else {
      return dateB.getTime() - dateA.getTime(); // Descending for past
    }
  });

  // Breadcrumb items
  const breadcrumbItems = [
    {
      label: 'Events',
      href: '/events',
    },
  ];

  return (
    <>
      <Helmet>
        <title>Gallery Events | Show Pony Gallery</title>
        <meta
          name="description"
          content="Discover upcoming exhibitions, openings, artist talks, and special events at Show Pony Gallery in West Palm Beach."
        />
        <meta
          name="keywords"
          content="art events, gallery openings, exhibitions, artist talks, West Palm Beach, art gallery events"
        />

        <meta property="og:title" content="Gallery Events | Show Pony Gallery" />
        <meta
          property="og:description"
          content="Discover upcoming exhibitions, openings, artist talks, and special events at Show Pony Gallery in West Palm Beach."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://showponygallery.com/events" />

        <link rel="canonical" href="https://showponygallery.com/events" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <Breadcrumbs items={breadcrumbItems} className="mb-6" />

        <div className="mb-8">
          <h1 className="text-3xl md:text-4xl font-medium mb-4">Gallery Events</h1>
          <p className="text-gray-600 max-w-3xl">
            Join us for exhibitions, openings, artist talks, and special events at Show Pony
            Gallery. Discover new artists, engage with our community, and experience the vibrant art
            scene of West Palm Beach.
          </p>
        </div>

        <EventFilters activeFilter={filter} onFilterChange={setFilter} />

        {loading ? (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="animate-pulse bg-white rounded-lg shadow-sm p-6">
                <div className="bg-gray-200 h-48 rounded-lg mb-4"></div>
                <div className="bg-gray-200 h-6 w-3/4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
              </div>
            ))}
          </div>
        ) : error ? (
          <div className="text-center py-12">
            <p className="text-red-600 mb-4">{error}</p>
          </div>
        ) : sortedEvents.length === 0 ? (
          <div className="text-center py-12 bg-gray-50 rounded-lg">
            <Calendar className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-xl font-medium text-gray-700 mb-2">
              {filter === 'upcoming'
                ? 'No upcoming events'
                : filter === 'past'
                  ? 'No past events'
                  : 'No events found'}
            </h3>
            <p className="text-gray-500">
              {filter === 'upcoming'
                ? 'Check back soon for new events!'
                : filter === 'past'
                  ? 'Past events will appear here after they occur.'
                  : 'Please try a different filter.'}
            </p>
          </div>
        ) : (
          <div className="grid gap-8 md:grid-cols-2 lg:grid-cols-3">
            {sortedEvents.map(event => (
              <EventCard key={event.id} event={event} />
            ))}
          </div>
        )}

        {/* Featured Event Information */}
        {filter === 'upcoming' && sortedEvents.length > 0 && (
          <div className="mt-16 bg-amber-50 rounded-lg p-8">
            <h2 className="text-2xl font-medium mb-6">Visit Our Gallery</h2>
            <div className="grid md:grid-cols-3 gap-6">
              <div className="flex items-start">
                <MapPin className="w-5 h-5 text-amber-800 mt-1 flex-shrink-0" />
                <div className="ml-3">
                  <h3 className="font-medium">Location</h3>
                  <address className="not-italic text-gray-600">
                    5011 S Dixie Hwy
                    <br />
                    West Palm Beach, FL 33405
                  </address>
                </div>
              </div>

              <div className="flex items-start">
                <Clock className="w-5 h-5 text-amber-800 mt-1 flex-shrink-0" />
                <div className="ml-3">
                  <h3 className="font-medium">Gallery Hours</h3>
                  <p className="text-gray-600">
                    Monday - Saturday: 11:00 AM - 5:00 PM
                    <br />
                    Sunday: Closed
                  </p>
                </div>
              </div>

              <div className="flex items-start">
                <Calendar className="w-5 h-5 text-amber-800 mt-1 flex-shrink-0" />
                <div className="ml-3">
                  <h3 className="font-medium">Private Viewings</h3>
                  <p className="text-gray-600">
                    Available by appointment.
                    <br />
                    Contact us at (917) 455-9307
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
