import React, { useState, useRef, useEffect } from 'react';
import { X } from 'lucide-react';

interface ImageMagnifierProps {
  src: string;
  alt: string;
  onClose?: () => void;
}

export const ImageMagnifier: React.FC<ImageMagnifierProps> = ({ src, alt, onClose }) => {
  const [position, setPosition] = useState({ x: 50, y: 50 });
  const [showMagnifier, setShowMagnifier] = useState(false);
  const [touchPosition, setTouchPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(2.5);
  const containerRef = useRef<HTMLDivElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const handleMouseMove = (e: MouseEvent) => {
      const { left, top, width, height } = container.getBoundingClientRect();
      const x = ((e.clientX - left) / width) * 100;
      const y = ((e.clientY - top) / height) * 100;
      setPosition({
        x: Math.max(0, Math.min(100, x)),
        y: Math.max(0, Math.min(100, y)),
      });
      setShowMagnifier(true);
    };

    const handleMouseLeave = () => {
      setShowMagnifier(false);
    };

    container.addEventListener('mousemove', handleMouseMove);
    container.addEventListener('mouseleave', handleMouseLeave);

    return () => {
      container.removeEventListener('mousemove', handleMouseMove);
      container.removeEventListener('mouseleave', handleMouseLeave);
    };
  }, []);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touch = e.touches[0];
    setTouchPosition({ x: touch.clientX, y: touch.clientY });
    setIsDragging(true);
    setShowMagnifier(true);

    // Set initial position based on touch
    if (containerRef.current) {
      const { left, top, width, height } = containerRef.current.getBoundingClientRect();
      const x = ((touch.clientX - left) / width) * 100;
      const y = ((touch.clientY - top) / height) * 100;
      setPosition({
        x: Math.max(0, Math.min(100, x)),
        y: Math.max(0, Math.min(100, y)),
      });
    }
  };

  const handleTouchMove = (e: React.TouchEvent) => {
    if (!isDragging || !containerRef.current) return;

    const touch = e.touches[0];
    const { left, top, width, height } = containerRef.current.getBoundingClientRect();

    const x = Math.max(0, Math.min(100, ((touch.clientX - left) / width) * 100));
    const y = Math.max(0, Math.min(100, ((touch.clientY - top) / height) * 100));

    setPosition({ x, y });
    setTouchPosition({ x: touch.clientX, y: touch.clientY });
  };

  const handleTouchEnd = () => {
    setIsDragging(false);
    setShowMagnifier(false);
  };

  const handleZoomIn = () => {
    setZoomLevel(prev => Math.min(prev + 0.5, 5));
  };

  const handleZoomOut = () => {
    setZoomLevel(prev => Math.max(prev - 0.5, 1.5));
  };

  return (
    <div className="relative w-full aspect-square bg-white rounded-xl overflow-hidden">
      <div
        ref={containerRef}
        className="relative w-full h-full cursor-zoom-in"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <img ref={imageRef} src={src} alt={alt} className="w-full h-full object-contain" />

        {showMagnifier && (
          <div
            className="absolute pointer-events-none border border-gray-200 bg-white rounded-lg shadow-xl z-10"
            style={{
              width: '200px',
              height: '200px',
              top: `${position.y}%`,
              left: `${position.x}%`,
              transform: 'translate(-50%, -50%)',
              backgroundImage: `url('${src}')`,
              backgroundPosition: `${position.x}% ${position.y}%`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: `${zoomLevel * 100}%`,
              opacity: 0.95,
            }}
          />
        )}
      </div>

      {/* Zoom controls */}
      <div className="absolute bottom-4 right-4 flex items-center gap-2 bg-white/80 backdrop-blur-sm rounded-full p-1 shadow-md">
        <button
          onClick={handleZoomOut}
          className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100"
          aria-label="Zoom out"
        >
          <span className="text-xl font-bold">−</span>
        </button>
        <span className="text-sm font-medium">{zoomLevel.toFixed(1)}x</span>
        <button
          onClick={handleZoomIn}
          className="w-8 h-8 flex items-center justify-center rounded-full hover:bg-gray-100"
          aria-label="Zoom in"
        >
          <span className="text-xl font-bold">+</span>
        </button>
      </div>

      {/* Instructions */}
      <div className="absolute bottom-4 left-4 text-xs text-gray-600 bg-white/80 backdrop-blur-sm rounded-md px-2 py-1">
        Move cursor to magnify
      </div>

      {onClose && (
        <button
          onClick={onClose}
          className="absolute top-4 right-4 p-2 bg-white/90 hover:bg-white rounded-full shadow-lg transition-colors"
        >
          <X className="w-5 h-5" />
        </button>
      )}
    </div>
  );
};
