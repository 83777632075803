import React from 'react';
import { Helmet } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { BlogSidebar } from './BlogSidebar';
import { Breadcrumbs } from '../ui/Breadcrumbs';

export const BlogLayout: React.FC = () => {
  const location = useLocation();
  const isArticlePage = location.pathname.includes('/blog/') && location.pathname !== '/blog/';
  const isSearchPage = location.search.includes('search=');

  // Extract search query from URL if on a search page
  const searchQuery = isSearchPage ? new URLSearchParams(location.search).get('search') : null;

  const breadcrumbItems = [
    {
      label: 'Artist Profiles',
      href: '/blog',
    },
  ];

  if (isSearchPage && searchQuery) {
    breadcrumbItems.push({
      label: `Search: ${searchQuery}`,
    });
  }

  if (isArticlePage) {
    breadcrumbItems.push({
      label: 'Artist Profile',
    });
  }

  return (
    <>
      <Helmet>
        <title>Artist Profiles | Show Pony Gallery</title>
        <meta
          name="description"
          content="Explore our featured artist profiles, showcasing the talented creators behind our exceptional collection."
        />
        <meta
          name="keywords"
          content="artist profiles, featured artists, art gallery, West Palm Beach"
        />

        <meta property="og:title" content="Artist Profiles | Show Pony Gallery" />
        <meta
          property="og:description"
          content="Explore our featured artist profiles, showcasing the talented creators behind our exceptional collection."
        />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://showponygallery.com/blog" />

        <link rel="canonical" href="https://showponygallery.com/blog" />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <Breadcrumbs items={breadcrumbItems} className="mb-6" />

        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          <div className="lg:col-span-8 order-2 lg:order-1">
            <Outlet />
          </div>

          <div className="lg:col-span-4 order-1 lg:order-2">
            <BlogSidebar />
          </div>
        </div>
      </div>
    </>
  );
};
