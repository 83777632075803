import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Search } from './ui/Search';
import { Menu, X, ChevronDown, Home } from 'lucide-react';
import { categories } from './nav/categories';

const HOVER_DELAY = 200;

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const timeoutRef = useRef<NodeJS.Timeout>();
  const navRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Close mobile menu when route changes
  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [location.pathname, location.search]);

  // Handle keyboard navigation
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape' && isMobileMenuOpen) {
        setIsMobileMenuOpen(false);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [isMobileMenuOpen]);

  const handleLogoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    // Reset all filters and navigate home
    navigate('/', { replace: true });

    // Reset any active filters by dispatching an event
    window.dispatchEvent(
      new CustomEvent('filterProducts', {
        detail: { showAll: true },
      })
    );

    // Scroll to top
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleMouseEnter = (categoryName: string) => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setActiveCategory(categoryName);
    }, HOVER_DELAY);
  };

  const handleMouseLeave = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(() => {
      setActiveCategory(null);
    }, HOVER_DELAY);
  };

  const handleCategoryClick = (category: string) => {
    // Navigate directly to collection with category parameter
    navigate(`/collection?category=${encodeURIComponent(category)}`);
    setIsMobileMenuOpen(false);
  };

  const handleSubcategoryClick = (category: string, subcategory: string) => {
    // Navigate directly to collection with both category and subcategory parameters
    navigate(
      `/collection?category=${encodeURIComponent(category)}&subcategory=${encodeURIComponent(subcategory)}`
    );
    setIsMobileMenuOpen(false);
  };

  return (
    <nav className="fixed w-full z-50 pt-safe" ref={navRef}>
      {/* Main Navigation Bar */}
      <div
        className={`bg-white/95 backdrop-blur-sm border-b relative z-50 ${
          isScrolled ? 'shadow-sm' : ''
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="h-16 md:h-20 grid grid-cols-3 items-center">
            <div className="flex items-center">
              <Search />
            </div>

            <div className="flex justify-center">
              <button
                onClick={handleLogoClick}
                className="block focus:outline-none focus:ring-2 focus:ring-amber-500 rounded-md"
                aria-label="Home"
              >
                <img
                  src="https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg"
                  alt="Show Pony Gallery"
                  className="h-12 md:h-16 w-auto object-contain max-w-none"
                  loading="eager"
                  fetchpriority="high"
                />
              </button>
            </div>

            <div className="flex items-center justify-end">
              <Link
                to="/"
                className="mr-4 text-gray-700 hover:text-amber-800 transition-colors hidden md:flex items-center"
                aria-label="Home"
              >
                <Home className="w-5 h-5" />
              </Link>

              <button
                onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
                className="md:hidden p-2 text-gray-700 hover:text-amber-800 transition-colors"
                aria-expanded={isMobileMenuOpen}
                aria-controls="mobile-menu"
                aria-label={isMobileMenuOpen ? 'Close menu' : 'Open menu'}
              >
                {isMobileMenuOpen ? <X className="h-6 w-6" /> : <Menu className="h-6 w-6" />}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Categories Bar - Desktop */}
      <div
        className={`hidden md:block bg-white/95 backdrop-blur-sm border-b transition-shadow duration-300 z-40 ${
          isScrolled ? 'shadow-sm' : ''
        }`}
      >
        <div className="max-w-7xl mx-auto px-4 py-2">
          <div className="flex justify-center space-x-8 py-1">
            {categories.map(category => (
              <div
                key={category.name}
                className="relative group"
                onMouseEnter={() => handleMouseEnter(category.name)}
                onMouseLeave={handleMouseLeave}
              >
                <button
                  onClick={() => handleCategoryClick(category.name)}
                  className="text-sm text-gray-700 hover:text-amber-800 transition-colors flex items-center gap-1 group focus:outline-none focus:ring-2 focus:ring-amber-500 rounded-md px-2 py-1"
                  aria-expanded={activeCategory === category.name}
                  aria-controls={`dropdown-${category.name}`}
                >
                  <span>{category.name}</span>
                  {category.subcategories && (
                    <ChevronDown
                      className={`w-4 h-4 text-gray-400 transition-transform duration-200 ${
                        activeCategory === category.name ? 'rotate-180' : ''
                      }`}
                    />
                  )}
                </button>

                {category.subcategories && activeCategory === category.name && (
                  <div
                    id={`dropdown-${category.name}`}
                    className="absolute left-0 top-full mt-1 bg-white shadow-lg rounded-lg py-2 min-w-[160px] z-30"
                    role="menu"
                    aria-orientation="vertical"
                  >
                    {category.subcategories.map(sub => (
                      <button
                        key={sub}
                        onClick={() => handleSubcategoryClick(category.name, sub)}
                        className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 hover:text-amber-800 focus:outline-none focus:bg-gray-50 focus:text-amber-800"
                        role="menuitem"
                      >
                        {sub}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      <div
        className={`md:hidden fixed inset-0 bg-black/50 backdrop-blur-sm transition-opacity duration-300 z-30 ${
          isMobileMenuOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'
        }`}
        onClick={() => setIsMobileMenuOpen(false)}
        aria-hidden="true"
      />
      <div
        id="mobile-menu"
        className={`md:hidden fixed right-0 top-[64px] bottom-0 w-64 bg-[#1a1f2e] transform transition-transform duration-300 z-40 overflow-y-auto pb-safe ${
          isMobileMenuOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
        aria-label="Mobile navigation"
      >
        <div className="p-4 space-y-2">
          <Link
            to="/"
            className="flex items-center gap-2 text-white hover:text-amber-300 transition-colors py-2 w-full"
            onClick={() => setIsMobileMenuOpen(false)}
          >
            <Home className="w-5 h-5" />
            <span>Home</span>
          </Link>

          {categories.map(category => (
            <div key={category.name} className="space-y-1">
              <button
                onClick={() => handleCategoryClick(category.name)}
                className="w-full text-left text-white hover:text-amber-300 transition-colors py-2 flex items-center justify-between focus:outline-none focus:ring-2 focus:ring-amber-500 rounded-md px-2"
                aria-expanded={activeCategory === category.name}
                aria-controls={`mobile-dropdown-${category.name}`}
              >
                <span>{category.name}</span>
                {category.subcategories && (
                  <ChevronDown
                    className={`w-4 h-4 transition-transform duration-200 ${
                      activeCategory === category.name ? 'rotate-180' : ''
                    }`}
                    onClick={e => {
                      e.stopPropagation();
                      setActiveCategory(activeCategory === category.name ? null : category.name);
                    }}
                  />
                )}
              </button>
              {category.subcategories && activeCategory === category.name && (
                <div
                  id={`mobile-dropdown-${category.name}`}
                  className="pl-4 space-y-1"
                  role="menu"
                  aria-orientation="vertical"
                >
                  {category.subcategories.map(sub => (
                    <button
                      key={sub}
                      onClick={() => handleSubcategoryClick(category.name, sub)}
                      className="w-full text-left text-gray-300 hover:text-amber-300 transition-colors py-1.5 text-sm focus:outline-none focus:text-amber-300 px-2 rounded-md"
                      role="menuitem"
                    >
                      {sub}
                    </button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
