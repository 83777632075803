import React from 'react';
import { useNavigate } from 'react-router-dom';
import { ImageHandler } from '../ImageHandler';
import { Command } from 'lucide-react';

interface SearchResultsProps {
  results: any[];
  loading: boolean;
  query: string;
  onClose: () => void;
  onSelectProduct: (product: any) => void;
  focusedIndex: number;
}

export const SearchResults: React.FC<SearchResultsProps> = ({
  results,
  loading,
  query,
  onClose,
  onSelectProduct,
  focusedIndex,
}) => {
  const navigate = useNavigate();

  const handleProductSelect = (product: any) => {
    onSelectProduct(product);
    onClose();
  };

  const handleCategoryClick = (e: React.MouseEvent, category: string, subcategory?: string) => {
    e.stopPropagation();

    // Navigate directly to the collection page with filters
    if (subcategory) {
      navigate(
        `/collection?category=${encodeURIComponent(category)}&subcategory=${encodeURIComponent(subcategory)}`
      );
    } else {
      navigate(`/collection?category=${encodeURIComponent(category)}`);
    }

    onClose();
  };

  if (!query) {
    return (
      <div className="py-14 px-6 text-center text-sm sm:px-14">
        <Command className="mx-auto h-6 w-6 text-gray-400" />
        <p className="mt-4 text-gray-900 font-semibold">Search the collection</p>
        <p className="mt-2 text-gray-500">
          Search by name, category, style, period, or description
        </p>
      </div>
    );
  }

  if (loading) {
    return <div className="p-6 text-center text-sm text-gray-500">Searching...</div>;
  }

  if (results.length === 0) {
    return (
      <div className="p-6 text-center">
        <p className="text-sm text-gray-500">No results found for "{query}"</p>
        <p className="mt-2 text-xs text-gray-400">
          Try searching for something else or browse our categories
        </p>
      </div>
    );
  }

  return (
    <div className="max-h-[60vh] overflow-y-auto overscroll-contain">
      <div className="flex flex-col divide-y divide-gray-100">
        {results.map((product, index) => (
          <div
            key={product.id}
            id={`result-${index}`}
            className={`flex items-center gap-4 p-4 hover:bg-gray-50 transition-colors w-full text-left ${
              focusedIndex === index ? 'bg-gray-50' : ''
            }`}
            role="option"
            aria-selected={focusedIndex === index}
            onClick={() => handleProductSelect(product)}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault();
                handleProductSelect(product);
              }
            }}
            tabIndex={-1}
          >
            <div className="w-16 h-16 bg-gray-100 rounded-lg overflow-hidden flex-shrink-0">
              <ImageHandler
                src={product.images?.[0] || ''}
                alt={product.name}
                className="w-full h-full object-cover"
              />
            </div>
            <div className="flex-1 min-w-0">
              <h3 className="text-sm font-medium text-gray-900 truncate">{product.name}</h3>
              <div className="mt-1 flex items-center gap-x-2 text-xs">
                <button
                  role="button"
                  tabIndex={0}
                  onClick={e => handleCategoryClick(e, product.category)}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      e.preventDefault();
                      handleCategoryClick(e, product.category);
                    }
                  }}
                  className="text-gray-500 hover:text-amber-800 transition-colors cursor-pointer"
                >
                  {product.category}
                </button>
                {product.subcategory && (
                  <>
                    <span className="text-gray-400">•</span>
                    <button
                      role="button"
                      tabIndex={0}
                      onClick={e => handleCategoryClick(e, product.category, product.subcategory)}
                      onKeyDown={e => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.preventDefault();
                          handleCategoryClick(e, product.category, product.subcategory);
                        }
                      }}
                      className="text-gray-500 hover:text-amber-800 transition-colors cursor-pointer"
                    >
                      {product.subcategory}
                    </button>
                  </>
                )}
                {product.sold_out && (
                  <span className="inline-flex items-center rounded-full bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">
                    Sold
                  </span>
                )}
              </div>
              <div className="mt-1 text-sm font-medium text-amber-600">
                ${product.price.toLocaleString()}
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
