import React, { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/Button';
import { Card } from '@/components/ui/Card';
import { Pencil, Trash2 } from 'lucide-react';
import { toast } from 'sonner';
import { CategoryForm } from './CategoryForm';

export const CategoryList: React.FC = () => {
  const [categories, setCategories] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingCategory, setEditingCategory] = useState<any | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [deleting, setDeleting] = useState<string | null>(null);

  const fetchCategories = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('blog_categories')
        .select('*, posts:blog_post_categories(count)')
        .order('name');

      if (fetchError) throw fetchError;
      setCategories(data || []);
    } catch (error: any) {
      console.error('Error fetching categories:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCategories();

    const subscription = supabase
      .channel('blog_categories_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'blog_categories',
        },
        () => {
          fetchCategories();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this category?')) return;

    setDeleting(id);
    const toastId = toast.loading('Deleting category...');

    try {
      const { error } = await supabase.from('blog_categories').delete().eq('id', id);

      if (error) throw error;

      setCategories(categories.filter(c => c.id !== id));
      toast.success('Category deleted successfully', { id: toastId });
    } catch (error: any) {
      console.error('Error deleting category:', error);
      toast.error('Failed to delete category', { id: toastId });
    } finally {
      setDeleting(null);
    }
  };

  if (loading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <Card className="p-4">
              <div className="h-6 bg-gray-200 w-1/4 rounded"></div>
            </Card>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 mb-4">{error}</p>
        <Button onClick={fetchCategories}>Retry</Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-medium">Categories</h2>
        <Button
          onClick={() => {
            setEditingCategory(null);
            setShowForm(true);
          }}
        >
          Add Category
        </Button>
      </div>

      {showForm && (
        <Card className="p-6">
          <CategoryForm
            initialData={editingCategory}
            onSuccess={() => {
              setShowForm(false);
              setEditingCategory(null);
            }}
            onCancel={() => {
              setShowForm(false);
              setEditingCategory(null);
            }}
          />
        </Card>
      )}

      <div className="space-y-4">
        {categories.map(category => (
          <Card key={category.id} className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-medium">{category.name}</h3>
                <p className="text-sm text-gray-500">{category.description}</p>
                <p className="text-xs text-gray-400 mt-1">
                  {category.posts[0].count} posts in this category
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setEditingCategory(category);
                    setShowForm(true);
                  }}
                  className="flex items-center gap-1"
                >
                  <Pencil size={14} />
                  Edit
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleDelete(category.id)}
                  disabled={deleting === category.id}
                  className="flex items-center gap-1 text-red-600 hover:text-red-700"
                >
                  <Trash2 size={14} />
                  {deleting === category.id ? 'Deleting...' : 'Delete'}
                </Button>
              </div>
            </div>
          </Card>
        ))}

        {categories.length === 0 && !showForm && (
          <div className="text-center py-12">
            <p className="text-gray-500 mb-4">No categories found</p>
            <Button onClick={() => setShowForm(true)}>Create Your First Category</Button>
          </div>
        )}
      </div>
    </div>
  );
};
