import { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { toast } from 'sonner';

export interface BlogCategory {
  id: string;
  name: string;
  slug: string;
  description?: string;
}

export interface BlogPost {
  id: string;
  title: string;
  slug: string;
  excerpt: string;
  content: string;
  featuredImage: string;
  date: string;
  publishedDate: string;
  author: string;
  authorAvatar?: string;
  authorTitle?: string;
  authorBio?: string;
  categories: BlogCategory[];
  relatedProducts?: any[];
}

export const useBlogPosts = () => {
  const [posts, setPosts] = useState<BlogPost[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000;

  useEffect(() => {
    const fetchPosts = async () => {
      try {
        if (retryCount >= MAX_RETRIES) {
          setLoading(false);
          setError('Failed to load blog posts after multiple attempts');
          toast.error('Failed to load blog posts. Please refresh the page.');
          return;
        }

        setLoading(true);
        setError(null);

        const { data, error: fetchError } = await supabase
          .from('blog_posts')
          .select(
            `
            *,
            categories:blog_post_categories(
              category:blog_categories(*)
            ),
            author:profiles!blog_posts_author_id_fkey(
              display_name,
              avatar_url,
              title,
              bio
            ),
            related_products:blog_post_products(
              product:products(*)
            )
          `
          )
          .eq('status', 'published')
          .order('published_at', { ascending: false });

        if (fetchError) throw fetchError;

        // Transform the data to match our BlogPost type
        const transformedPosts = (data || []).map(post => ({
          id: post.id,
          title: post.title,
          slug: post.slug,
          excerpt: post.excerpt,
          content: post.content,
          featuredImage: post.featured_image,
          date: new Date(post.published_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          publishedDate: post.published_at,
          author: post.author?.display_name || 'Gallery Staff',
          authorAvatar: post.author?.avatar_url,
          authorTitle: post.author?.title,
          authorBio: post.author?.bio,
          categories: post.categories?.map((cat: any) => cat.category) || [],
          relatedProducts: post.related_products?.map((rel: any) => rel.product) || [],
        }));

        setPosts(transformedPosts);
        setError(null);
        setRetryCount(0);
      } catch (err) {
        console.error('Error fetching blog posts:', err);
        setError('Failed to load blog posts');

        // Retry with exponential backoff
        const delay = RETRY_DELAY * Math.pow(2, retryCount);
        setRetryCount(prev => prev + 1);
        setTimeout(() => fetchPosts(), delay);
      } finally {
        setLoading(false);
      }
    };

    fetchPosts();

    // Set up real-time subscription
    const subscription = supabase
      .channel('blog_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'blog_posts',
        },
        () => {
          fetchPosts();
        }
      )
      .subscribe(status => {
        if (status === 'CHANNEL_ERROR') {
          console.warn('Supabase channel error, retrying subscription...');
          setTimeout(() => {
            subscription.unsubscribe();
            supabase
              .channel('blog_changes')
              .on(
                'postgres_changes',
                {
                  event: '*',
                  schema: 'public',
                  table: 'blog_posts',
                },
                () => {
                  fetchPosts();
                }
              )
              .subscribe();
          }, 5000);
        }
      });

    return () => {
      subscription.unsubscribe();
    };
  }, [retryCount]);

  // Get a single post by slug
  const getPostBySlug = (slug: string): BlogPost | null => {
    return posts.find(post => post.slug === slug) || null;
  };

  // Get related posts (excluding the current post)
  const getRelatedPosts = (currentPostId: string, limit: number = 3): BlogPost[] => {
    const currentPost = posts.find(post => post.id === currentPostId);
    if (!currentPost) return [];

    // Get posts with matching categories
    const relatedByCategory = posts.filter(
      post =>
        post.id !== currentPostId &&
        post.categories.some(cat =>
          currentPost.categories.some(currentCat => currentCat.id === cat.id)
        )
    );

    // If we have enough related by category, return those
    if (relatedByCategory.length >= limit) {
      return relatedByCategory.slice(0, limit);
    }

    // Otherwise, add other posts to fill the limit
    const otherPosts = posts.filter(
      post =>
        post.id !== currentPostId && !relatedByCategory.some(related => related.id === post.id)
    );

    return [...relatedByCategory, ...otherPosts].slice(0, limit);
  };

  return {
    posts,
    loading,
    error,
    getPostBySlug,
    getRelatedPosts,
  };
};
