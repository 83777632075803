import React, { useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useEvents } from './hooks/useEvents';
import { ImageHandler } from '../ui/ImageHandler';
import { Button } from '../ui/Button';
import { Breadcrumbs } from '../ui/Breadcrumbs';
import { Calendar, Clock, MapPin, Users, ArrowLeft, Share2, ExternalLink } from 'lucide-react';
import { formatEventDate, isEventPast } from './utils';
import { ShareButton } from '../ui/ShareButton';

export const EventDetail: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { getEventBySlug, getRelatedEvents, loading, error } = useEvents();
  const navigate = useNavigate();

  // Get event data
  const event = slug ? getEventBySlug(slug) : null;

  // Get related events
  const relatedEvents = event ? getRelatedEvents(event.id, 3) : [];

  // Scroll to top on mount
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [slug]);

  // Handle 404
  if (!loading && !error && !event) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center py-12">
          <h1 className="text-3xl font-medium mb-4">Event Not Found</h1>
          <p className="text-gray-600 mb-6">
            The event you're looking for doesn't exist or has been removed.
          </p>
          <Link to="/events">
            <Button>View All Events</Button>
          </Link>
        </div>
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="animate-pulse space-y-6">
          <div className="bg-gray-200 h-8 w-3/4 rounded mb-2"></div>
          <div className="bg-gray-200 h-64 rounded-lg mb-4"></div>
          <div className="bg-gray-200 h-4 rounded mb-2"></div>
          <div className="bg-gray-200 h-4 rounded mb-2"></div>
          <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center py-12">
          <p className="text-red-600 mb-4">{error}</p>
          <Button onClick={() => navigate(0)}>Retry</Button>
        </div>
      </div>
    );
  }

  if (!event) return null;

  const isPast = isEventPast(event.date);

  // Breadcrumb items
  const breadcrumbItems = [
    {
      label: 'Events',
      href: '/events',
    },
    {
      label: event.title,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{event.title} | Show Pony Gallery Events</title>
        <meta name="description" content={event.description} />
        <meta
          name="keywords"
          content={`${event.type}, gallery event, art exhibition, West Palm Beach, Show Pony Gallery`}
        />

        <meta property="og:title" content={`${event.title} | Show Pony Gallery Events`} />
        <meta property="og:description" content={event.description} />
        <meta property="og:type" content="event" />
        <meta property="og:url" content={`https://showponygallery.com/events/${event.slug}`} />
        <meta property="og:image" content={event.image} />

        <meta property="event:start_time" content={new Date(event.date).toISOString()} />
        {event.endDate && (
          <meta property="event:end_time" content={new Date(event.endDate).toISOString()} />
        )}
        <meta property="event:location:name" content={event.location || 'Show Pony Gallery'} />

        <link rel="canonical" href={`https://showponygallery.com/events/${event.slug}`} />

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Event',
            name: event.title,
            description: event.description,
            image: event.image,
            startDate: new Date(event.date).toISOString(),
            endDate: event.endDate ? new Date(event.endDate).toISOString() : undefined,
            eventStatus: isPast
              ? 'https://schema.org/EventScheduled'
              : 'https://schema.org/EventScheduled',
            eventAttendanceMode: 'https://schema.org/OfflineEventAttendanceMode',
            location: {
              '@type': 'Place',
              name: event.location || 'Show Pony Gallery',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '5011 S Dixie Hwy',
                addressLocality: 'West Palm Beach',
                addressRegion: 'FL',
                postalCode: '33405',
                addressCountry: 'US',
              },
            },
            organizer: {
              '@type': 'Organization',
              name: 'Show Pony Gallery',
              url: 'https://showponygallery.com',
            },
            offers: event.ticketLink
              ? {
                  '@type': 'Offer',
                  url: event.ticketLink,
                  price: event.price || '0',
                  priceCurrency: 'USD',
                  availability: 'https://schema.org/InStock',
                  validFrom: new Date(event.date).toISOString(),
                }
              : undefined,
          })}
        </script>
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <Breadcrumbs items={breadcrumbItems} className="mb-6" />

        <article>
          {/* Event Header */}
          <div className="mb-8">
            <div className="flex flex-wrap items-center gap-3 mb-4">
              <span className="inline-block bg-amber-100 text-amber-800 text-sm font-medium px-3 py-1 rounded">
                {event.type}
              </span>

              {isPast && (
                <span className="inline-block bg-gray-100 text-gray-800 text-sm font-medium px-3 py-1 rounded">
                  Past Event
                </span>
              )}

              {!isPast && event.featured && (
                <span className="inline-block bg-amber-600 text-white text-sm font-medium px-3 py-1 rounded">
                  Featured
                </span>
              )}
            </div>

            <h1 className="text-3xl md:text-4xl font-medium mb-4">{event.title}</h1>

            <div className="flex flex-wrap items-center gap-6 text-gray-600">
              <div className="flex items-center">
                <Calendar className="w-5 h-5 mr-2" />
                <span>{formatEventDate(event.date, event.endDate)}</span>
              </div>

              {event.time && (
                <div className="flex items-center">
                  <Clock className="w-5 h-5 mr-2" />
                  <span>{event.time}</span>
                </div>
              )}

              {event.location && (
                <div className="flex items-center">
                  <MapPin className="w-5 h-5 mr-2" />
                  <span>{event.location}</span>
                </div>
              )}

              <div className="ml-auto">
                <ShareButton
                  title={event.title}
                  description={event.description}
                  imageUrl={event.image}
                />
              </div>
            </div>
          </div>

          {/* Event Image */}
          <div className="mb-8 rounded-lg overflow-hidden">
            <ImageHandler
              src={event.image}
              alt={event.title}
              className="w-full h-auto object-cover"
              priority={true}
            />
          </div>

          {/* Event Details */}
          <div className="grid md:grid-cols-3 gap-8 mb-12">
            <div className="md:col-span-2">
              <h2 className="text-2xl font-medium mb-4">About This Event</h2>

              {event.longDescription ? (
                <div
                  className="prose prose-stone max-w-none"
                  dangerouslySetInnerHTML={{ __html: event.longDescription }}
                />
              ) : (
                <p className="text-gray-600">{event.description}</p>
              )}

              {event.artists && event.artists.length > 0 && (
                <div className="mt-8">
                  <h3 className="text-xl font-medium mb-4">Featured Artists</h3>
                  <ul className="space-y-4">
                    {event.artists.map((artist, index) => (
                      <li key={index} className="flex items-start">
                        <div className="w-12 h-12 rounded-full overflow-hidden mr-4 flex-shrink-0">
                          <img
                            src={
                              artist.image ||
                              'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?auto=format&fit=facearea&facepad=2&w=256&h=256&q=80'
                            }
                            alt={artist.name}
                            className="w-full h-full object-cover"
                          />
                        </div>
                        <div>
                          <h4 className="font-medium">{artist.name}</h4>
                          {artist.bio && <p className="text-sm text-gray-600">{artist.bio}</p>}
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </div>

            <div>
              <div className="bg-gray-50 rounded-lg p-6 sticky top-24">
                <h3 className="text-lg font-medium mb-4">Event Details</h3>

                <div className="space-y-4 mb-6">
                  <div>
                    <h4 className="text-sm font-medium text-gray-700">Date & Time</h4>
                    <p className="text-gray-600">
                      {formatEventDate(event.date, event.endDate)}
                      {event.time && (
                        <>
                          <br />
                          {event.time}
                        </>
                      )}
                    </p>
                  </div>

                  <div>
                    <h4 className="text-sm font-medium text-gray-700">Location</h4>
                    <p className="text-gray-600">{event.location || 'Show Pony Gallery'}</p>
                    <p className="text-gray-600">5011 S Dixie Hwy, West Palm Beach, FL 33405</p>
                  </div>

                  {event.capacity && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-700">Capacity</h4>
                      <div className="flex items-center">
                        <Users className="w-4 h-4 text-gray-500 mr-1" />
                        <span className="text-gray-600">{event.capacity} attendees</span>
                      </div>
                    </div>
                  )}

                  {event.price !== undefined && (
                    <div>
                      <h4 className="text-sm font-medium text-gray-700">Price</h4>
                      <p className="text-gray-600">
                        {event.price === 0 ? 'Free' : `$${event.price.toFixed(2)}`}
                      </p>
                    </div>
                  )}
                </div>

                {!isPast && event.ticketLink && (
                  <a
                    href={event.ticketLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="w-full bg-amber-800 hover:bg-amber-900 text-white py-2 px-4 rounded-md transition-colors flex items-center justify-center gap-2"
                  >
                    {event.price === 0 ? 'Register Now' : 'Get Tickets'}
                    <ExternalLink className="w-4 h-4" />
                  </a>
                )}

                {!isPast && !event.ticketLink && (
                  <a
                    href="mailto:Showponygallerypb@gmail.com?subject=Event Inquiry"
                    className="w-full bg-amber-800 hover:bg-amber-900 text-white py-2 px-4 rounded-md transition-colors flex items-center justify-center gap-2"
                  >
                    Contact for Details
                    <ExternalLink className="w-4 h-4" />
                  </a>
                )}

                {isPast && (
                  <div className="text-center text-gray-600 p-2 border border-gray-200 rounded-md">
                    This event has ended
                  </div>
                )}
              </div>
            </div>
          </div>

          {/* Related Events */}
          {relatedEvents.length > 0 && (
            <div className="border-t pt-12">
              <h2 className="text-2xl font-medium mb-6">Related Events</h2>
              <div className="grid md:grid-cols-3 gap-6">
                {relatedEvents.map(relatedEvent => (
                  <div
                    key={relatedEvent.slug}
                    className="bg-white rounded-lg shadow-sm overflow-hidden"
                  >
                    <Link
                      to={`/events/${relatedEvent.slug}`}
                      className="block aspect-[16/9] overflow-hidden"
                    >
                      <ImageHandler
                        src={relatedEvent.image}
                        alt={relatedEvent.title}
                        className="w-full h-full object-cover transition-transform hover:scale-105 duration-300"
                      />
                    </Link>
                    <div className="p-4">
                      <div className="mb-2">
                        <span className="inline-block bg-amber-100 text-amber-800 text-xs font-medium px-2 py-0.5 rounded">
                          {relatedEvent.type}
                        </span>
                      </div>
                      <h3 className="font-medium mb-2">
                        <Link
                          to={`/events/${relatedEvent.slug}`}
                          className="hover:text-amber-800 transition-colors"
                        >
                          {relatedEvent.title}
                        </Link>
                      </h3>
                      <p className="text-sm text-gray-500 mb-2">
                        {formatEventDate(relatedEvent.date, relatedEvent.endDate)}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Navigation */}
          <div className="flex justify-between mt-12 pt-6 border-t">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => navigate('/events')}
              className="flex items-center gap-2"
            >
              <ArrowLeft className="w-4 h-4" />
              Back to Events
            </Button>
          </div>
        </article>
      </div>
    </>
  );
};
