import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { BlogForm } from './BlogForm';
import { Button } from '@/components/ui/Button';
import { toast } from 'sonner';

export const EditBlogPost: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [post, setPost] = useState<any | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchPost = async () => {
      if (!id) {
        navigate('/admin/blog');
        return;
      }

      try {
        setLoading(true);
        setError(null);

        const { data, error: fetchError } = await supabase
          .from('blog_posts')
          .select(
            `
            *,
            categories:blog_post_categories(
              category:blog_categories(*)
            ),
            tags:blog_post_tags(
              tag:blog_tags(*)
            )
          `
          )
          .eq('id', id)
          .single();

        if (fetchError) throw fetchError;
        if (!data) throw new Error('Post not found');

        setPost(data);
      } catch (err: any) {
        console.error('Error fetching post:', err);
        setError(err.message);
        toast.error('Failed to load post');
      } finally {
        setLoading(false);
      }
    };

    fetchPost();
  }, [id, navigate]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-stone-800"></div>
      </div>
    );
  }

  if (error || !post) {
    return (
      <div className="text-center py-8">
        <p className="text-red-600 mb-4">{error || 'Post not found'}</p>
        <Link to="/admin/blog">
          <Button variant="secondary">Back to Blog Posts</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-serif">Edit Blog Post</h1>
        <Link to="/admin/blog">
          <Button variant="secondary">Back to Blog Posts</Button>
        </Link>
      </div>
      <BlogForm
        initialData={post}
        onSuccess={() => {
          toast.success('Post updated successfully');
          navigate('/admin/blog');
        }}
      />
    </div>
  );
};
