import { Category } from './types';

export const categories: Category[] = [
  {
    name: 'Drawings',
    subcategories: ['Charcoal', 'Graphite', 'Ink', 'Pastel', 'Pencil'],
  },
  {
    name: 'Furniture',
  },
  {
    name: 'Mixed Media',
    subcategories: ['Collage', 'Digital', 'Installation', 'Textile'],
  },
  {
    name: 'Paintings',
    subcategories: ['Acrylic', 'Oil', 'Watercolor'],
  },
  {
    name: 'Photography',
    subcategories: ['Black & White', 'Color', 'Digital', 'Film'],
  },
  {
    name: 'Prints',
    subcategories: [
      'Digital',
      'Ephemera & Advertisements',
      'Etching',
      'Letterpress',
      'Linocut',
      'Lithograph',
      'Original Prints',
      'Posters',
      'Reproduction Prints',
      'Screen Print',
      'Woodcut',
    ],
  },
  {
    name: 'Sculpture',
    subcategories: ['Bronze', 'Ceramic', 'Glass', 'Metal', 'Mixed Media', 'Stone', 'Wood'],
  },
];
