import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Search as SearchIcon, X, Loader2, Command, ArrowRight } from 'lucide-react';
import { debounce } from '@/lib/utils';
import { supabase } from '@/lib/supabase';
import { SearchResults } from './SearchResults';
import { useNavigate } from 'react-router-dom';

export const Search = () => {
  const [query, setQuery] = useState('');
  const [results, setResults] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [focusedIndex, setFocusedIndex] = useState(-1);
  const searchRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const resultsRef = useRef<HTMLDivElement>(null);
  const navigate = useNavigate();

  // Handle clicks outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setShowResults(false);
        if (!query) {
          setIsExpanded(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [query]);

  // Debounced search function
  const searchProducts = useCallback(
    debounce(async (searchQuery: string) => {
      if (!searchQuery.trim()) {
        setResults([]);
        setLoading(false);
        return;
      }

      try {
        // Split search query into terms and filter out empty strings
        const searchTerms = searchQuery.toLowerCase().split(/\s+/).filter(Boolean);

        // Create an array of filter conditions for each term
        const conditions = searchTerms.map(term => {
          const pattern = `%${term}%`;
          return `or(name.ilike.${pattern},category.ilike.${pattern},subcategory.ilike.${pattern},style.ilike.${pattern},period.ilike.${pattern},description.ilike.${pattern})`;
        });

        // Combine all conditions with AND
        const filterString = conditions.join(',');

        const { data, error: fetchError } = await supabase
          .from('products')
          .select('*')
          .eq('status', 'published')
          .or(filterString)
          .order('created_at', { ascending: false })
          .limit(8);

        if (fetchError) throw fetchError;

        // Calculate relevance scores
        const scoredResults = data.map(product => {
          let score = 0;
          const searchableText = [
            product.name,
            product.description,
            product.category,
            product.subcategory,
            product.style,
            product.period,
          ]
            .filter(Boolean)
            .join(' ')
            .toLowerCase();

          searchTerms.forEach(term => {
            // Exact matches in name
            if (product.name.toLowerCase().includes(term)) score += 10;
            // Exact matches in category/subcategory
            if (product.category.toLowerCase() === term) score += 8;
            if (product.subcategory?.toLowerCase() === term) score += 8;
            // Exact matches in style/period
            if (product.style?.toLowerCase() === term) score += 6;
            if (product.period?.toLowerCase() === term) score += 6;
            // Partial matches
            if (searchableText.includes(term)) score += 2;
          });

          // Boost score for products with images
          if (product.images?.length > 0) score += 1;
          // Boost score for available products
          if (!product.sold_out) score += 2;

          return { ...product, relevanceScore: score };
        });

        // Sort by relevance score and filter out low-scoring results
        const sortedResults = scoredResults
          .sort((a, b) => b.relevanceScore - a.relevanceScore)
          .filter(product => product.relevanceScore > 0);

        setResults(sortedResults);
        setFocusedIndex(-1);
      } catch (error) {
        console.error('Search error:', error);
        setResults([]);
      } finally {
        setLoading(false);
      }
    }, 300),
    []
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newQuery = e.target.value;
    setQuery(newQuery);
    setLoading(true);
    setShowResults(true);
    searchProducts(newQuery);
  };

  const handleClear = () => {
    setQuery('');
    setResults([]);
    setShowResults(false);
    setIsExpanded(false);
    setFocusedIndex(-1);
  };

  const handleSelectProduct = (product: any) => {
    window.dispatchEvent(
      new CustomEvent('filterProducts', {
        detail: { productId: product.id },
      })
    );

    window.dispatchEvent(
      new CustomEvent('openProductCard', {
        detail: { product },
      })
    );

    setShowResults(false);
    setQuery('');
  };

  const handleSearchClick = () => {
    setIsExpanded(true);
    setTimeout(() => {
      inputRef.current?.focus();
    }, 100);
  };

  // Handle keyboard navigation
  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (!showResults) return;

    // Arrow down
    if (e.key === 'ArrowDown') {
      e.preventDefault();
      setFocusedIndex(prev => (prev < results.length - 1 ? prev + 1 : prev));

      // Scroll into view if needed
      if (resultsRef.current && focusedIndex >= 0) {
        const items = resultsRef.current.querySelectorAll('[role="option"]');
        if (items[focusedIndex + 1]) {
          items[focusedIndex + 1].scrollIntoView({ block: 'nearest' });
        }
      }
    }

    // Arrow up
    else if (e.key === 'ArrowUp') {
      e.preventDefault();
      setFocusedIndex(prev => (prev > 0 ? prev - 1 : 0));

      // Scroll into view if needed
      if (resultsRef.current && focusedIndex > 0) {
        const items = resultsRef.current.querySelectorAll('[role="option"]');
        if (items[focusedIndex - 1]) {
          items[focusedIndex - 1].scrollIntoView({ block: 'nearest' });
        }
      }
    }

    // Enter to select
    else if (e.key === 'Enter') {
      if (focusedIndex >= 0 && focusedIndex < results.length) {
        handleSelectProduct(results[focusedIndex]);
      } else if (query && results.length === 0) {
        navigate(`/collection?search=${encodeURIComponent(query)}`);
        setShowResults(false);
      }
    }

    // Escape to close
    else if (e.key === 'Escape') {
      setShowResults(false);
      if (!query) {
        setIsExpanded(false);
      }
      inputRef.current?.blur();
    }
  };

  // Handle "View all results" action
  const handleViewAllResults = () => {
    if (query) {
      navigate(`/collection?search=${encodeURIComponent(query)}`);
      setShowResults(false);
      setQuery('');
    }
  };

  return (
    <div ref={searchRef} className="relative" style={{ zIndex: 1000 }}>
      <div
        className={`relative flex items-center transition-all duration-200 ${isExpanded ? 'w-64' : 'w-8'}`}
      >
        {isExpanded ? (
          <>
            <input
              ref={inputRef}
              type="text"
              className="w-full pl-10 pr-10 py-2 text-sm rounded-full border border-gray-200 
                       bg-gray-50/80 backdrop-blur-sm focus:bg-white
                       focus:outline-none focus:ring-2 focus:ring-amber-500/20 focus:border-amber-500/30
                       placeholder:text-gray-400"
              placeholder="Search artwork..."
              value={query}
              onChange={handleSearch}
              onFocus={() => setShowResults(true)}
              onKeyDown={handleKeyDown}
              aria-expanded={showResults}
              aria-autocomplete="list"
              aria-controls={showResults ? 'search-results' : undefined}
              aria-activedescendant={focusedIndex >= 0 ? `result-${focusedIndex}` : undefined}
              role="combobox"
            />
            <SearchIcon className="absolute left-3 top-1/2 -translate-y-1/2 h-4 w-4 text-gray-400" />
            <div className="absolute right-2 top-1/2 -translate-y-1/2 flex items-center gap-2">
              {loading && <Loader2 className="h-4 w-4 text-amber-600 animate-spin" />}
              {query && (
                <button
                  onClick={handleClear}
                  className="text-gray-400 hover:text-gray-600 p-1 rounded-full"
                  aria-label="Clear search"
                >
                  <X className="h-4 w-4" />
                </button>
              )}
            </div>
          </>
        ) : (
          <button
            onClick={handleSearchClick}
            className="p-2 text-gray-700 hover:text-amber-800 transition-colors"
            aria-label="Search"
          >
            <SearchIcon className="h-4 w-4" />
          </button>
        )}
      </div>

      {showResults && (query || results.length > 0) && (
        <>
          {/* Overlay backdrop */}
          <div
            className="fixed inset-0 bg-black/20 backdrop-blur-[1px]"
            style={{ zIndex: 998 }}
            onClick={() => setShowResults(false)}
            aria-hidden="true"
          />

          {/* Search results */}
          <div
            id="search-results"
            ref={resultsRef}
            className="absolute top-full left-0 right-0 mt-2 bg-white rounded-lg shadow-xl border border-gray-100 overflow-hidden"
            style={{ zIndex: 999 }}
            role="listbox"
          >
            <SearchResults
              results={results}
              loading={loading}
              query={query}
              onClose={() => setShowResults(false)}
              onSelectProduct={handleSelectProduct}
              focusedIndex={focusedIndex}
            />

            {/* View all results button */}
            {query && (
              <div className="p-2 border-t border-gray-100">
                <button
                  onClick={handleViewAllResults}
                  className="w-full text-left px-3 py-2 text-sm text-amber-600 hover:text-amber-800 flex items-center justify-between rounded-md hover:bg-gray-50"
                >
                  <span>View all results</span>
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};
