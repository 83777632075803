import React from 'react';
import { ART_STYLES, FURNITURE_STYLES } from '@/lib/constants';

interface StyleFieldProps {
  initialStyle?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
  category?: string;
}

export const StyleField: React.FC<StyleFieldProps> = ({
  initialStyle,
  value,
  onChange,
  error,
  category,
}) => {
  // Reset style when category changes
  React.useEffect(() => {
    if (value && category === 'Furniture' && !FURNITURE_STYLES.includes(value as any)) {
      onChange('');
    } else if (value && category !== 'Furniture' && !ART_STYLES.includes(value as any)) {
      onChange('');
    }
  }, [category, value, onChange]);

  // Determine which styles to show based on category
  const styles = category === 'Furniture' ? FURNITURE_STYLES : ART_STYLES;

  return (
    <div>
      <label htmlFor="style" className="block text-base font-medium text-gray-700 mb-2">
        Style *
      </label>
      <div className="relative">
        <select
          id="style"
          value={value || ''}
          onChange={e => onChange(e.target.value)}
          className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4 ${
            error ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
          }`}
          required
        >
          <option value="">Select a style</option>
          {styles.map(style => (
            <option key={style} value={style}>
              {style}
            </option>
          ))}
        </select>
        <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
          <svg
            className="h-4 w-4 text-gray-400"
            fill="none"
            stroke="currentColor"
            viewBox="0 0 24 24"
          >
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
          </svg>
        </div>
      </div>
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
      {category === 'Furniture' && (
        <p className="mt-2 text-sm text-gray-500">
          Select the furniture style that best matches this piece
        </p>
      )}
    </div>
  );
};
