import React, { useState, useEffect } from 'react';
import { supabase } from '@/lib/supabase';
import { Button } from '@/components/ui/Button';
import { Card } from '@/components/ui/Card';
import { Pencil, Trash2 } from 'lucide-react';
import { toast } from 'sonner';
import { TagForm } from './TagForm';

export const TagList: React.FC = () => {
  const [tags, setTags] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [editingTag, setEditingTag] = useState<any | null>(null);
  const [showForm, setShowForm] = useState(false);
  const [deleting, setDeleting] = useState<string | null>(null);

  const fetchTags = async () => {
    try {
      setLoading(true);
      const { data, error: fetchError } = await supabase
        .from('blog_tags')
        .select('*, posts:blog_post_tags(count)')
        .order('name');

      if (fetchError) throw fetchError;
      setTags(data || []);
    } catch (error: any) {
      console.error('Error fetching tags:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTags();

    const subscription = supabase
      .channel('blog_tags_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'blog_tags',
        },
        () => {
          fetchTags();
        }
      )
      .subscribe();

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this tag?')) return;

    setDeleting(id);
    const toastId = toast.loading('Deleting tag...');

    try {
      const { error } = await supabase.from('blog_tags').delete().eq('id', id);

      if (error) throw error;

      setTags(tags.filter(t => t.id !== id));
      toast.success('Tag deleted successfully', { id: toastId });
    } catch (error: any) {
      console.error('Error deleting tag:', error);
      toast.error('Failed to delete tag', { id: toastId });
    } finally {
      setDeleting(null);
    }
  };

  if (loading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <Card className="p-4">
              <div className="h-6 bg-gray-200 w-1/4 rounded"></div>
            </Card>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 mb-4">{error}</p>
        <Button onClick={fetchTags}>Retry</Button>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h2 className="text-xl font-medium">Tags</h2>
        <Button
          onClick={() => {
            setEditingTag(null);
            setShowForm(true);
          }}
        >
          Add Tag
        </Button>
      </div>

      {showForm && (
        <Card className="p-6">
          <TagForm
            initialData={editingTag}
            onSuccess={() => {
              setShowForm(false);
              setEditingTag(null);
            }}
            onCancel={() => {
              setShowForm(false);
              setEditingTag(null);
            }}
          />
        </Card>
      )}

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {tags.map(tag => (
          <Card key={tag.id} className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <h3 className="font-medium">{tag.name}</h3>
                <p className="text-xs text-gray-400 mt-1">
                  {tag.posts[0].count} posts with this tag
                </p>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    setEditingTag(tag);
                    setShowForm(true);
                  }}
                  className="flex items-center gap-1"
                >
                  <Pencil size={14} />
                  Edit
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleDelete(tag.id)}
                  disabled={deleting === tag.id}
                  className="flex items-center gap-1 text-red-600 hover:text-red-700"
                >
                  <Trash2 size={14} />
                  {deleting === tag.id ? 'Deleting...' : 'Delete'}
                </Button>
              </div>
            </div>
          </Card>
        ))}

        {tags.length === 0 && !showForm && (
          <div className="text-center py-12 col-span-2">
            <p className="text-gray-500 mb-4">No tags found</p>
            <Button onClick={() => setShowForm(true)}>Create Your First Tag</Button>
          </div>
        )}
      </div>
    </div>
  );
};
