import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { EventForm } from './EventForm';
import { useEvents } from '@/components/Events/hooks/useEvents';
import { Button } from '@/components/ui/Button';
import { toast } from 'sonner';

export const EditEvent: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const { getEventBySlug, loading, error } = useEvents();
  const [event, setEvent] = useState<any>(null);

  useEffect(() => {
    if (!id) {
      navigate('/admin/events');
      return;
    }

    const fetchEvent = async () => {
      try {
        const event = await getEventBySlug(id);
        if (!event) {
          throw new Error('Event not found');
        }
        setEvent(event);
      } catch (err: any) {
        console.error('Error fetching event:', err);
        toast.error('Failed to load event');
      }
    };

    fetchEvent();
  }, [id, navigate, getEventBySlug]);

  if (loading) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-stone-800"></div>
      </div>
    );
  }

  if (error || !event) {
    return (
      <div className="text-center py-8">
        <p className="text-red-600 mb-4">{error || 'Event not found'}</p>
        <Link to="/admin/events">
          <Button variant="secondary">Back to Events</Button>
        </Link>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-serif">Edit Event</h1>
        <Link to="/admin/events">
          <Button variant="secondary">Back to Events</Button>
        </Link>
      </div>
      <EventForm
        initialData={event}
        onSuccess={() => {
          toast.success('Event updated successfully');
          navigate('/admin/events');
        }}
      />
    </div>
  );
};
