import React from 'react';
import { ART_PERIODS } from '@/lib/constants';

interface PeriodFieldProps {
  initialPeriod?: string;
  value: string;
  onChange: (value: string) => void;
  error?: string;
}

export const PeriodField: React.FC<PeriodFieldProps> = ({
  initialPeriod,
  value,
  onChange,
  error,
}) => {
  return (
    <div>
      <label htmlFor="period" className="block text-base font-medium text-gray-700 mb-2">
        Period *
      </label>
      <select
        id="period"
        value={value || ''}
        onChange={e => onChange(e.target.value)}
        className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4 ${
          error ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
        }`}
        required
      >
        <option value="">Select a period</option>
        {ART_PERIODS.map(period => (
          <option key={period} value={period}>
            {period}
          </option>
        ))}
      </select>
      {error && <p className="mt-1 text-sm text-red-600">{error}</p>}
    </div>
  );
};
