import { supabase } from './supabase';

const CLOUDINARY_CLOUD_NAME = import.meta.env.VITE_CLOUDINARY_CLOUD_NAME;
const CLOUDINARY_UPLOAD_PRESET = import.meta.env.VITE_CLOUDINARY_UPLOAD_PRESET;
const MAX_RETRIES = 3;
const BATCH_SIZE = 1; // Process one image at a time
const BATCH_DELAY = 2000; // 2 seconds between batches
const ERROR_DELAY = 5000; // 5 seconds after error
const MAX_CONCURRENT_UPLOADS = 1;

const wait = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const fetchWithTimeout = async (url: string, options: RequestInit = {}, timeout = 30000) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timeout);

  try {
    const response = await fetch(url, {
      ...options,
      signal: controller.signal,
    });
    clearTimeout(id);
    return response;
  } catch (error) {
    clearTimeout(id);
    throw error;
  }
};

const uploadToCloudinary = async (imageUrl: string, retryCount = 0): Promise<string> => {
  try {
    // Skip if already a Cloudinary URL
    if (imageUrl.includes('res.cloudinary.com')) {
      return imageUrl;
    }

    // Add cache-busting parameter
    const timestamp = Date.now();
    const urlWithCache = imageUrl.includes('?')
      ? `${imageUrl}&t=${timestamp}`
      : `${imageUrl}?t=${timestamp}`;

    // First fetch the image
    const imageResponse = await fetchWithTimeout(urlWithCache, {
      headers: {
        'Cache-Control': 'no-cache',
        Pragma: 'no-cache',
      },
    });

    if (!imageResponse.ok) {
      throw new Error(`Failed to fetch image: ${imageResponse.status}`);
    }

    const blob = await imageResponse.blob();
    if (blob.size === 0) {
      throw new Error('Empty image blob');
    }

    // Create form data
    const formData = new FormData();
    formData.append('file', blob);
    formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
    formData.append('folder', 'products');

    // Upload to Cloudinary
    const uploadResponse = await fetchWithTimeout(
      `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUD_NAME}/image/upload`,
      {
        method: 'POST',
        body: formData,
      },
      60000 // 60 second timeout for upload
    );

    if (!uploadResponse.ok) {
      const error = await uploadResponse.json();
      throw new Error(`Cloudinary upload failed: ${error.message || uploadResponse.status}`);
    }

    const data = await uploadResponse.json();
    return data.secure_url;
  } catch (error: any) {
    console.error(`Error uploading image (attempt ${retryCount + 1}):`, error);

    if (retryCount < MAX_RETRIES) {
      const delay = ERROR_DELAY * Math.pow(2, retryCount);
      await wait(delay);
      return uploadToCloudinary(imageUrl, retryCount + 1);
    }
    throw error;
  }
};

export const migrateImagesToCloudinary = async (
  onProgress?: (current: number, total: number, error?: string) => void
) => {
  try {
    // Get all products with images that aren't already on Cloudinary
    const { data: products, error: fetchError } = await supabase
      .from('products')
      .select('id, images')
      .not('images', 'eq', '[]')
      .not('images', 'is', null)
      .order('created_at', { ascending: true });

    if (fetchError) throw fetchError;
    if (!products?.length) return { migrated: 0, errors: 0 };

    let migrated = 0;
    let errors = 0;
    const total = products.reduce((acc, p) => acc + (p.images?.length || 0), 0);

    // Process one product at a time
    for (const product of products) {
      if (!product.images?.length) continue;

      const newImages = [];
      let productUpdated = false;

      // Process each image sequentially
      for (const imageUrl of product.images) {
        try {
          // Skip if already a Cloudinary URL
          if (imageUrl.includes('res.cloudinary.com')) {
            newImages.push(imageUrl);
            migrated++;
            onProgress?.(migrated, total);
            continue;
          }

          // Skip invalid URLs
          if (!imageUrl || !imageUrl.startsWith('http')) {
            throw new Error('Invalid image URL');
          }

          // Upload to Cloudinary
          const cloudinaryUrl = await uploadToCloudinary(imageUrl);
          newImages.push(cloudinaryUrl);
          migrated++;
          productUpdated = true;
          onProgress?.(migrated, total);

          // Add delay between images
          await wait(BATCH_DELAY);
        } catch (error: any) {
          console.error(`Failed to migrate image: ${imageUrl}`, error);
          newImages.push(imageUrl); // Keep original URL on error
          errors++;
          onProgress?.(migrated, total, `Failed to migrate image: ${error.message}`);
          await wait(ERROR_DELAY);
        }
      }

      // Update product if any images were migrated
      if (productUpdated) {
        const { error: updateError } = await supabase
          .from('products')
          .update({ images: newImages })
          .eq('id', product.id);

        if (updateError) {
          console.error(`Failed to update product ${product.id}:`, updateError);
          errors++;
          onProgress?.(migrated, total, `Failed to update product ${product.id}`);
        }
      }

      // Add delay between products
      await wait(BATCH_DELAY);
    }

    return { migrated, errors };
  } catch (error: any) {
    console.error('Migration error:', error);
    throw new Error(`Migration failed: ${error.message}`);
  }
};

export const cleanupSupabaseStorage = async (
  onProgress?: (current: number, total: number) => void
) => {
  try {
    const { data: objects, error: listError } = await supabase.storage
      .from('images')
      .list('products');

    if (listError) throw listError;
    if (!objects?.length) return { deleted: 0, total: 0 };

    const total = objects.length;
    let deleted = 0;

    // Delete files one at a time
    for (const obj of objects) {
      try {
        const { error: deleteError } = await supabase.storage
          .from('images')
          .remove([`products/${obj.name}`]);

        if (deleteError) {
          console.error(`Failed to delete file ${obj.name}:`, deleteError);
        } else {
          deleted++;
          onProgress?.(deleted, total);
        }
      } catch (error) {
        console.error(`Error deleting file ${obj.name}:`, error);
      }

      // Add delay between deletions
      await wait(BATCH_DELAY);
    }

    return { deleted, total };
  } catch (error: any) {
    console.error('Cleanup error:', error);
    throw new Error(`Cleanup failed: ${error.message}`);
  }
};
