import React from 'react';
import { Control, useController } from 'react-hook-form';
import { Globe } from 'lucide-react';

interface WebsiteSelectorProps {
  control: Control<any>;
}

export const WebsiteSelector: React.FC<WebsiteSelectorProps> = ({ control }) => {
  const { field } = useController({
    name: 'websites',
    control,
    defaultValue: ['showponygallery.com'],
  });

  return (
    <div>
      <label className="block text-base font-medium text-gray-700 mb-2">Display Product On</label>
      <div className="space-y-3">
        <div className="flex items-center">
          <div className="flex items-center flex-1">
            <input
              type="checkbox"
              checked={true}
              disabled={true}
              className="h-4 w-4 text-amber-600 focus:ring-amber-500 border-gray-300 rounded cursor-not-allowed"
            />
            <label className="ml-2 text-gray-700 flex items-center gap-2">
              <Globe className="w-4 h-4 text-amber-600" />
              showponygallery.com
              <span className="text-xs text-amber-600 font-medium bg-amber-50 px-2 py-0.5 rounded">
                Required
              </span>
            </label>
          </div>
        </div>
      </div>
      <p className="mt-2 text-sm text-gray-500">Products are only displayed on Show Pony Gallery</p>
    </div>
  );
};
