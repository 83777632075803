import React from 'react';
import { Instagram, ExternalLink, MapPin } from 'lucide-react';

const ContactInfo = () => {
  const address = '5011 S Dixie Hwy, West Palm Beach, FL 33405';
  const encodedAddress = encodeURIComponent(address);
  const mapsUrl = `https://www.google.com/maps/dir/?api=1&destination=${encodedAddress}`;

  return (
    <section className="py-8 md:py-12 bg-[#1a1f2e] text-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid md:grid-cols-3 gap-8 md:gap-12">
          {/* Gallery Info */}
          <div>
            <h2 className="text-xl md:text-2xl mb-3 md:mb-4">Show Pony Gallery</h2>
            <a
              href={mapsUrl}
              target="_blank"
              rel="noopener noreferrer"
              className="not-italic block hover:text-gray-300 transition-colors group"
              aria-label="Get directions to our gallery"
            >
              <div className="flex items-start gap-2">
                <MapPin className="w-5 h-5 mt-1 flex-shrink-0 text-gray-400 group-hover:text-gray-300" />
                <address className="not-italic">
                  <p>5011 S Dixie Hwy</p>
                  <p>West Palm Beach, FL 33405</p>
                </address>
              </div>
            </a>
            <a
              href="tel:+19174559307"
              className="block mt-2 hover:text-gray-300 transition-colors"
              aria-label="Call our gallery"
            >
              (917) 455-9307
            </a>
            <a
              href="mailto:Showponygallerypb@gmail.com"
              className="block mt-2 hover:text-gray-300 transition-colors"
              aria-label="Email our gallery"
            >
              Showponygallerypb@gmail.com
            </a>
          </div>

          {/* Hours */}
          <div>
            <h2 className="text-xl md:text-2xl mb-3 md:mb-4">Visit Us</h2>
            <div className="space-y-2">
              <div>
                <p className="font-medium">Monday - Saturday</p>
                <p>11:00 AM - 5:00 PM</p>
              </div>
              <div>
                <p className="font-medium">Sunday</p>
                <p>Closed</p>
              </div>
            </div>
          </div>

          {/* Social Links */}
          <div>
            <h2 className="text-xl md:text-2xl mb-3 md:mb-4">Follow Us</h2>
            <div className="flex flex-col gap-4">
              <a
                href="https://www.instagram.com/showponygallerypb/"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 hover:text-gray-300 transition-colors"
                aria-label="Follow us on Instagram"
              >
                <Instagram className="w-5 h-5" />
                <span>Instagram</span>
              </a>
              <a
                href="https://www.chairish.com/shop/showponygallery"
                target="_blank"
                rel="noopener noreferrer"
                className="flex items-center gap-2 hover:text-gray-300 transition-colors"
                aria-label="Shop on Chairish"
              >
                <ExternalLink className="w-5 h-5" />
                <span>Shop on Chairish</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactInfo;
