import React, { useState, useEffect, useRef } from 'react';
import { ImageIcon } from 'lucide-react';
import { validateImageUrl, getOptimizedImageUrl } from '@/lib/imageUtils';

interface ImageHandlerProps {
  src: string;
  alt: string;
  className?: string;
  fallbackClassName?: string;
  priority?: boolean;
  onError?: () => void;
  itemProp?: string;
}

export const ImageHandler: React.FC<ImageHandlerProps> = ({
  src,
  alt,
  className = '',
  fallbackClassName = '',
  priority = false,
  onError,
  itemProp,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [error, setError] = useState(false);
  const [currentSrc, setCurrentSrc] = useState('');
  const [retryCount, setRetryCount] = useState(0);
  const mountedRef = useRef(true);
  const imageRef = useRef<HTMLImageElement>(null);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000;

  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (!src || !validateImageUrl(src)) {
      setError(true);
      return;
    }

    setLoaded(false);
    setError(false);
    setRetryCount(0);

    // Get optimized URL based on priority
    const optimizedSrc = getOptimizedImageUrl(src, priority ? 1200 : 800);
    setCurrentSrc(optimizedSrc);
  }, [src, priority]);

  const handleError = async () => {
    if (!mountedRef.current) return;

    if (retryCount >= MAX_RETRIES) {
      setError(true);
      setLoaded(false);
      onError?.();
      return;
    }

    try {
      await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * (retryCount + 1)));

      if (!mountedRef.current) return;

      // Add cache-busting parameter
      const timestamp = Date.now();
      const cleanSrc = src.split('?')[0];
      const newSrc = `${cleanSrc}?retry=${retryCount + 1}&t=${timestamp}`;
      setCurrentSrc(newSrc);
      setRetryCount(prev => prev + 1);
    } catch (error) {
      console.error('Error retrying image load:', error);
      setError(true);
      setLoaded(false);
      onError?.();
    }
  };

  const handleLoad = () => {
    if (!mountedRef.current) return;
    setLoaded(true);
    setError(false);
  };

  // Implement Intersection Observer for lazy loading
  useEffect(() => {
    if (!priority && imageRef.current) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              const img = entry.target as HTMLImageElement;
              img.src = currentSrc;
              observer.unobserve(img);
            }
          });
        },
        {
          rootMargin: '50px',
          threshold: 0.1,
        }
      );

      observer.observe(imageRef.current);

      return () => {
        observer.disconnect();
      };
    }
  }, [currentSrc, priority]);

  return (
    <div className={`relative ${className}`}>
      {(!loaded || error) && (
        <div
          className={`absolute inset-0 bg-gray-100 flex items-center justify-center ${fallbackClassName}`}
        >
          <ImageIcon className="w-8 h-8 text-gray-400" />
        </div>
      )}
      {!error && (
        <img
          ref={imageRef}
          src={priority ? currentSrc : undefined}
          data-src={!priority ? currentSrc : undefined}
          alt={alt}
          className={`w-full h-full object-contain transition-opacity duration-300 ${
            loaded ? 'opacity-100' : 'opacity-0'
          }`}
          onError={handleError}
          onLoad={handleLoad}
          loading={priority ? 'eager' : 'lazy'}
          fetchpriority={priority ? 'high' : 'auto'}
          decoding={priority ? 'sync' : 'async'}
          crossOrigin="anonymous"
          itemProp={itemProp}
        />
      )}
    </div>
  );
};
