import React from 'react';
import { Button } from '@/components/ui/Button';

interface FormSubmitButtonProps {
  loading: boolean;
  isUpdate?: boolean;
}

export const FormSubmitButton: React.FC<FormSubmitButtonProps> = ({
  loading,
  isUpdate = false,
}) => {
  return (
    <Button type="submit" disabled={loading} className="w-full md:w-auto">
      {loading ? 'Saving...' : isUpdate ? 'Update Product' : 'Add Product'}
    </Button>
  );
};
