import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useBlogPosts } from './hooks/useBlogPosts';
import { ImageHandler } from '../ui/ImageHandler';
import { Button } from '../ui/Button';
import { Calendar, User, Search } from 'lucide-react';

export const BlogList: React.FC = () => {
  const { posts, loading, error } = useBlogPosts();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState('');

  // Extract search query from URL
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const query = params.get('search');
    setSearchQuery(query || '');
  }, [location.search]);

  // Filter posts by search query if present
  const filteredPosts = React.useMemo(() => {
    if (searchQuery) {
      const query = searchQuery.toLowerCase();
      return posts.filter(
        post =>
          post.title.toLowerCase().includes(query) ||
          post.excerpt.toLowerCase().includes(query) ||
          post.content.toLowerCase().includes(query) ||
          post.author.toLowerCase().includes(query)
      );
    }
    return posts;
  }, [posts, searchQuery]);

  // SEO title and description
  const pageTitle = searchQuery
    ? `Search Results for "${searchQuery}" | Artist Profiles`
    : 'Artist Profiles | Show Pony Gallery';

  const pageDescription = searchQuery
    ? `Browse artist profiles related to "${searchQuery}".`
    : 'Explore our featured artist profiles, showcasing the talented creators behind our exceptional collection.';

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:description" content={pageDescription} />
        <link rel="canonical" href="https://showponygallery.com/blog" />
      </Helmet>

      <div>
        {/* Search Results Header */}
        {searchQuery && (
          <div className="mb-8 pb-6 border-b">
            <h1 className="text-3xl font-medium mb-3">Search Results</h1>
            <p className="text-gray-600">
              Found {filteredPosts.length} {filteredPosts.length === 1 ? 'result' : 'results'} for "
              {searchQuery}"
            </p>
          </div>
        )}

        {/* Blog Header */}
        {!searchQuery && (
          <div className="mb-8 pb-6 border-b">
            <h1 className="text-3xl font-medium mb-3">Artist Profiles</h1>
            <p className="text-gray-600">
              Meet the talented artists behind our exceptional collection.
            </p>
          </div>
        )}

        {/* Loading State */}
        {loading && (
          <div className="space-y-8">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="animate-pulse">
                <div className="bg-gray-200 h-64 rounded-lg mb-4"></div>
                <div className="bg-gray-200 h-8 w-3/4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
              </div>
            ))}
          </div>
        )}

        {/* Error State */}
        {error && (
          <div className="text-center py-12">
            <p className="text-red-600 mb-4">{error}</p>
            <Button>Retry</Button>
          </div>
        )}

        {/* No Posts State */}
        {!loading && !error && filteredPosts.length === 0 && (
          <div className="text-center py-12">
            {searchQuery ? (
              <>
                <div className="flex justify-center mb-4">
                  <Search className="w-12 h-12 text-gray-300" />
                </div>
                <p className="text-gray-600 mb-4">
                  No artist profiles found matching "{searchQuery}".
                </p>
                <Link to="/blog">
                  <Button>View All Profiles</Button>
                </Link>
              </>
            ) : (
              <p className="text-gray-600 mb-4">No artist profiles available yet.</p>
            )}
          </div>
        )}

        {/* Posts List */}
        {!loading && !error && filteredPosts.length > 0 && (
          <div className="space-y-12">
            {filteredPosts.map(post => (
              <article key={post.slug} className="grid md:grid-cols-12 gap-6">
                {/* Featured Image */}
                <div className="md:col-span-5">
                  <Link
                    to={`/blog/${post.slug}`}
                    className="block aspect-[4/3] rounded-lg overflow-hidden"
                  >
                    <ImageHandler
                      src={post.featuredImage}
                      alt={post.title}
                      className="w-full h-full object-cover transition-transform hover:scale-105 duration-300"
                    />
                  </Link>
                </div>

                {/* Content */}
                <div className="md:col-span-7">
                  {/* Title */}
                  <h2 className="text-2xl font-medium mb-3">
                    <Link
                      to={`/blog/${post.slug}`}
                      className="hover:text-amber-800 transition-colors"
                    >
                      {post.title}
                    </Link>
                  </h2>

                  {/* Meta */}
                  <div className="flex flex-wrap items-center text-sm text-gray-500 mb-3 gap-2">
                    <span className="flex items-center">
                      <Calendar className="w-4 h-4 mr-1" />
                      {post.date}
                    </span>
                    <span className="hidden sm:inline mx-2">•</span>
                    <span className="flex items-center">
                      <User className="w-4 h-4 mr-1" />
                      {post.author}
                    </span>
                  </div>

                  {/* Excerpt */}
                  <p className="text-gray-600 mb-4 line-clamp-3">{post.excerpt}</p>

                  {/* Read More */}
                  <Link to={`/blog/${post.slug}`}>
                    <Button variant="secondary" size="sm">
                      Read Profile
                    </Button>
                  </Link>
                </div>
              </article>
            ))}
          </div>
        )}
      </div>
    </>
  );
};
