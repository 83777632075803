import React from 'react';
import { Link } from 'react-router-dom';
import { useEvents } from '../Events/hooks/useEvents';
import { ImageHandler } from '../ui/ImageHandler';
import { Button } from '../ui/Button';
import { Calendar, Clock, MapPin, ArrowRight } from 'lucide-react';
import { formatEventDate, isEventPast } from '../Events/utils';

export const EventsPreview: React.FC = () => {
  const { events, loading } = useEvents();

  // Get upcoming events only
  const upcomingEvents = events.filter(event => !isEventPast(event.date));

  // Sort by date (ascending)
  const sortedEvents = [...upcomingEvents].sort((a, b) => {
    return new Date(a.date).getTime() - new Date(b.date).getTime();
  });

  // Get the next 3 events
  const featuredEvents = sortedEvents.slice(0, 3);

  if (loading) {
    return (
      <section className="py-16 bg-stone-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-medium mb-4">Upcoming Events</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              Loading our upcoming exhibitions, openings, and special events...
            </p>
          </div>

          <div className="grid md:grid-cols-3 gap-8">
            {[...Array(3)].map((_, i) => (
              <div key={i} className="animate-pulse">
                <div className="bg-gray-200 h-48 rounded-lg mb-4"></div>
                <div className="bg-gray-200 h-6 w-3/4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 rounded mb-2"></div>
                <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
              </div>
            ))}
          </div>
        </div>
      </section>
    );
  }

  if (featuredEvents.length === 0) {
    return null;
  }

  return (
    <section className="py-16 bg-stone-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center mb-12">
          <h2 className="text-3xl font-medium mb-4">Upcoming Events</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Join us for exhibitions, openings, artist talks, and special events at Show Pony
            Gallery.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8">
          {featuredEvents.map(event => (
            <article
              key={event.slug}
              className="bg-white rounded-lg shadow-sm overflow-hidden h-full flex flex-col"
            >
              <Link to={`/events/${event.slug}`} className="block aspect-[16/9] overflow-hidden">
                <div className="aspect-placeholder" data-ratio="16:9">
                  <ImageHandler
                    src={event.image}
                    alt={event.title}
                    className="w-full h-full object-cover transition-transform hover:scale-105 duration-300"
                    loading="lazy"
                  />
                </div>

                {event.featured && (
                  <div className="absolute top-3 right-3 bg-amber-600 text-white text-xs font-medium px-2.5 py-1 rounded">
                    Featured
                  </div>
                )}
              </Link>

              <div className="p-6 flex-1 flex flex-col">
                <div className="mb-3">
                  <span className="inline-block bg-amber-100 text-amber-800 text-xs font-medium px-2.5 py-1 rounded">
                    {event.type}
                  </span>
                </div>

                <h3 className="text-xl font-medium mb-3">
                  <Link
                    to={`/events/${event.slug}`}
                    className="hover:text-amber-800 transition-colors"
                  >
                    {event.title}
                  </Link>
                </h3>

                <div className="space-y-2 mb-4 text-gray-600 text-sm">
                  <div className="flex items-start">
                    <Calendar className="w-4 h-4 mt-0.5 flex-shrink-0" />
                    <span className="ml-2">{formatEventDate(event.date, event.endDate)}</span>
                  </div>

                  {event.time && (
                    <div className="flex items-start">
                      <Clock className="w-4 h-4 mt-0.5 flex-shrink-0" />
                      <span className="ml-2">{event.time}</span>
                    </div>
                  )}

                  {event.location && (
                    <div className="flex items-start">
                      <MapPin className="w-4 h-4 mt-0.5 flex-shrink-0" />
                      <span className="ml-2">{event.location}</span>
                    </div>
                  )}
                </div>

                <p className="text-gray-600 mb-4 line-clamp-2 flex-1">{event.description}</p>

                <Link
                  to={`/events/${event.slug}`}
                  className="text-amber-800 hover:text-amber-900 font-medium text-sm inline-flex items-center mt-auto"
                >
                  Learn More
                  <ArrowRight className="ml-1 w-4 h-4" />
                </Link>
              </div>
            </article>
          ))}
        </div>

        <div className="text-center mt-12">
          <Link to="/events">
            <Button variant="secondary">View All Events</Button>
          </Link>
        </div>
      </div>
    </section>
  );
};
