import React from 'react';
import { Card } from '../ui/Card';
import { ExternalLink, Link as LinkIcon, ArrowUpRight } from 'lucide-react';

export const BacklinkStrategy: React.FC = () => {
  // Sample backlink opportunities
  const backlinkOpportunities = [
    {
      name: 'Art Forum',
      url: 'https://www.artforum.com',
      type: 'Directory',
      difficulty: 'Medium',
      notes: 'Submit gallery information to their gallery listings section',
    },
    {
      name: 'ArtNet',
      url: 'https://www.artnet.com',
      type: 'News Site',
      difficulty: 'High',
      notes: 'Pitch gallery exhibitions for coverage in their news section',
    },
    {
      name: 'Palm Beach Arts Paper',
      url: 'https://www.palmbeachartspaper.com',
      type: 'Local Publication',
      difficulty: 'Medium',
      notes: 'Contact for exhibition reviews and artist interviews',
    },
    {
      name: 'Art Basel Blog',
      url: 'https://www.artbasel.com/stories',
      type: 'Event Blog',
      difficulty: 'High',
      notes: 'Submit gallery artists for feature stories',
    },
    {
      name: 'Hyperallergic',
      url: 'https://hyperallergic.com',
      type: 'Art Blog',
      difficulty: 'Medium',
      notes: 'Pitch unique exhibitions and events for coverage',
    },
    {
      name: 'West Palm Beach Arts Directory',
      url: 'https://www.wpb.org/our-city/things-to-do/arts-and-culture',
      type: 'Local Directory',
      difficulty: 'Low',
      notes: 'Ensure gallery is listed in the local arts directory',
    },
  ];

  // Sample guest post opportunities
  const guestPostOpportunities = [
    {
      name: 'Art & Object',
      url: 'https://www.artandobject.com',
      topics: ['Collecting Tips', 'Art Market Trends', 'Artist Profiles'],
      notes: 'Accepts guest contributions from gallery owners and art experts',
    },
    {
      name: 'Artsy Editorial',
      url: 'https://www.artsy.net/articles',
      topics: ['Collector Guides', 'Market Analysis', 'Exhibition Reviews'],
      notes: 'Highly selective but offers excellent exposure',
    },
    {
      name: 'Create! Magazine',
      url: 'https://createmagazine.com',
      topics: ['Emerging Artists', 'Gallery Insights', 'Art Business'],
      notes: 'Focuses on contemporary art and emerging artists',
    },
  ];

  return (
    <div className="space-y-8">
      <div>
        <h2 className="text-2xl font-medium mb-4">Backlink Strategy</h2>
        <p className="text-gray-600 mb-6">
          Building high-quality backlinks from reputable art websites is essential for improving our
          gallery's search visibility. This document outlines our strategy for acquiring valuable
          backlinks from art directories, publications, and partner websites.
        </p>

        <Card className="p-6 mb-6">
          <h3 className="text-lg font-medium mb-3">Target Backlink Opportunities</h3>
          <div className="overflow-x-auto">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Source
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Type
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Difficulty
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Notes
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                  >
                    Action
                  </th>
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">
                {backlinkOpportunities.map((opportunity, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <div className="flex items-center">
                        <a
                          href={opportunity.url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="text-amber-800 hover:underline flex items-center"
                        >
                          {opportunity.name}
                          <ArrowUpRight className="ml-1 w-3 h-3" />
                        </a>
                      </div>
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      {opportunity.type}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap">
                      <span
                        className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${
                          opportunity.difficulty === 'Low'
                            ? 'bg-green-100 text-green-800'
                            : opportunity.difficulty === 'Medium'
                              ? 'bg-yellow-100 text-yellow-800'
                              : 'bg-red-100 text-red-800'
                        }`}
                      >
                        {opportunity.difficulty}
                      </span>
                    </td>
                    <td className="px-6 py-4 text-sm text-gray-500">{opportunity.notes}</td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                      <button className="text-amber-600 hover:text-amber-800">
                        <LinkIcon className="w-4 h-4" />
                      </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Card>

        <Card className="p-6">
          <h3 className="text-lg font-medium mb-3">Guest Post Opportunities</h3>
          <div className="space-y-4">
            {guestPostOpportunities.map((opportunity, index) => (
              <div key={index} className="border-b pb-4 last:border-0 last:pb-0">
                <div className="flex justify-between items-start">
                  <div>
                    <a
                      href={opportunity.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-amber-800 hover:underline flex items-center font-medium"
                    >
                      {opportunity.name}
                      <ExternalLink className="ml-1 w-3 h-3" />
                    </a>
                    <div className="mt-2 flex flex-wrap gap-2">
                      {opportunity.topics.map((topic, i) => (
                        <span
                          key={i}
                          className="bg-gray-100 text-gray-800 text-xs px-2 py-1 rounded"
                        >
                          {topic}
                        </span>
                      ))}
                    </div>
                  </div>
                  <button className="bg-amber-50 text-amber-800 px-3 py-1 rounded text-sm hover:bg-amber-100 transition-colors">
                    Pitch
                  </button>
                </div>
                <p className="text-sm text-gray-600 mt-2">{opportunity.notes}</p>
              </div>
            ))}
          </div>
        </Card>
      </div>

      <div>
        <h2 className="text-2xl font-medium mb-4">Implementation Plan</h2>
        <Card className="p-6">
          <ol className="space-y-4 list-decimal list-inside">
            <li className="text-gray-800">
              <span className="font-medium">Directory Submissions (Monthly)</span>
              <p className="text-gray-600 ml-6 mt-1">
                Submit the gallery to 2-3 art directories each month, focusing on those with high
                domain authority.
              </p>
            </li>
            <li className="text-gray-800">
              <span className="font-medium">Guest Posts (Quarterly)</span>
              <p className="text-gray-600 ml-6 mt-1">
                Develop and pitch 1 high-quality guest post per quarter to targeted art
                publications.
              </p>
            </li>
            <li className="text-gray-800">
              <span className="font-medium">Local Partnerships (Ongoing)</span>
              <p className="text-gray-600 ml-6 mt-1">
                Develop relationships with local businesses, cultural institutions, and artists for
                mutual linking opportunities.
              </p>
            </li>
            <li className="text-gray-800">
              <span className="font-medium">Press Releases (For Major Events)</span>
              <p className="text-gray-600 ml-6 mt-1">
                Distribute press releases for major exhibitions and events to relevant art news
                outlets.
              </p>
            </li>
            <li className="text-gray-800">
              <span className="font-medium">Social Media Engagement (Weekly)</span>
              <p className="text-gray-600 ml-6 mt-1">
                Engage with art influencers and publications on social media to build relationships
                that may lead to backlinks.
              </p>
            </li>
            <li className="text-gray-800">
              <span className="font-medium">Monitoring & Reporting (Monthly)</span>
              <p className="text-gray-600 ml-6 mt-1">
                Track new backlinks, referral traffic, and search rankings to measure the
                effectiveness of our strategy.
              </p>
            </li>
          </ol>
        </Card>
      </div>
    </div>
  );
};
