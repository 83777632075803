import React from 'react';
import { categories } from '../../nav/categories';
import { useFormContext } from 'react-hook-form';

interface CategorySelectProps {
  selectedCategory: string;
  onCategoryChange: (category: string) => void;
  selectedSubcategory: string;
  onSubcategoryChange: (subcategory: string) => void;
  error?: { category?: string; subcategory?: string };
}

export const CategorySelect: React.FC<CategorySelectProps> = ({
  selectedCategory,
  onCategoryChange,
  selectedSubcategory,
  onSubcategoryChange,
  error,
}) => {
  const { register } = useFormContext();
  const selectedCategoryData = categories.find(cat => cat.name === selectedCategory);

  const handleCategoryChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newCategory = e.target.value;
    onCategoryChange(newCategory);
    onSubcategoryChange(''); // Reset subcategory when category changes
  };

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="category" className="block text-base font-medium text-gray-700 mb-2">
          Category *
        </label>
        <div className="relative">
          <select
            id="category"
            {...register('category', { required: 'Category is required' })}
            value={selectedCategory}
            onChange={handleCategoryChange}
            className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4 bg-white ${
              error?.category ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
            }`}
          >
            <option value="">Select a category</option>
            {categories.map(category => (
              <option key={category.name} value={category.name}>
                {category.name}
              </option>
            ))}
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              className="h-4 w-4 text-gray-400"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
        {error?.category && <p className="mt-1 text-sm text-red-600">{error.category}</p>}
      </div>

      {selectedCategoryData?.subcategories && (
        <div>
          <label htmlFor="subcategory" className="block text-base font-medium text-gray-700 mb-2">
            Subcategory *
          </label>
          <div className="relative">
            <select
              id="subcategory"
              {...register('subcategory', {
                required: selectedCategoryData ? 'Subcategory is required' : false,
              })}
              value={selectedSubcategory}
              onChange={e => onSubcategoryChange(e.target.value)}
              className={`mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4 bg-white ${
                error?.subcategory ? 'border-red-300 focus:border-red-500 focus:ring-red-500' : ''
              }`}
            >
              <option value="">Select a subcategory</option>
              {selectedCategoryData.subcategories.map(subcategory => (
                <option key={subcategory} value={subcategory} className="py-2">
                  {subcategory}
                </option>
              ))}
            </select>
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg
                className="h-4 w-4 text-gray-400"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
          {error?.subcategory && <p className="mt-1 text-sm text-red-600">{error.subcategory}</p>}
        </div>
      )}
    </div>
  );
};
