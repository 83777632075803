import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button } from '@/components/ui/Button';
import { BlogList } from './BlogList';
import { CategoryList } from './categories/CategoryList';
import { TagList } from './tags/TagList';

export const BlogManagement: React.FC = () => {
  const [activeTab, setActiveTab] = useState<'posts' | 'categories' | 'tags'>('posts');

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-serif">Blog Management</h1>
        {activeTab === 'posts' && (
          <Link to="/admin/blog/new">
            <Button>Add New Post</Button>
          </Link>
        )}
      </div>

      <div className="border-b border-gray-200">
        <nav className="-mb-px flex space-x-8">
          <button
            onClick={() => setActiveTab('posts')}
            className={`
              py-4 px-1 border-b-2 font-medium text-sm
              ${
                activeTab === 'posts'
                  ? 'border-amber-500 text-amber-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }
            `}
          >
            Posts
          </button>
          <button
            onClick={() => setActiveTab('categories')}
            className={`
              py-4 px-1 border-b-2 font-medium text-sm
              ${
                activeTab === 'categories'
                  ? 'border-amber-500 text-amber-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }
            `}
          >
            Categories
          </button>
          <button
            onClick={() => setActiveTab('tags')}
            className={`
              py-4 px-1 border-b-2 font-medium text-sm
              ${
                activeTab === 'tags'
                  ? 'border-amber-500 text-amber-600'
                  : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
              }
            `}
          >
            Tags
          </button>
        </nav>
      </div>

      <div>
        {activeTab === 'posts' && <BlogList />}
        {activeTab === 'categories' && <CategoryList />}
        {activeTab === 'tags' && <TagList />}
      </div>
    </div>
  );
};
