import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useBlogPosts } from './hooks/useBlogPosts';
import { ImageHandler } from '../ui/ImageHandler';
import { Button } from '../ui/Button';
import { Calendar, User, ArrowLeft, ArrowRight, Share2 } from 'lucide-react';
import { ShareButton } from '../ui/ShareButton';
import { Breadcrumbs } from '../ui/Breadcrumbs';
import { ProductCard } from '../FeaturedPieces/ProductCard';

export const BlogPost: React.FC = () => {
  const { slug } = useParams<{ slug: string }>();
  const { getPostBySlug, loading, error } = useBlogPosts();
  const navigate = useNavigate();
  const [selectedProduct, setSelectedProduct] = React.useState<any | null>(null);

  // Get post data
  const post = slug ? getPostBySlug(slug) : null;

  // Handle 404
  if (!loading && !error && !post) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center py-12">
          <h1 className="text-3xl font-medium mb-4">Artist Profile Not Found</h1>
          <p className="text-gray-600 mb-6">
            The profile you're looking for doesn't exist or has been moved.
          </p>
          <Link to="/blog">
            <Button>Back to Artist Profiles</Button>
          </Link>
        </div>
      </div>
    );
  }

  // Loading state
  if (loading) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="animate-pulse space-y-6">
          <div className="bg-gray-200 h-8 w-3/4 rounded mb-2"></div>
          <div className="bg-gray-200 h-64 rounded-lg mb-4"></div>
          <div className="bg-gray-200 h-4 rounded mb-2"></div>
          <div className="bg-gray-200 h-4 rounded mb-2"></div>
          <div className="bg-gray-200 h-4 w-1/2 rounded"></div>
        </div>
      </div>
    );
  }

  // Error state
  if (error) {
    return (
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
        <div className="text-center py-12">
          <p className="text-red-600 mb-4">{error}</p>
          <Button onClick={() => navigate(0)}>Retry</Button>
        </div>
      </div>
    );
  }

  if (!post) return null;

  // Get canonical URL
  const canonicalUrl = `https://showponygallery.com/blog/${post.slug}`;

  // Breadcrumb items
  const breadcrumbItems = [
    {
      label: 'Artist Profiles',
      href: '/blog',
    },
    {
      label: post.title,
    },
  ];

  return (
    <>
      <Helmet>
        <title>{post.title} | Artist Profiles | Show Pony Gallery</title>
        <meta name="description" content={post.excerpt} />
        <meta name="keywords" content="artist profile, fine art, gallery, West Palm Beach" />

        <meta property="og:title" content={`${post.title} | Artist Profiles | Show Pony Gallery`} />
        <meta property="og:description" content={post.excerpt} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={canonicalUrl} />
        <meta property="og:image" content={post.featuredImage} />

        <meta property="article:published_time" content={post.publishedDate} />
        <meta property="article:author" content={post.author} />

        <link rel="canonical" href={canonicalUrl} />
      </Helmet>

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8 md:py-12">
        <Breadcrumbs items={breadcrumbItems} className="mb-6" />

        <article>
          {/* Title */}
          <h1 className="text-3xl md:text-4xl font-medium mb-4">{post.title}</h1>

          {/* Meta */}
          <div className="flex flex-wrap items-center text-sm text-gray-500 mb-6">
            <span className="flex items-center mr-4">
              <Calendar className="w-4 h-4 mr-1" />
              {post.date}
            </span>
            <span className="flex items-center mr-4">
              <User className="w-4 h-4 mr-1" />
              {post.author}
            </span>
            <div className="ml-auto">
              <ShareButton
                title={post.title}
                description={post.excerpt}
                imageUrl={post.featuredImage}
              />
            </div>
          </div>

          {/* Featured Image */}
          <div className="mb-8 rounded-lg overflow-hidden">
            <ImageHandler
              src={post.featuredImage}
              alt={post.title}
              className="w-full h-auto object-cover"
              priority={true}
            />
          </div>

          {/* Content */}
          <div className="prose prose-stone max-w-none mb-12 whitespace-pre-wrap">
            {post.content.split('\n').map((paragraph, index) => (
              <p key={index}>{paragraph}</p>
            ))}
          </div>

          {/* Featured Works */}
          {post.relatedProducts && post.relatedProducts.length > 0 && (
            <div className="border-t pt-12 mb-12">
              <h2 className="text-2xl font-medium mb-6">Featured Works by the Artist</h2>
              <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6">
                {post.relatedProducts.map(product => (
                  <div
                    key={product.id}
                    className="group cursor-pointer"
                    onClick={() => setSelectedProduct(product)}
                    role="button"
                    tabIndex={0}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        e.preventDefault();
                        setSelectedProduct(product);
                      }
                    }}
                  >
                    <div className="aspect-[3/4] bg-white rounded-lg overflow-hidden">
                      <ImageHandler
                        src={product.images[0]}
                        alt={product.name}
                        className="w-full h-full object-contain hover:scale-105 transition-transform duration-300"
                      />
                      {product.sold_out && (
                        <div className="absolute inset-0 bg-black/50 backdrop-blur-sm flex items-center justify-center">
                          <div className="bg-red-500 text-white px-3 py-1 text-sm rounded-full transform -rotate-12 shadow-lg">
                            Sold
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="mt-4">
                      <h3 className="text-lg font-medium text-gray-900 line-clamp-1">
                        {product.name}
                      </h3>
                      <p className="text-lg font-medium text-gray-900 mt-1">
                        ${product.price.toLocaleString()}
                      </p>
                      <div className="flex items-center gap-1 mt-1 text-sm">
                        <span className="text-gray-600">{product.category}</span>
                        {product.subcategory && (
                          <>
                            <span className="text-gray-400">•</span>
                            <span className="text-gray-600">{product.subcategory}</span>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}

          {/* Navigation */}
          <div className="flex justify-between mt-12 pt-6 border-t">
            <Button
              variant="secondary"
              size="sm"
              onClick={() => navigate('/blog')}
              className="flex items-center gap-2"
            >
              <ArrowLeft className="w-4 h-4" />
              Back to Artist Profiles
            </Button>

            <Button
              variant="secondary"
              size="sm"
              onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}
              className="flex items-center gap-2"
            >
              Back to Top
              <ArrowRight className="w-4 h-4" />
            </Button>
          </div>
        </article>

        {/* Product Modal */}
        {selectedProduct && (
          <ProductCard
            product={selectedProduct}
            isSelected={true}
            onClose={() => setSelectedProduct(null)}
          />
        )}
      </div>
    </>
  );
};
