import React, { useState, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet-async';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useProductData } from './FeaturedPieces/hooks/useProductData';
import { ProductCard } from './FeaturedPieces/ProductCard';
import { Button } from './ui/Button';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { Breadcrumbs } from './ui/Breadcrumbs';
import { ProductItem } from './Collection/ProductItem';

interface CollectionProps {
  showTitle?: boolean;
  website?: 'showponygallery.com' | 'showponypalmbeach.com';
}

export const Collection: React.FC<CollectionProps> = ({
  showTitle = true,
  website = 'showponygallery.com',
}) => {
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const {
    products,
    loading,
    error,
    activeCategory,
    activeSubcategory,
    filterProductsByParams,
    retry,
  } = useProductData(website);
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [retryCount, setRetryCount] = useState<Record<string, number>>({});
  const collectionRef = useRef<HTMLDivElement>(null);

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(12);

  // Calculate pagination
  const totalPages = Math.ceil(products.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentProducts = products.slice(indexOfFirstItem, indexOfLastItem);

  // Apply filters from URL parameters on component mount and when URL changes
  useEffect(() => {
    const category = searchParams.get('category');
    const subcategory = searchParams.get('subcategory');
    const productId = searchParams.get('productId');
    const page = parseInt(searchParams.get('page') || '1');
    const perPage = parseInt(searchParams.get('perPage') || '12');

    setCurrentPage(page);
    setItemsPerPage(perPage);

    if (productId) {
      filterProductsByParams(category || undefined, subcategory || null, productId);
    } else if (category) {
      filterProductsByParams(category, subcategory || null);
    }
  }, [searchParams, filterProductsByParams]);

  // Listen for openProductCard events
  useEffect(() => {
    const handleOpenProduct = (event: CustomEvent<{ product: any }>) => {
      setSelectedProduct(event.detail.product);
    };

    window.addEventListener('openProductCard', handleOpenProduct as EventListener);
    return () => {
      window.removeEventListener('openProductCard', handleOpenProduct as EventListener);
    };
  }, []);

  const getTitle = () => {
    if (activeSubcategory) {
      return `${activeSubcategory} ${activeCategory ? `in ${activeCategory}` : ''}`;
    }
    if (activeCategory) {
      return activeCategory;
    }
    return website === 'showponygallery.com' ? 'Our Collection' : 'Palm Beach Collection';
  };

  const getDescription = () => {
    if (activeSubcategory && activeCategory) {
      return `Explore our collection of ${activeSubcategory} ${activeCategory} pieces. Each item is carefully curated and authenticated.`;
    }
    if (activeCategory) {
      return `Discover our exceptional collection of ${activeCategory}. Browse unique pieces that bring character to your space.`;
    }
    return website === 'showponygallery.com'
      ? 'Browse our curated collection of fine art. Each piece tells a unique story.'
      : 'Explore our Palm Beach collection of fine art and distinguished acquisitions.';
  };

  const handleImageError = (productId: string, imageUrl: string) => {
    const currentRetries = retryCount[productId] || 0;
    if (currentRetries < 3) {
      setRetryCount(prev => ({
        ...prev,
        [productId]: currentRetries + 1,
      }));
      return `${imageUrl}?retry=${currentRetries + 1}&t=${Date.now()}`;
    }
    return imageUrl;
  };

  const handlePageChange = (page: number) => {
    setCurrentPage(page);

    // Update URL with pagination parameters
    const newParams = new URLSearchParams(searchParams);
    newParams.set('page', page.toString());
    setSearchParams(newParams);

    // Scroll to top of the collection
    if (collectionRef.current) {
      collectionRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  };

  const handleItemsPerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const newItemsPerPage = parseInt(e.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1); // Reset to first page when changing items per page

    // Update URL with pagination parameters
    const newParams = new URLSearchParams(searchParams);
    newParams.set('perPage', newItemsPerPage.toString());
    newParams.set('page', '1');
    setSearchParams(newParams);
  };

  const handleRetry = () => {
    retry();
  };

  // Generate breadcrumb items
  const breadcrumbItems = [];

  breadcrumbItems.push({
    label: website === 'showponygallery.com' ? 'Collection' : 'Palm Beach Collection',
    href: '/collection',
  });

  if (activeCategory) {
    breadcrumbItems.push({
      label: activeCategory,
      href: `/collection?category=${encodeURIComponent(activeCategory)}`,
    });
  }

  if (activeSubcategory) {
    breadcrumbItems.push({
      label: activeSubcategory,
    });
  }

  return (
    <>
      <Helmet>
        <title>{`${getTitle()} | Show Pony Gallery`}</title>
        <meta name="description" content={getDescription()} />
        <meta
          name="keywords"
          content={`${activeCategory || ''} ${activeSubcategory || ''} fine art, vintage, art gallery, West Palm Beach`.trim()}
        />

        <meta property="og:title" content={`${getTitle()} | Show Pony Gallery`} />
        <meta property="og:description" content={getDescription()} />
        {products[0]?.images[0] && <meta property="og:image" content={products[0].images[0]} />}

        <link rel="canonical" href={`https://${website}${location.pathname}${location.search}`} />

        <script type="application/ld+json">
          {JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'CollectionPage',
            name: getTitle(),
            description: getDescription(),
            provider: {
              '@type': 'ArtGallery',
              name: 'Show Pony Gallery',
              address: {
                '@type': 'PostalAddress',
                streetAddress: '5011 S Dixie Hwy',
                addressLocality: 'West Palm Beach',
                addressRegion: 'FL',
                postalCode: '33405',
                addressCountry: 'US',
              },
            },
            about: {
              '@type': 'Thing',
              name: activeCategory || 'Art Collection',
            },
          })}
        </script>
      </Helmet>

      <div
        ref={collectionRef}
        className={`min-h-screen ${showTitle ? 'py-8 md:py-12' : 'py-8 md:py-12'}`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {/* Breadcrumbs */}
          <Breadcrumbs items={breadcrumbItems} className="mb-4" />

          {showTitle && (
            <>
              <h1 className="text-3xl md:text-4xl font-medium mb-4">{getTitle()}</h1>
              <p className="text-gray-600 mb-8">{getDescription()}</p>
            </>
          )}

          {loading ? (
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
              {[...Array(12)].map((_, i) => (
                <div key={i} className="aspect-[3/4] bg-white rounded-lg animate-pulse" />
              ))}
            </div>
          ) : error ? (
            <div className="text-center py-12">
              <p className="text-red-600 mb-4">{error}</p>
              <Button onClick={handleRetry}>Retry</Button>
            </div>
          ) : products.length === 0 ? (
            <div className="text-center py-12 text-gray-500">
              No products available in this category.
            </div>
          ) : (
            <>
              {/* Pagination controls - top */}
              <div className="flex flex-col sm:flex-row justify-between items-center mb-6">
                <div className="mb-4 sm:mb-0">
                  <span className="text-gray-600">
                    Showing {indexOfFirstItem + 1}-{Math.min(indexOfLastItem, products.length)} of{' '}
                    {products.length} items
                  </span>
                </div>
                <div className="flex items-center gap-4">
                  <label htmlFor="itemsPerPage" className="text-gray-600 text-sm">
                    Items per page:
                  </label>
                  <select
                    id="itemsPerPage"
                    value={itemsPerPage}
                    onChange={handleItemsPerPageChange}
                    className="rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-sm"
                  >
                    <option value="12">12</option>
                    <option value="24">24</option>
                    <option value="36">36</option>
                    <option value="48">48</option>
                  </select>
                </div>
              </div>

              {/* Product grid */}
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 md:gap-6">
                {currentProducts.map(product => (
                  <ProductItem
                    key={product.id}
                    product={product}
                    onSelect={setSelectedProduct}
                    onImageError={handleImageError}
                  />
                ))}
              </div>

              {/* Pagination controls - bottom */}
              {totalPages > 1 && (
                <div className="mt-8 flex justify-center">
                  <nav className="flex items-center gap-1" aria-label="Pagination">
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
                      disabled={currentPage === 1}
                      className="flex items-center"
                      aria-label="Previous page"
                    >
                      <ChevronLeft className="w-4 h-4" />
                      <span className="sr-only sm:not-sr-only sm:ml-1">Previous</span>
                    </Button>

                    <div className="hidden sm:flex items-center gap-1 mx-2">
                      {Array.from({ length: Math.min(5, totalPages) }, (_, i) => {
                        // Show pages around current page
                        let pageNum;
                        if (totalPages <= 5) {
                          pageNum = i + 1;
                        } else if (currentPage <= 3) {
                          pageNum = i + 1;
                        } else if (currentPage >= totalPages - 2) {
                          pageNum = totalPages - 4 + i;
                        } else {
                          pageNum = currentPage - 2 + i;
                        }

                        return (
                          <Button
                            key={pageNum}
                            variant={currentPage === pageNum ? 'primary' : 'secondary'}
                            size="sm"
                            onClick={() => handlePageChange(pageNum)}
                            className="min-w-[36px]"
                            aria-label={`Page ${pageNum}`}
                            aria-current={currentPage === pageNum ? 'page' : undefined}
                          >
                            {pageNum}
                          </Button>
                        );
                      })}

                      {totalPages > 5 && currentPage < totalPages - 2 && (
                        <>
                          <span className="px-1">...</span>
                          <Button
                            variant="secondary"
                            size="sm"
                            onClick={() => handlePageChange(totalPages)}
                            className="min-w-[36px]"
                            aria-label={`Page ${totalPages}`}
                          >
                            {totalPages}
                          </Button>
                        </>
                      )}
                    </div>

                    {/* Mobile pagination indicator */}
                    <div className="sm:hidden px-2">
                      <span className="text-sm">
                        Page {currentPage} of {totalPages}
                      </span>
                    </div>

                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
                      disabled={currentPage === totalPages}
                      className="flex items-center"
                      aria-label="Next page"
                    >
                      <span className="sr-only sm:not-sr-only sm:mr-1">Next</span>
                      <ChevronRight className="w-4 h-4" />
                    </Button>
                  </nav>
                </div>
              )}
            </>
          )}
        </div>
      </div>

      {selectedProduct && (
        <ProductCard
          product={selectedProduct}
          isSelected={true}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </>
  );
};
