import React, { useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'sonner';
import { Event } from '@/components/Events/types';
import { createEvent, updateEvent } from '@/lib/events';
import { ImageUploader } from '@/components/ui/ImageUploader';
import { Button } from '@/components/ui/Button';
import { Calendar, Clock, MapPin, Users, DollarSign, Link as LinkIcon } from 'lucide-react';

interface EventFormProps {
  initialData?: Event;
  onSuccess?: () => void;
}

export const EventForm: React.FC<EventFormProps> = ({ initialData, onSuccess }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const methods = useForm({
    defaultValues: {
      title: initialData?.title || '',
      slug: initialData?.slug || '',
      description: initialData?.description || '',
      longDescription: initialData?.longDescription || '',
      image: initialData?.image || '',
      date: initialData?.date ? new Date(initialData.date).toISOString().split('T')[0] : '',
      endDate: initialData?.endDate
        ? new Date(initialData.endDate).toISOString().split('T')[0]
        : '',
      time: initialData?.time || '',
      location: initialData?.location || 'Show Pony Gallery',
      type: initialData?.type || 'Exhibition',
      featured: initialData?.featured || false,
      capacity: initialData?.capacity || '',
      price: initialData?.price || 0,
      ticketLink: initialData?.ticketLink || '',
      artists: initialData?.artists || [],
    },
  });

  const { handleSubmit, register, watch, setValue } = methods;
  const eventType = watch('type');

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      setError(null);

      if (initialData) {
        await updateEvent(initialData.id, data);
        toast.success('Event updated successfully');
      } else {
        await createEvent(data);
        toast.success('Event created successfully');
      }

      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      console.error('Form submission error:', error);
      setError(error.message);
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
            {error}
          </div>
        )}

        {/* Basic Information */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Event Title *
              </label>
              <input
                type="text"
                {...register('title', { required: 'Title is required' })}
                className="mt-1 block w-full rounded-md border-gray-300"
              />
            </div>

            <div>
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                URL Slug *
              </label>
              <input
                type="text"
                {...register('slug', { required: 'Slug is required' })}
                className="mt-1 block w-full rounded-md border-gray-300"
              />
            </div>

            <div>
              <label htmlFor="type" className="block text-sm font-medium text-gray-700">
                Event Type *
              </label>
              <select
                {...register('type', { required: 'Type is required' })}
                className="mt-1 block w-full rounded-md border-gray-300"
              >
                <option value="Exhibition">Exhibition</option>
                <option value="Opening Reception">Opening Reception</option>
                <option value="Artist Talk">Artist Talk</option>
                <option value="Workshop">Workshop</option>
                <option value="Special Event">Special Event</option>
              </select>
            </div>

            <div>
              <label htmlFor="description" className="block text-sm font-medium text-gray-700">
                Short Description *
              </label>
              <textarea
                {...register('description', { required: 'Description is required' })}
                rows={3}
                className="mt-1 block w-full rounded-md border-gray-300"
              />
            </div>

            <div>
              <label htmlFor="longDescription" className="block text-sm font-medium text-gray-700">
                Full Description (HTML)
              </label>
              <textarea
                {...register('longDescription')}
                rows={10}
                className="mt-1 block w-full rounded-md border-gray-300 font-mono text-sm"
              />
            </div>
          </div>
        </div>

        {/* Event Image */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Event Image</h3>
          <ImageUploader
            value={[watch('image')].filter(Boolean)}
            onChange={urls => setValue('image', urls[0])}
            maxImages={1}
          />
        </div>

        {/* Date and Time */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Date and Time</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="date" className="block text-sm font-medium text-gray-700">
                Start Date *
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="date"
                  {...register('date', { required: 'Start date is required' })}
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>

            <div>
              <label htmlFor="endDate" className="block text-sm font-medium text-gray-700">
                End Date
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Calendar className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="date"
                  {...register('endDate')}
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>

            <div>
              <label htmlFor="time" className="block text-sm font-medium text-gray-700">
                Time
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Clock className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  {...register('time')}
                  placeholder="e.g., 6:00 PM - 9:00 PM"
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>

            <div>
              <label htmlFor="location" className="block text-sm font-medium text-gray-700">
                Location
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <MapPin className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="text"
                  {...register('location')}
                  defaultValue="Show Pony Gallery"
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>
          </div>
        </div>

        {/* Event Details */}
        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Event Details</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label htmlFor="capacity" className="block text-sm font-medium text-gray-700">
                Capacity
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <Users className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  {...register('capacity')}
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>

            <div>
              <label htmlFor="price" className="block text-sm font-medium text-gray-700">
                Price
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <DollarSign className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="number"
                  step="0.01"
                  {...register('price')}
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>

            <div>
              <label htmlFor="ticketLink" className="block text-sm font-medium text-gray-700">
                Ticket Link
              </label>
              <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <LinkIcon className="h-5 w-5 text-gray-400" />
                </div>
                <input
                  type="url"
                  {...register('ticketLink')}
                  className="pl-10 block w-full rounded-md border-gray-300"
                />
              </div>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                {...register('featured')}
                className="h-4 w-4 text-amber-600 focus:ring-amber-500 border-gray-300 rounded"
              />
              <label htmlFor="featured" className="ml-2 block text-sm text-gray-700">
                Featured Event
              </label>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="flex justify-end">
          <Button type="submit" disabled={loading}>
            {loading ? 'Saving...' : initialData ? 'Update Event' : 'Create Event'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

export default EventForm;
