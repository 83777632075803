import { useState, useEffect, useCallback } from 'react';
import { supabase, checkSupabaseConnection } from '@/lib/supabase';
import { Product } from '@/components/FeaturedPieces/types';
import { debounce } from '@/lib/utils';
import { toast } from 'sonner';

export const useProductData = () => {
  const [products, setProducts] = useState<Product[]>([]);
  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeCategory, setActiveCategory] = useState<string | null>(null);
  const [activeSubcategory, setActiveSubcategory] = useState<string | null>(null);
  const [retryCount, setRetryCount] = useState(0);
  const MAX_RETRIES = 3;
  const RETRY_DELAY = 2000;

  const fetchProducts = useCallback(
    async (signal?: AbortSignal) => {
      // Don't retry if already at max attempts
      if (retryCount >= MAX_RETRIES) {
        setLoading(false);
        setError('Failed to load products after multiple attempts');
        toast.error('Failed to load products. Please refresh the page.');
        return;
      }

      try {
        if (signal?.aborted) return;

        setLoading(true);
        setError(null);

        // Check connection before attempting fetch
        const { connected } = await checkSupabaseConnection();
        if (!connected) {
          throw new Error('Unable to connect to database. Please check your internet connection.');
        }

        const { data, error: fetchError } = await supabase
          .from('products')
          .select('*')
          .eq('status', 'published')
          .order('created_at', { ascending: false });

        // Check if aborted after fetch
        if (signal?.aborted) return;

        if (fetchError) throw fetchError;

        // Validate products data
        const validProducts =
          data?.filter(
            product =>
              product &&
              product.category &&
              typeof product.category === 'string' &&
              Array.isArray(product.images) &&
              product.images.length > 0
          ) || [];

        setProducts(validProducts);
        setFilteredProducts(validProducts);
        setRetryCount(0);
        setError(null);
      } catch (error: any) {
        // Only handle error if not aborted
        if (!signal?.aborted) {
          console.error('Error fetching products:', error);

          if (error.name !== 'AbortError') {
            setError(error.message || 'Failed to load products');
            const delay = Math.min(RETRY_DELAY * Math.pow(2, retryCount), 8000);
            setRetryCount(prev => prev + 1);

            // Schedule retry
            const retryTimeout = setTimeout(() => {
              if (!signal?.aborted) {
                fetchProducts(signal);
              }
            }, delay);

            // Clean up timeout if aborted
            if (signal) {
              signal.addEventListener('abort', () => {
                clearTimeout(retryTimeout);
              });
            }
          }
        }
      } finally {
        if (!signal?.aborted) {
          setLoading(false);
        }
      }
    },
    [retryCount]
  );

  useEffect(() => {
    const controller = new AbortController();

    // Initial fetch
    fetchProducts(controller.signal);

    // Set up real-time subscription
    const subscription = supabase
      .channel('products_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'products',
        },
        () => {
          if (!controller.signal.aborted) {
            fetchProducts(controller.signal);
          }
        }
      )
      .subscribe(status => {
        if (status === 'CHANNEL_ERROR') {
          console.warn('Supabase channel error, retrying subscription...');

          const retryTimeout = setTimeout(() => {
            if (!controller.signal.aborted) {
              subscription.unsubscribe();

              supabase
                .channel('products_changes')
                .on(
                  'postgres_changes',
                  {
                    event: '*',
                    schema: 'public',
                    table: 'products',
                  },
                  () => {
                    if (!controller.signal.aborted) {
                      fetchProducts(controller.signal);
                    }
                  }
                )
                .subscribe();
            }
          }, 5000);

          // Clean up timeout if aborted
          controller.signal.addEventListener('abort', () => {
            clearTimeout(retryTimeout);
          });
        }
      });

    // Cleanup function
    return () => {
      controller.abort(); // Abort any pending fetches
      subscription.unsubscribe(); // Unsubscribe from real-time updates
    };
  }, [fetchProducts]);

  const filterProductsByParams = useCallback(
    (category?: string, subcategory?: string, productId?: string) => {
      if (productId) {
        const singleProduct = products.filter(product => product.id === productId);
        setFilteredProducts(singleProduct);
        setActiveCategory(null);
        setActiveSubcategory(null);
        return;
      }

      if (!category) {
        setFilteredProducts(products);
        setActiveCategory(null);
        setActiveSubcategory(null);
        return;
      }

      let filtered = [...products];

      filtered = filtered.filter(
        product => product.category.toLowerCase() === category.toLowerCase()
      );

      setActiveCategory(category);

      if (subcategory) {
        filtered = filtered.filter(
          product => product.subcategory?.toLowerCase() === subcategory.toLowerCase()
        );
        setActiveSubcategory(subcategory);
      } else {
        setActiveSubcategory(null);
      }

      setFilteredProducts(filtered);
    },
    [products]
  );

  return {
    products: filteredProducts,
    loading,
    error,
    activeCategory,
    activeSubcategory,
    retry: fetchProducts,
    filterProductsByParams,
  };
};
