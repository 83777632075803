import { useState, useEffect } from 'react';
import { supabase } from './supabase';
import { toast } from 'sonner';

interface User {
  id: string;
  email: string;
  isAdmin: boolean;
}

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const checkAuth = async () => {
      try {
        setIsLoading(true);
        setError(null);

        // Get current session
        const {
          data: { session },
          error: sessionError,
        } = await supabase.auth.getSession();

        if (sessionError) throw sessionError;

        if (!session?.user) {
          setUser(null);
          return;
        }

        // Check admin status
        const { data: profile, error: profileError } = await supabase
          .from('profiles')
          .select('is_admin')
          .eq('id', session.user.id)
          .single();

        if (profileError) {
          // If profile doesn't exist, create it
          if (profileError.code === 'PGRST116') {
            const { error: insertError } = await supabase
              .from('profiles')
              .insert([{ id: session.user.id, is_admin: false }]);

            if (insertError) throw insertError;

            setUser({
              id: session.user.id,
              email: session.user.email!,
              isAdmin: false,
            });
            return;
          }
          throw profileError;
        }

        if (!profile?.is_admin) {
          // If not admin, sign out
          await supabase.auth.signOut();
          setUser(null);
          throw new Error('Access denied. Admin privileges required.');
        }

        setUser({
          id: session.user.id,
          email: session.user.email!,
          isAdmin: true,
        });
      } catch (err: any) {
        console.error('Auth error:', err);
        setError(err.message);
        setUser(null);

        // Handle specific error cases
        if (err.message.includes('Access denied')) {
          toast.error('Access denied. Admin privileges required.');
        } else {
          toast.error('Authentication error. Please try again.');
        }
      } finally {
        setIsLoading(false);
      }
    };

    // Initial check
    checkAuth();

    // Set up auth state change listener
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === 'SIGNED_OUT' || event === 'USER_DELETED') {
        setUser(null);
        setError(null);
        return;
      }

      if (session?.user) {
        try {
          const { data: profile, error: profileError } = await supabase
            .from('profiles')
            .select('is_admin')
            .eq('id', session.user.id)
            .single();

          if (profileError) throw profileError;

          if (!profile?.is_admin) {
            await supabase.auth.signOut();
            setUser(null);
            throw new Error('Access denied. Admin privileges required.');
          }

          setUser({
            id: session.user.id,
            email: session.user.email!,
            isAdmin: true,
          });
          setError(null);
        } catch (err: any) {
          console.error('Profile fetch error:', err);
          setUser(null);
          setError(err.message);

          if (err.message.includes('Access denied')) {
            toast.error('Access denied. Admin privileges required.');
          }
        }
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return { user, isLoading, error };
};

export const signIn = async (email: string, password: string) => {
  try {
    const { data, error: authError } = await supabase.auth.signInWithPassword({
      email: email.trim(),
      password: password,
      options: {
        persistSession: true,
      },
    });

    if (authError) {
      if (authError.message.includes('Invalid login credentials')) {
        throw new Error('Invalid email or password');
      }
      throw authError;
    }

    if (!data.user) {
      throw new Error('No user data returned');
    }

    // Verify admin status
    const { data: profile, error: profileError } = await supabase
      .from('profiles')
      .select('is_admin')
      .eq('id', data.user.id)
      .single();

    if (profileError) {
      throw profileError;
    }

    if (!profile?.is_admin) {
      await supabase.auth.signOut();
      throw new Error('Access denied. Admin privileges required.');
    }

    return data;
  } catch (error: any) {
    console.error('Login error:', error);
    throw new Error(error.message || 'Invalid login credentials');
  }
};

export const signOut = async () => {
  try {
    const { error } = await supabase.auth.signOut();
    if (error) throw error;
    toast.success('Signed out successfully');
  } catch (error: any) {
    console.error('Sign out error:', error);
    throw error;
  }
};
