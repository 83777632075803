import { toast } from 'sonner';

const MAX_RETRIES = 3;
const RETRY_DELAY = 2000;

export const validateImageUrl = (url: string): boolean => {
  if (!url) return false;
  try {
    if (url.startsWith('data:')) return true;
    if (url.startsWith('blob:')) return true;
    if (url.startsWith('/')) return true;

    const validDomains = [
      'res.cloudinary.com',
      'tdhtppahgtuldkznzecg.supabase.co',
      'images.unsplash.com',
    ];

    const urlObj = new URL(url);
    return validDomains.some(domain => urlObj.hostname === domain);
  } catch (error) {
    console.warn('URL validation error:', error);
    return false;
  }
};

export const getOptimizedImageUrl = (url: string, width: number = 800): string => {
  if (!url) return '';

  try {
    // Handle Cloudinary URLs
    if (url.includes('res.cloudinary.com')) {
      const parts = url.split('/upload/');
      if (parts.length === 2) {
        return `${parts[0]}/upload/f_auto,q_auto,w_${width},c_limit/${parts[1]}`;
      }
      return url;
    }

    // Handle Supabase Storage URLs
    if (url.includes('supabase.co')) {
      // Remove any existing transformations
      const baseUrl = url.split('?')[0];
      return `${baseUrl}?width=${width}&quality=80&t=${Date.now()}`;
    }

    // Handle Unsplash URLs
    if (url.includes('images.unsplash.com')) {
      return `${url}&w=${width}&q=80&auto=format&fit=crop`;
    }

    return url;
  } catch (error) {
    console.warn('Error optimizing image URL:', error);
    return url;
  }
};

export const generateSrcSet = (url: string): string => {
  const widths = [400, 600, 800, 1200, 1600];
  return widths.map(w => `${getOptimizedImageUrl(url, w)} ${w}w`).join(', ');
};

export const getSizesAttribute = (defaultSize: string = '100vw'): string => {
  return '(max-width: 640px) 100vw, (max-width: 1024px) 50vw, 33vw';
};

export const handleImageError = async (url: string, retryCount: number = 0): Promise<string> => {
  if (retryCount >= MAX_RETRIES) {
    toast.error('Failed to load image after multiple attempts');
    return url;
  }

  try {
    const response = await fetch(url, {
      method: 'HEAD',
      cache: 'no-cache',
    });

    if (!response.ok) {
      throw new Error(`Failed to load image: ${response.status}`);
    }

    return url;
  } catch (error) {
    console.warn(`Image load error (attempt ${retryCount + 1}):`, error);

    const newUrl = url.includes('?') ? `${url}&t=${Date.now()}` : `${url}?t=${Date.now()}`;

    await new Promise(resolve => setTimeout(resolve, RETRY_DELAY * (retryCount + 1)));

    return handleImageError(newUrl, retryCount + 1);
  }
};
