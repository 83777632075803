import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { supabase } from '@/lib/supabase';
import { Button } from '../ui/Button';
import { Card } from '../ui/Card';
import { Toggle } from '../ui/Toggle';
import { Pencil, Trash2, Search, Loader2, Filter } from 'lucide-react';
import { ImageHandler } from '../ui/ImageHandler';
import { toast } from 'sonner';
import { debounce } from '@/lib/utils';

interface ProductListProps {
  filter?: string | null;
}

export const ProductList: React.FC<ProductListProps> = ({ filter }) => {
  const [products, setProducts] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [updatingStatus, setUpdatingStatus] = useState<string | null>(null);
  const [deleting, setDeleting] = useState<string | null>(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [searching, setSearching] = useState(false);
  const [statusFilter, setStatusFilter] = useState<'all' | 'published' | 'draft'>('all');

  const fetchProducts = React.useCallback(
    async (query?: string) => {
      try {
        setLoading(true);
        setError(null);

        let supabaseQuery = supabase.from('products').select('*');

        // Apply search filter if query exists
        if (query) {
          supabaseQuery = supabaseQuery.or(
            `name.ilike.%${query}%,` +
              `description.ilike.%${query}%,` +
              `category.ilike.%${query}%,` +
              `subcategory.ilike.%${query}%`
          );
        }

        // Apply status filter
        if (statusFilter !== 'all') {
          supabaseQuery = supabaseQuery.eq('status', statusFilter);
        }

        // Apply other filters
        if (filter) {
          if (filter === 'published') {
            supabaseQuery = supabaseQuery.eq('status', 'published');
          } else if (filter === 'draft') {
            supabaseQuery = supabaseQuery.eq('status', 'draft');
          } else if (filter === 'sold') {
            supabaseQuery = supabaseQuery.eq('sold_out', true);
          } else if (filter === 'available') {
            supabaseQuery = supabaseQuery.eq('status', 'published').eq('sold_out', false);
          } else if (filter.startsWith('category:')) {
            const category = filter.split(':')[1];
            supabaseQuery = supabaseQuery.eq('category', category);
          }
        }

        // Add ordering
        supabaseQuery = supabaseQuery.order('created_at', { ascending: false });

        const { data, error: fetchError } = await supabaseQuery;

        if (fetchError) throw fetchError;
        setProducts(data || []);
      } catch (error: any) {
        console.error('Error fetching products:', error);
        setError(error.message);
        toast.error('Failed to load products');
      } finally {
        setLoading(false);
        setSearching(false);
      }
    },
    [filter, statusFilter]
  );

  // Load products on mount and when filters change
  React.useEffect(() => {
    fetchProducts();
  }, [filter, statusFilter, fetchProducts]);

  // Debounced search function
  const debouncedSearch = React.useCallback(
    debounce((query: string) => {
      fetchProducts(query);
    }, 300),
    [fetchProducts]
  );

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    const query = e.target.value;
    setSearchQuery(query);
    setSearching(true);
    debouncedSearch(query);
  };

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this product?')) return;

    setDeleting(id);
    const toastId = toast.loading('Deleting product...');

    try {
      const product = products.find(p => p.id === id);

      // Delete all associated images from storage first
      if (product?.images?.length) {
        await Promise.all(
          product.images.map(async (url: string) => {
            if (url) {
              try {
                const path = url.split('/').pop();
                if (!path) throw new Error('Invalid image URL');

                const { error: storageError } = await supabase.storage
                  .from('images')
                  .remove([`products/${path}`]);

                if (storageError) throw storageError;
              } catch (error) {
                console.error('Error deleting image:', error);
              }
            }
          })
        );
      }

      // Delete the product record
      const { error: deleteError } = await supabase.from('products').delete().eq('id', id);

      if (deleteError) throw deleteError;

      setProducts(products.filter(p => p.id !== id));
      toast.success('Product deleted successfully', { id: toastId });
    } catch (error: any) {
      console.error('Error deleting product:', error);
      toast.error('Failed to delete product', { id: toastId });
    } finally {
      setDeleting(null);
    }
  };

  const handleStatusToggle = async (id: string, currentStatus: string) => {
    setUpdatingStatus(id);
    const toastId = toast.loading('Updating status...');

    try {
      const newStatus = currentStatus === 'published' ? 'draft' : 'published';
      const { error } = await supabase.from('products').update({ status: newStatus }).eq('id', id);

      if (error) throw error;

      setProducts(
        products.map(product => (product.id === id ? { ...product, status: newStatus } : product))
      );

      toast.success(
        newStatus === 'published' ? 'Product published successfully' : 'Product moved to drafts',
        { id: toastId }
      );
    } catch (error: any) {
      console.error('Error updating product status:', error);
      toast.error('Failed to update status', { id: toastId });
    } finally {
      setUpdatingStatus(null);
    }
  };

  const handleSoldToggle = async (id: string, currentlySold: boolean) => {
    try {
      const product = products.find(p => p.id === id);
      if (!product) return;

      const update = currentlySold
        ? {
            sold_at: null,
            sold_price: null,
            quantity: 1,
            sold_out: false,
          }
        : {
            sold_at: new Date().toISOString(),
            sold_price: product.price,
            quantity: 0,
            sold_out: true,
          };

      const { error } = await supabase.from('products').update(update).eq('id', id);

      if (error) throw error;

      setProducts(products.map(p => (p.id === id ? { ...p, ...update } : p)));

      toast.success(currentlySold ? 'Product marked as available' : 'Product marked as sold');
    } catch (error: any) {
      console.error('Error toggling sold status:', error);
      toast.error('Failed to update product status');
    }
  };

  if (loading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <Card className="p-6">
              <div className="flex items-center gap-4">
                <div className="w-24 h-24 bg-gray-200 rounded-lg"></div>
                <div className="flex-1">
                  <div className="h-6 bg-gray-200 w-3/4 rounded mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded mb-2"></div>
                  <div className="h-4 bg-gray-200 w-1/2 rounded"></div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 mb-4">{error}</p>
        <Button onClick={() => fetchProducts()}>Retry</Button>
      </div>
    );
  }

  return (
    <div className="space-y-4">
      {/* Search and Filter Controls */}
      <div className="flex flex-col sm:flex-row gap-4 items-center">
        {/* Search Bar */}
        <div className="relative flex-1">
          <input
            type="text"
            value={searchQuery}
            onChange={handleSearch}
            placeholder="Search products by name..."
            className="w-full pl-10 pr-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-stone-500 focus:border-stone-500"
          />
          <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
          {searching && (
            <div className="absolute right-3 top-1/2 transform -translate-y-1/2">
              <Loader2 className="w-4 h-4 text-stone-600 animate-spin" />
            </div>
          )}
        </div>

        {/* Status Filter */}
        <div className="flex items-center gap-2">
          <Filter className="w-4 h-4 text-gray-400" />
          <select
            value={statusFilter}
            onChange={e => setStatusFilter(e.target.value as any)}
            className="rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-sm"
          >
            <option value="all">All Status</option>
            <option value="published">Published</option>
            <option value="draft">Drafts</option>
          </select>
        </div>
      </div>

      {products.length === 0 ? (
        <div className="text-center py-12">
          {searchQuery ? (
            <p className="text-gray-500 mb-4">No products found matching "{searchQuery}"</p>
          ) : (
            <>
              <p className="text-gray-500 mb-4">No products found</p>
              <Link to="/admin/products/new">
                <Button>Add Your First Product</Button>
              </Link>
            </>
          )}
        </div>
      ) : (
        <div className="space-y-4">
          {products.map(product => (
            <Card key={product.id} className="p-4 sm:p-6">
              <div className="flex flex-col sm:flex-row gap-4 sm:gap-6">
                <div className="w-full sm:w-48 shrink-0">
                  <div className="aspect-square bg-gray-100 rounded-lg overflow-hidden">
                    <ImageHandler
                      src={product.images?.[0] || ''}
                      alt={product.name}
                      className="w-full h-full object-cover"
                    />
                  </div>
                </div>

                <div className="flex-1">
                  <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
                    <div>
                      <h3 className="text-xl font-medium">{product.name}</h3>
                      <p className="text-lg text-gray-700">${product.price}</p>
                      <p className="text-sm text-gray-600 mt-1">
                        {product.category}
                        {product.subcategory && ` • ${product.subcategory}`}
                      </p>
                      {product.sold_at && (
                        <p className="text-sm text-red-600 mt-1">
                          Sold on {new Date(product.sold_at).toLocaleDateString()}
                        </p>
                      )}
                    </div>
                    <div className="flex flex-col gap-2">
                      <Toggle
                        checked={product.status === 'published'}
                        onChange={() => handleStatusToggle(product.id, product.status)}
                        disabled={updatingStatus === product.id}
                        label={product.status === 'published' ? 'Published' : 'Draft'}
                      />
                      <Toggle
                        checked={!!product.sold_at}
                        onChange={() => handleSoldToggle(product.id, !!product.sold_at)}
                        label={product.sold_at ? 'Sold' : 'Available'}
                      />
                    </div>
                  </div>

                  <div className="flex flex-wrap gap-2 mt-4">
                    <Link to={`/admin/products/edit/${product.id}`}>
                      <Button size="sm" variant="secondary" className="flex items-center gap-1">
                        <Pencil size={14} />
                        Edit
                      </Button>
                    </Link>
                    <Button
                      variant="secondary"
                      size="sm"
                      onClick={() => handleDelete(product.id)}
                      disabled={deleting === product.id}
                      className="flex items-center gap-1 text-red-600 hover:text-red-700"
                    >
                      <Trash2 size={14} />
                      {deleting === product.id ? 'Deleting...' : 'Delete'}
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          ))}
        </div>
      )}
    </div>
  );
};
