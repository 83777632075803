import React, { useState, useEffect } from 'react';
import { ProductList } from './ProductList';
import { useAuth } from '@/lib/auth';
import { supabase } from '@/lib/supabase';
import { Card } from '../ui/Card';
import { toast } from 'sonner';
import {
  BarChart3,
  Package,
  Archive,
  ShoppingBag,
  Tags,
  Plus,
  Filter,
  X,
  DollarSign,
  Calendar,
  TrendingUp,
} from 'lucide-react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { Button } from '../ui/Button';
import { MigrateToCloudinary } from './MigrateToCloudinary';

interface ProductStats {
  total: number;
  published: number;
  draft: number;
  sold: number;
  available: number;
  totalValue: number;
  soldValue: number;
  categoryBreakdown: { [key: string]: number };
  recentActivity: {
    created: number;
    updated: number;
    sold: number;
  };
}

interface StatCardProps {
  title: string;
  value: number | string;
  icon: React.ReactNode;
  secondaryValue?: string;
  isActive?: boolean;
  onClick?: () => void;
  isMonetary?: boolean;
  trend?: number;
}

const StatCard: React.FC<StatCardProps> = ({
  title,
  value,
  icon,
  secondaryValue,
  isActive,
  onClick,
  isMonetary,
  trend,
}) => (
  <Card
    className={`p-6 bg-white cursor-pointer transition-all duration-200 hover:bg-gray-50 hover:shadow-md ${
      isActive ? 'ring-2 ring-stone-500' : ''
    }`}
    onClick={onClick}
  >
    <div className="flex items-start justify-between">
      <div>
        <h3 className="text-sm font-medium text-gray-500">{title}</h3>
        <p className="mt-2 text-3xl font-semibold text-gray-900">
          {isMonetary ? `$${Number(value).toLocaleString()}` : value}
        </p>
        {secondaryValue && <p className="mt-1 text-sm text-gray-500">{secondaryValue}</p>}
        {trend !== undefined && (
          <div
            className={`mt-1 text-sm flex items-center gap-1 ${
              trend > 0 ? 'text-green-600' : trend < 0 ? 'text-red-600' : 'text-gray-600'
            }`}
          >
            <TrendingUp className={`w-4 h-4 ${trend < 0 ? 'transform rotate-180' : ''}`} />
            <span>{Math.abs(trend)}% from last month</span>
          </div>
        )}
      </div>
      {icon}
    </div>
  </Card>
);

export const AdminDashboard = () => {
  const { user, isLoading } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [stats, setStats] = useState<ProductStats>({
    total: 0,
    published: 0,
    draft: 0,
    sold: 0,
    available: 0,
    totalValue: 0,
    soldValue: 0,
    categoryBreakdown: {},
    recentActivity: {
      created: 0,
      updated: 0,
      sold: 0,
    },
  });
  const [loadingStats, setLoadingStats] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [activeFilter, setActiveFilter] = useState<string | null>(null);
  const [timeRange, setTimeRange] = useState<'all' | 'month' | 'week'>('all');

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setLoadingStats(true);
        const now = new Date();
        const monthAgo = new Date(now.setMonth(now.getMonth() - 1));
        const weekAgo = new Date(now.setDate(now.getDate() - 7));

        // Fetch all products
        const { data: products, error: fetchError } = await supabase.from('products').select('*');

        if (fetchError) throw fetchError;

        if (products) {
          // Calculate basic stats
          const stats = products.reduce(
            (acc, product) => ({
              total: acc.total + 1,
              published: acc.published + (product.status === 'published' ? 1 : 0),
              draft: acc.draft + (product.status === 'draft' ? 1 : 0),
              sold: acc.sold + (product.sold_out ? 1 : 0),
              available:
                acc.available + (!product.sold_out && product.status === 'published' ? 1 : 0),
              totalValue: acc.totalValue + product.price,
              soldValue: acc.soldValue + (product.sold_out ? product.price : 0),
              categoryBreakdown: {
                ...acc.categoryBreakdown,
                [product.category]: (acc.categoryBreakdown[product.category] || 0) + 1,
              },
              recentActivity: {
                created:
                  acc.recentActivity.created + (new Date(product.created_at) > monthAgo ? 1 : 0),
                updated:
                  acc.recentActivity.updated + (new Date(product.updated_at) > monthAgo ? 1 : 0),
                sold:
                  acc.recentActivity.sold +
                  (product.sold_at && new Date(product.sold_at) > monthAgo ? 1 : 0),
              },
            }),
            {
              total: 0,
              published: 0,
              draft: 0,
              sold: 0,
              available: 0,
              totalValue: 0,
              soldValue: 0,
              categoryBreakdown: {},
              recentActivity: {
                created: 0,
                updated: 0,
                sold: 0,
              },
            }
          );

          setStats(stats);
        }
      } catch (error: any) {
        console.error('Error fetching stats:', error);
        setError('Failed to load product statistics');
        toast.error('Failed to load product statistics');
      } finally {
        setLoadingStats(false);
      }
    };

    // Set up real-time subscription
    const subscription = supabase
      .channel('products_changes')
      .on(
        'postgres_changes',
        {
          event: '*',
          schema: 'public',
          table: 'products',
        },
        () => {
          fetchStats();
        }
      )
      .subscribe();

    if (user?.isAdmin) {
      fetchStats();
    }

    return () => {
      subscription.unsubscribe();
    };
  }, [user]);

  // Parse the current filter from URL if any
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const filter = params.get('filter');
    setActiveFilter(filter);
  }, [location]);

  const handleFilterClick = (filter: string) => {
    if (activeFilter === filter) {
      // Clear filter if clicking the active one
      setActiveFilter(null);
      navigate('/admin/products');
    } else {
      setActiveFilter(filter);
      navigate(`/admin/products?filter=${filter}`);
    }
  };

  if (isLoading || loadingStats) {
    return (
      <div className="flex items-center justify-center p-8">
        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-stone-800"></div>
      </div>
    );
  }

  if (!user?.isAdmin) {
    return <div className="p-8">Access denied</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-12">
      <div className="mb-12">
        <div className="flex justify-between items-center mb-8">
          <h1 className="text-3xl font-serif">Dashboard</h1>
          <Link to="/admin/products/new">
            <Button className="flex items-center gap-2">
              <Plus className="w-4 h-4" />
              Add New Artwork
            </Button>
          </Link>
        </div>

        {/* Image Migration Tool */}
        <div className="mb-8">
          <MigrateToCloudinary />
        </div>

        {/* Main Stats Grid */}
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          <StatCard
            title="Total Products"
            value={stats.total}
            icon={<Package className="w-8 h-8 text-gray-400" />}
            onClick={() => handleFilterClick('total')}
            isActive={activeFilter === 'total'}
          />

          <StatCard
            title="Available Products"
            value={stats.available}
            icon={<Tags className="w-8 h-8 text-green-200" />}
            secondaryValue="Published & In Stock"
            onClick={() => handleFilterClick('available')}
            isActive={activeFilter === 'available'}
          />

          <StatCard
            title="Draft Products"
            value={stats.draft}
            icon={<Archive className="w-8 h-8 text-gray-200" />}
            onClick={() => handleFilterClick('draft')}
            isActive={activeFilter === 'draft'}
          />

          <StatCard
            title="Sold Products"
            value={stats.sold}
            icon={<ShoppingBag className="w-8 h-8 text-red-200" />}
            onClick={() => handleFilterClick('sold')}
            isActive={activeFilter === 'sold'}
          />
        </div>

        {/* Financial Stats */}
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mt-4">
          <StatCard
            title="Total Inventory Value"
            value={stats.totalValue}
            icon={<DollarSign className="w-8 h-8 text-emerald-200" />}
            isMonetary={true}
            trend={5} // Example trend value
          />

          <StatCard
            title="Total Sales Value"
            value={stats.soldValue}
            icon={<DollarSign className="w-8 h-8 text-blue-200" />}
            isMonetary={true}
            trend={12} // Example trend value
          />
        </div>

        {/* Recent Activity */}
        <div className="mt-8">
          <div className="flex items-center justify-between mb-4">
            <h2 className="text-lg font-medium text-gray-900">Recent Activity</h2>
            <select
              value={timeRange}
              onChange={e => setTimeRange(e.target.value as any)}
              className="rounded-md border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-sm"
            >
              <option value="week">Last 7 Days</option>
              <option value="month">Last 30 Days</option>
              <option value="all">All Time</option>
            </select>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
            <Card className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">New Products</p>
                  <p className="text-2xl font-semibold">{stats.recentActivity.created}</p>
                </div>
                <Calendar className="w-8 h-8 text-amber-200" />
              </div>
            </Card>

            <Card className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">Updated Products</p>
                  <p className="text-2xl font-semibold">{stats.recentActivity.updated}</p>
                </div>
                <BarChart3 className="w-8 h-8 text-blue-200" />
              </div>
            </Card>

            <Card className="p-4">
              <div className="flex items-center justify-between">
                <div>
                  <p className="text-sm text-gray-500">Products Sold</p>
                  <p className="text-2xl font-semibold">{stats.recentActivity.sold}</p>
                </div>
                <ShoppingBag className="w-8 h-8 text-green-200" />
              </div>
            </Card>
          </div>
        </div>

        {/* Category Breakdown */}
        {Object.keys(stats.categoryBreakdown).length > 0 && (
          <div className="mt-8">
            <div className="flex items-center justify-between mb-4">
              <h2 className="text-lg font-medium text-gray-900">Category Breakdown</h2>
              {activeFilter?.startsWith('category:') && (
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => handleFilterClick('total')}
                  className="flex items-center gap-2"
                >
                  <X className="w-4 h-4" />
                  Clear Category Filter
                </Button>
              )}
            </div>
            <div className="grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4">
              {Object.entries(stats.categoryBreakdown)
                .sort(([, a], [, b]) => b - a)
                .map(([category, count]) => (
                  <Card
                    key={category}
                    className={`p-4 bg-white cursor-pointer transition-all duration-200 hover:bg-gray-50 hover:shadow-md ${
                      activeFilter === `category:${category}` ? 'ring-2 ring-stone-500' : ''
                    }`}
                    onClick={() => handleFilterClick(`category:${category}`)}
                  >
                    <h3 className="text-sm font-medium text-gray-500">{category}</h3>
                    <p className="mt-1 text-2xl font-semibold text-gray-900">{count}</p>
                    <p className="mt-1 text-sm text-gray-500">
                      {Math.round((count / stats.total) * 100)}% of total
                    </p>
                  </Card>
                ))}
            </div>
          </div>
        )}
      </div>

      <div className="space-y-6">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-serif">Products</h2>
          <div className="flex items-center gap-4">
            {activeFilter && (
              <Button
                variant="secondary"
                size="sm"
                onClick={() => handleFilterClick(activeFilter)}
                className="flex items-center gap-2"
              >
                <Filter className="w-4 h-4" />
                {activeFilter === 'draft'
                  ? 'Showing Drafts'
                  : activeFilter === 'published'
                    ? 'Showing Published'
                    : activeFilter === 'sold'
                      ? 'Showing Sold'
                      : activeFilter === 'available'
                        ? 'Showing Available'
                        : activeFilter.startsWith('category:')
                          ? `Showing ${activeFilter.split(':')[1]}`
                          : 'Filtered View'}
                <X className="w-4 h-4" />
              </Button>
            )}
            <p className="text-sm text-gray-500">Showing {stats.total} total products</p>
          </div>
        </div>
        <ProductList filter={activeFilter} />
      </div>
    </div>
  );
};
