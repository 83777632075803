import React, { useState, useCallback } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { toast } from 'sonner';
import { supabase } from '@/lib/supabase';
import { ImageUploader } from '@/components/shared/ImageUploader';
import { Button } from '@/components/ui/Button';
import { generateMetaDescription } from '@/lib/utils/seo';
import { Copy, Search, Loader2, X } from 'lucide-react';
import { ImageHandler } from '@/components/ui/ImageHandler';
import { debounce } from '@/lib/utils';

interface BlogFormProps {
  onSuccess?: () => void;
  initialData?: any;
}

export const BlogForm: React.FC<BlogFormProps> = ({ onSuccess, initialData }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [savingStage, setSavingStage] = useState('');
  const [selectedProduct, setSelectedProduct] = useState<any | null>(null);
  const [relatedProducts, setRelatedProducts] = useState<string[]>(
    initialData?.related_products || []
  );
  const [productDetails, setProductDetails] = useState<any[]>([]);
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const [searching, setSearching] = useState(false);
  const [searchError, setSearchError] = useState<string | null>(null);

  const methods = useForm({
    defaultValues: {
      title: initialData?.title || '',
      slug: initialData?.slug || '',
      excerpt: initialData?.excerpt || '',
      content: initialData?.content || '',
      featured_image: initialData?.featured_image || '',
      status: initialData?.status || 'draft',
      meta_title: initialData?.meta_title || '',
      meta_description: initialData?.meta_description || '',
      meta_keywords: initialData?.meta_keywords || '',
      product_search: '',
      author: initialData?.author || 'Gallery Staff',
    },
  });

  const {
    handleSubmit,
    register,
    watch,
    setValue,
    formState: { errors },
  } = methods;
  const content = watch('content');
  const excerpt = watch('excerpt');
  const productSearch = watch('product_search');

  // Load related products on mount
  React.useEffect(() => {
    const loadRelatedProducts = async () => {
      if (!initialData?.id) return;

      try {
        const { data, error } = await supabase
          .from('blog_post_products')
          .select('product_id')
          .eq('post_id', initialData.id)
          .order('display_order');

        if (error) throw error;

        const productIds = data.map(item => item.product_id);
        setRelatedProducts(productIds);

        // Load product details
        if (productIds.length > 0) {
          const { data: products, error: productsError } = await supabase
            .from('products')
            .select('id, name, price, images, category, subcategory')
            .in('id', productIds);

          if (productsError) throw productsError;
          if (products) {
            setProductDetails(products);
          }
        }
      } catch (error: any) {
        console.error('Error loading related products:', error);
        toast.error('Failed to load related products');
      }
    };

    loadRelatedProducts();
  }, [initialData?.id]);

  // Debounced search function
  const searchProducts = React.useCallback(
    debounce(async (query: string) => {
      if (!query.trim()) {
        setSearchResults([]);
        setSearching(false);
        setSearchError(null);
        return;
      }

      setSearching(true);
      setSearchError(null);

      try {
        const { data, error } = await supabase
          .from('products')
          .select('id, name, price, images, category, subcategory')
          .ilike('name', `%${query}%`)
          .eq('status', 'published')
          .order('name')
          .limit(5);

        if (error) throw error;
        setSearchResults(data || []);
      } catch (error: any) {
        console.error('Error searching products:', error);
        setSearchError('Failed to search products');
        setSearchResults([]);
      } finally {
        setSearching(false);
      }
    }, 300),
    []
  );

  // Watch for search input changes
  React.useEffect(() => {
    searchProducts(productSearch);
  }, [productSearch]);

  const handleAddProduct = (product: any) => {
    if (!product) return;

    if (relatedProducts.includes(product.id)) {
      toast.error('Product already added');
      return;
    }

    setRelatedProducts(prev => [...prev, product.id]);
    setProductDetails(prev => [...prev, product]);
    setValue('product_search', '');
    setSearchResults([]);
    toast.success('Product added');
  };

  const handleRemoveProduct = (productId: string) => {
    setRelatedProducts(prev => prev.filter(id => id !== productId));
    setProductDetails(prev => prev.filter(product => product.id !== productId));
  };

  // Auto-generate meta description when content changes
  React.useEffect(() => {
    if (content && !methods.getValues('meta_description')) {
      const metaDesc = generateMetaDescription(content);
      setValue('meta_description', metaDesc);
    }
  }, [content, setValue, methods]);

  const onSubmit = async (data: any) => {
    if (loading) return;

    setLoading(true);
    setError(null);
    setSavingStage('Validating form data...');

    const toastId = toast.loading(initialData ? 'Updating post...' : 'Creating post...');

    try {
      // Get current user's profile
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authorized');

      // Get user's profile
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('id')
        .eq('id', user.id)
        .single();

      if (profileError) throw profileError;
      if (!profile) throw new Error('Profile not found');

      // Generate slug if not provided
      if (!data.slug) {
        data.slug = data.title
          .toLowerCase()
          .replace(/[^a-z0-9]+/g, '-')
          .replace(/(^-|-$)/g, '');
      }

      // Set meta title if not provided
      if (!data.meta_title) {
        data.meta_title = data.title;
      }

      // Set meta description if not provided
      if (!data.meta_description) {
        data.meta_description = generateMetaDescription(data.content);
      }

      // Set published_at date if publishing
      if (data.status === 'published' && !initialData?.published_at) {
        data.published_at = new Date().toISOString();
      }

      const postData = {
        ...data,
        author_id: profile.id,
        content: data.content.replace(/\r\n/g, '\n'), // Normalize line endings
      };

      // Remove product_search from postData as it's not a column
      delete postData.product_search;

      let postId = initialData?.id;

      if (initialData) {
        // Update existing post
        const { error: updateError } = await supabase
          .from('blog_posts')
          .update(postData)
          .eq('id', initialData.id);

        if (updateError) throw updateError;
      } else {
        // Create new post
        const { data: newPost, error: insertError } = await supabase
          .from('blog_posts')
          .insert([postData])
          .select()
          .single();

        if (insertError) throw insertError;
        postId = newPost.id;
      }

      // Update related products
      if (postId) {
        // First, remove all existing relations
        await supabase.from('blog_post_products').delete().eq('post_id', postId);

        // Then add new relations
        if (relatedProducts.length > 0) {
          const { error: productsError } = await supabase.from('blog_post_products').insert(
            relatedProducts.map((productId, index) => ({
              post_id: postId,
              product_id: productId,
              display_order: index,
            }))
          );

          if (productsError) throw productsError;
        }
      }

      toast.success(initialData ? 'Post updated successfully' : 'Post created successfully', {
        id: toastId,
      });

      if (onSuccess) {
        onSuccess();
      }
    } catch (error: any) {
      console.error('Form submission error:', error);
      setError(error.message);
      toast.error(error.message, { id: toastId });
    } finally {
      setLoading(false);
      setSavingStage('');
    }
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
        {error && (
          <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded">
            {error}
          </div>
        )}

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Featured Image</h3>
          <ImageUploader
            value={[methods.watch('featured_image')].filter(Boolean)}
            onChange={urls => setValue('featured_image', urls[0])}
            maxImages={1}
          />
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Basic Information</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="title" className="block text-sm font-medium text-gray-700">
                Title *
              </label>
              <input
                type="text"
                {...register('title', { required: 'Title is required' })}
                className="mt-1 block w-full rounded-md border-gray-300"
              />
              {errors.title && <p className="mt-1 text-sm text-red-600">{errors.title.message}</p>}
            </div>

            <div>
              <label htmlFor="author" className="block text-sm font-medium text-gray-700">
                Author
              </label>
              <input
                type="text"
                {...register('author')}
                placeholder="Gallery Staff"
                className="mt-1 block w-full rounded-md border-gray-300"
              />
            </div>

            <div>
              <label htmlFor="slug" className="block text-sm font-medium text-gray-700">
                Slug
              </label>
              <input
                type="text"
                {...register('slug')}
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder="Leave blank to auto-generate from title"
              />
            </div>

            <div>
              <label htmlFor="excerpt" className="block text-sm font-medium text-gray-700">
                Excerpt *
              </label>
              <div className="mt-1 relative">
                <textarea
                  {...register('excerpt', {
                    required: 'Excerpt is required',
                    maxLength: {
                      value: 1000,
                      message: 'Excerpt must be 1000 characters or less',
                    },
                  })}
                  rows={3}
                  className="block w-full rounded-md border-gray-300"
                />
                <div className="absolute bottom-2 right-2 text-xs text-gray-500">
                  {excerpt?.length || 0}/1000
                </div>
              </div>
              {errors.excerpt && (
                <p className="mt-1 text-sm text-red-600">{errors.excerpt.message}</p>
              )}
            </div>

            <div>
              <label htmlFor="content" className="block text-sm font-medium text-gray-700">
                Content *
              </label>
              <textarea
                {...register('content', { required: 'Content is required' })}
                rows={20}
                className="mt-1 block w-full rounded-md border-gray-300 font-mono text-sm whitespace-pre-wrap"
                placeholder="Enter your blog post content here..."
              />
              {errors.content && (
                <p className="mt-1 text-sm text-red-600">{errors.content.message}</p>
              )}
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Featured Works</h3>
          <div className="space-y-4">
            <div className="relative">
              <div className="flex items-center gap-2">
                <div className="relative flex-1">
                  <input
                    type="text"
                    {...register('product_search')}
                    placeholder="Search artworks by name..."
                    className="w-full pl-10 pr-4 py-2 rounded-md border-gray-300"
                  />
                  <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4" />
                  {searching && (
                    <Loader2 className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 w-4 h-4 animate-spin" />
                  )}
                </div>
              </div>

              {/* Search Results Dropdown */}
              {searchResults.length > 0 && (
                <div className="absolute z-10 mt-1 w-full bg-white rounded-md shadow-lg border border-gray-200 max-h-96 overflow-y-auto">
                  {searchResults.map(product => (
                    <button
                      key={product.id}
                      type="button"
                      onClick={() => handleAddProduct(product)}
                      className="w-full text-left px-4 py-2 hover:bg-gray-50 flex items-center gap-3"
                    >
                      <div className="w-12 h-12 bg-gray-100 rounded-md overflow-hidden flex-shrink-0">
                        <ImageHandler
                          src={product.images[0]}
                          alt={product.name}
                          className="w-full h-full object-contain"
                        />
                      </div>
                      <div className="flex-1 min-w-0">
                        <p className="font-medium text-sm truncate">{product.name}</p>
                        <p className="text-sm text-gray-500">${product.price.toLocaleString()}</p>
                        <p className="text-xs text-gray-400">
                          {product.category}
                          {product.subcategory && ` • ${product.subcategory}`}
                        </p>
                      </div>
                    </button>
                  ))}
                </div>
              )}

              {searchError && <p className="mt-2 text-sm text-red-600">{searchError}</p>}
            </div>

            {/* Selected Products */}
            {productDetails.length > 0 && (
              <div className="grid sm:grid-cols-2 gap-4">
                {productDetails.map(product => (
                  <div
                    key={product.id}
                    className="flex items-center gap-4 p-4 bg-gray-50 rounded-lg"
                  >
                    <div className="w-16 h-16 bg-white rounded-md overflow-hidden flex-shrink-0">
                      <ImageHandler
                        src={product.images[0]}
                        alt={product.name}
                        className="w-full h-full object-contain"
                      />
                    </div>
                    <div className="flex-1 min-w-0">
                      <h4 className="font-medium text-sm truncate">{product.name}</h4>
                      <p className="text-sm text-gray-500">${product.price.toLocaleString()}</p>
                      <p className="text-xs text-gray-400">
                        {product.category}
                        {product.subcategory && ` • ${product.subcategory}`}
                      </p>
                    </div>
                    <button
                      type="button"
                      onClick={() => handleRemoveProduct(product.id)}
                      className="p-1 text-gray-400 hover:text-red-500"
                    >
                      <X className="w-5 h-5" />
                    </button>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">SEO Settings</h3>
          <div className="space-y-4">
            <div>
              <label htmlFor="meta_title" className="block text-sm font-medium text-gray-700">
                Meta Title (Optional)
              </label>
              <input
                type="text"
                {...register('meta_title')}
                placeholder="Leave blank to use post title"
                className="mt-1 block w-full rounded-md border-gray-300"
              />
            </div>

            <div>
              <label htmlFor="meta_description" className="block text-sm font-medium text-gray-700">
                Meta Description (Optional)
              </label>
              <textarea
                {...register('meta_description')}
                rows={3}
                placeholder="Leave blank to auto-generate from excerpt"
                className="mt-1 block w-full rounded-md border-gray-300"
              />
            </div>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-medium text-gray-900 mb-4">Status</h3>
          <select {...register('status')} className="mt-1 block w-full rounded-md border-gray-300">
            <option value="draft">Draft</option>
            <option value="published">Published</option>
          </select>
        </div>

        <div className="flex justify-end">
          <Button type="submit" disabled={loading}>
            {loading ? 'Saving...' : initialData ? 'Update Post' : 'Create Post'}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};
