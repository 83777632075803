import React from 'react';
import { useProductData } from './FeaturedPieces/hooks/useProductData';
import { ProductCard } from './FeaturedPieces/ProductCard';
import { ImageHandler } from './ui/ImageHandler';

const FeaturedPieces = () => {
  const { products, loading } = useProductData();
  const [selectedProduct, setSelectedProduct] = React.useState<any | null>(null);

  // Get daily featured products (up to 12 available products)
  const featuredProducts = React.useMemo(() => {
    // Filter out sold products
    const availableProducts = products.filter(product => !product.sold_out);

    if (availableProducts.length === 0) return [];

    // Use the current date as a seed for consistent daily rotation
    const today = new Date();
    const seed = today.getFullYear() * 10000 + (today.getMonth() + 1) * 100 + today.getDate();

    // Fisher-Yates shuffle with seeded random
    const seededRandom = (n: number) => {
      const x = Math.sin(seed + n) * 10000;
      return x - Math.floor(x);
    };

    const shuffled = [...availableProducts];
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(seededRandom(i) * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    // Return first 12 products
    return shuffled.slice(0, 12);
  }, [products]);

  if (loading || featuredProducts.length === 0) {
    return null;
  }

  return (
    <section className="py-8 md:py-12 bg-stone-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <h2 className="text-2xl md:text-3xl font-medium text-center mb-6">Featured Pieces</h2>
        <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3 md:gap-6">
          {featuredProducts.map(product => (
            <div
              key={product.id}
              className="group cursor-pointer"
              onClick={() => setSelectedProduct(product)}
              role="button"
              tabIndex={0}
              onKeyDown={e => {
                if (e.key === 'Enter' || e.key === ' ') {
                  e.preventDefault();
                  setSelectedProduct(product);
                }
              }}
            >
              <div className="aspect-[3/4] bg-white rounded-lg overflow-hidden">
                <ImageHandler
                  src={product.images[0]}
                  alt={product.name}
                  className="w-full h-full object-contain hover:scale-105 transition-transform duration-300"
                />
              </div>
            </div>
          ))}
        </div>
      </div>

      {selectedProduct && (
        <ProductCard
          product={selectedProduct}
          isSelected={true}
          onClose={() => setSelectedProduct(null)}
        />
      )}
    </section>
  );
};

export default FeaturedPieces;
