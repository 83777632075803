import { supabase } from './supabase';
import { Event, Artist } from '@/components/Events/types';

export const createEvent = async (event: Omit<Event, 'id'>) => {
  try {
    // First create the event
    const { data: eventData, error: eventError } = await supabase
      .from('events')
      .insert([
        {
          title: event.title,
          slug: event.slug,
          description: event.description,
          long_description: event.longDescription,
          image: event.image,
          date: event.date,
          end_date: event.endDate,
          time: event.time,
          location: event.location,
          type: event.type,
          featured: event.featured,
          capacity: event.capacity,
          price: event.price,
          ticket_link: event.ticketLink,
        },
      ])
      .select()
      .single();

    if (eventError) throw eventError;

    // If there are artists, create them
    if (event.artists?.length) {
      const { error: artistsError } = await supabase.from('event_artists').insert(
        event.artists.map(artist => ({
          event_id: eventData.id,
          name: artist.name,
          bio: artist.bio,
          image: artist.image,
        }))
      );

      if (artistsError) throw artistsError;
    }

    return eventData;
  } catch (error) {
    console.error('Error creating event:', error);
    throw error;
  }
};

export const updateEvent = async (id: string, event: Partial<Event>) => {
  try {
    // Update the event
    const { error: eventError } = await supabase
      .from('events')
      .update({
        title: event.title,
        slug: event.slug,
        description: event.description,
        long_description: event.longDescription,
        image: event.image,
        date: event.date,
        end_date: event.endDate,
        time: event.time,
        location: event.location,
        type: event.type,
        featured: event.featured,
        capacity: event.capacity,
        price: event.price,
        ticket_link: event.ticketLink,
      })
      .eq('id', id);

    if (eventError) throw eventError;

    // If artists are provided, update them
    if (event.artists) {
      // First delete existing artists
      const { error: deleteError } = await supabase
        .from('event_artists')
        .delete()
        .eq('event_id', id);

      if (deleteError) throw deleteError;

      // Then insert new artists
      const { error: artistsError } = await supabase.from('event_artists').insert(
        event.artists.map(artist => ({
          event_id: id,
          name: artist.name,
          bio: artist.bio,
          image: artist.image,
        }))
      );

      if (artistsError) throw artistsError;
    }

    return true;
  } catch (error) {
    console.error('Error updating event:', error);
    throw error;
  }
};

export const deleteEvent = async (id: string) => {
  try {
    const { error } = await supabase.from('events').delete().eq('id', id);

    if (error) throw error;
    return true;
  } catch (error) {
    console.error('Error deleting event:', error);
    throw error;
  }
};

export const getEvent = async (slug: string): Promise<Event | null> => {
  try {
    // Get the event
    const { data: event, error: eventError } = await supabase
      .from('events')
      .select(
        `
        *,
        artists:event_artists(*)
      `
      )
      .eq('slug', slug)
      .single();

    if (eventError) throw eventError;
    if (!event) return null;

    // Transform the data to match our Event type
    return {
      id: event.id,
      title: event.title,
      slug: event.slug,
      description: event.description,
      longDescription: event.long_description,
      image: event.image,
      date: event.date,
      endDate: event.end_date,
      time: event.time,
      location: event.location,
      type: event.type,
      featured: event.featured,
      capacity: event.capacity,
      price: event.price,
      ticketLink: event.ticket_link,
      artists: event.artists as Artist[],
    };
  } catch (error) {
    console.error('Error getting event:', error);
    throw error;
  }
};

export const getEvents = async (): Promise<Event[]> => {
  try {
    const { data: events, error } = await supabase
      .from('events')
      .select(
        `
        *,
        artists:event_artists(*)
      `
      )
      .order('date', { ascending: true });

    if (error) throw error;

    // Transform the data to match our Event type
    return events.map(event => ({
      id: event.id,
      title: event.title,
      slug: event.slug,
      description: event.description,
      longDescription: event.long_description,
      image: event.image,
      date: event.date,
      endDate: event.end_date,
      time: event.time,
      location: event.location,
      type: event.type,
      featured: event.featured,
      capacity: event.capacity,
      price: event.price,
      ticketLink: event.ticket_link,
      artists: event.artists as Artist[],
    }));
  } catch (error) {
    console.error('Error getting events:', error);
    throw error;
  }
};
