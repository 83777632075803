import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import './index.css';

// Create router with future flags enabled
const router = createBrowserRouter(
  [
    {
      path: '/*',
      element: <App />,
    },
  ],
  {
    future: {
      v7_startTransition: true,
      v7_relativeSplatPath: true,
      v7_normalizeFormMethod: true,
    },
  }
);

// Initialize the app
const root = document.getElementById('root');
if (root) {
  createRoot(root).render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}

// Set up performance monitoring
if ('performance' in window && 'PerformanceObserver' in window) {
  // Create performance observer
  const perfObserver = new PerformanceObserver(list => {
    list.getEntries().forEach(entry => {
      // Log LCP (Largest Contentful Paint)
      if (entry.entryType === 'largest-contentful-paint') {
        console.debug('LCP:', Math.round(entry.startTime));
      }
      // Log FID (First Input Delay)
      if (entry.entryType === 'first-input') {
        console.debug('FID:', Math.round(entry.processingStart - entry.startTime));
      }
      // Log CLS (Cumulative Layout Shift)
      if (entry.entryType === 'layout-shift' && !entry.hadRecentInput) {
        console.debug('CLS contribution:', entry.value);
      }
    });
  });

  // Observe performance metrics
  perfObserver.observe({
    entryTypes: ['largest-contentful-paint', 'first-input', 'layout-shift'],
  });
}
