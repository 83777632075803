import React from 'react';
import { Helmet } from 'react-helmet-async';

const QRCodePage = () => {
  const address = '5011 S Dixie Hwy, West Palm Beach, FL 33405';
  const phone = '(917) 455-9307';
  const hours = {
    'Monday - Saturday': '11:00 AM - 5:00 PM',
    Sunday: 'Closed',
  };

  // Create vCard format with photo
  const vCardData = [
    'BEGIN:VCARD',
    'VERSION:3.0',
    'N:;Show Pony Gallery;;;',
    'FN:Show Pony Gallery',
    'ORG:Show Pony Gallery',
    'TITLE:Art Gallery',
    `ADR;TYPE=WORK:;;${address};;;`,
    `TEL;TYPE=WORK:${phone.replace(/\D/g, '')}`,
    'EMAIL:Showponygallerypb@gmail.com',
    'URL:https://showponygallery.com',
    // Add photo using our logo URL
    'PHOTO;VALUE=URI;TYPE=JPEG:https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg',
    'LOGO;VALUE=URI;TYPE=JPEG:https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg',
    'NOTE:Hours: Monday - Saturday 11:00 AM - 5:00 PM\\nSunday Closed\\nFine Art & Distinguished Acquisitions Gallery',
    'END:VCARD',
  ].join('\n');

  // Generate QR code URL using QR Code Generator API
  const qrCodeUrl = `https://api.qrserver.com/v1/create-qr-code/?size=300x300&data=${encodeURIComponent(vCardData)}`;

  return (
    <>
      <Helmet>
        <title>Contact Information | Show Pony Gallery</title>
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="min-h-screen bg-white py-8 px-4 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto">
          <div className="text-center mb-8">
            <img
              src="https://res.cloudinary.com/graphichut/image/upload/v1738340546/Show_Pony_-_Gallery_Logo_-_BW_fyzzlq.jpg"
              alt="Show Pony Gallery"
              className="h-16 w-auto mx-auto mb-4"
            />
            <h1 className="text-3xl font-medium text-gray-900">Show Pony Gallery</h1>
            <p className="mt-2 text-gray-600">Contact Information</p>
          </div>

          <div className="bg-white shadow-lg rounded-lg overflow-hidden">
            <div className="p-8">
              <div className="flex flex-col md:flex-row items-center justify-between gap-8">
                {/* QR Code */}
                <div className="w-full md:w-auto flex-shrink-0">
                  <img
                    src={qrCodeUrl}
                    alt="QR Code with contact information"
                    className="w-64 h-64 mx-auto"
                  />
                  <p className="text-sm text-center text-gray-500 mt-2">
                    Scan to save our contact information
                  </p>
                </div>

                {/* Contact Information */}
                <div className="flex-1 space-y-6">
                  <div>
                    <h2 className="text-lg font-medium text-gray-900">Address</h2>
                    <p className="mt-1 text-gray-600">{address}</p>
                    <a
                      href={`https://www.google.com/maps/dir/?api=1&destination=${encodeURIComponent(address)}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mt-2 inline-block text-sm text-amber-600 hover:text-amber-800"
                    >
                      Get Directions →
                    </a>
                  </div>

                  <div>
                    <h2 className="text-lg font-medium text-gray-900">Phone</h2>
                    <a
                      href={`tel:${phone.replace(/\D/g, '')}`}
                      className="mt-1 text-gray-600 hover:text-amber-800"
                    >
                      {phone}
                    </a>
                  </div>

                  <div>
                    <h2 className="text-lg font-medium text-gray-900">Hours</h2>
                    <dl className="mt-1 space-y-1">
                      {Object.entries(hours).map(([day, time]) => (
                        <div key={day} className="flex justify-between text-gray-600">
                          <dt>{day}</dt>
                          <dd>{time}</dd>
                        </div>
                      ))}
                    </dl>
                  </div>

                  <div>
                    <h2 className="text-lg font-medium text-gray-900">Email</h2>
                    <a
                      href="mailto:Showponygallerypb@gmail.com"
                      className="mt-1 text-gray-600 hover:text-amber-800"
                    >
                      Showponygallerypb@gmail.com
                    </a>
                  </div>
                </div>
              </div>

              {/* Print Button */}
              <div className="mt-8 text-center">
                <button
                  onClick={() => window.print()}
                  className="no-print inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500"
                >
                  Print Contact Information
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QRCodePage;
