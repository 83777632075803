import React from 'react';
import { Control, useController } from 'react-hook-form';

interface ConditionFieldProps {
  control: Control<any>;
  initialCondition?: string;
  initialConditionDetails?: string;
}

const PRODUCT_CONDITIONS = ['Excellent', 'Very Good', 'Good', 'Fair', 'Poor'] as const;

const STANDARD_CONDITION_TEXT =
  'In great as found condition. Minor scuffs and blemishes appropriate with age.';

export const ConditionField: React.FC<ConditionFieldProps> = ({
  control,
  initialCondition = 'Very Good',
  initialConditionDetails = '',
}) => {
  const { field: conditionField } = useController({
    name: 'condition',
    control,
    defaultValue: initialCondition,
  });

  const { field: conditionDetailsField } = useController({
    name: 'condition_details',
    control,
    defaultValue: initialConditionDetails,
  });

  const handleUseStandardText = () => {
    conditionDetailsField.onChange(STANDARD_CONDITION_TEXT);
  };

  return (
    <div className="space-y-6">
      <div>
        <label htmlFor="condition" className="block text-base font-medium text-gray-700 mb-2">
          Condition *
        </label>
        <select
          id="condition"
          {...conditionField}
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
        >
          {PRODUCT_CONDITIONS.map(condition => (
            <option key={condition} value={condition}>
              {condition}
            </option>
          ))}
        </select>
      </div>

      <div>
        <div className="flex justify-between items-center mb-2">
          <label htmlFor="condition_details" className="block text-base font-medium text-gray-700">
            Condition Details
          </label>
          <button
            type="button"
            onClick={handleUseStandardText}
            className="text-sm text-amber-600 hover:text-amber-700"
          >
            Use Standard Text
          </button>
        </div>
        <textarea
          id="condition_details"
          {...conditionDetailsField}
          rows={3}
          placeholder="Describe any specific condition details, marks, or wear..."
          className="mt-1 block w-full rounded-lg border-gray-300 shadow-sm focus:border-stone-500 focus:ring-stone-500 text-lg py-3 px-4"
        />
      </div>
    </div>
  );
};
