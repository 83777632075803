import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useEvents } from '@/components/Events/hooks/useEvents';
import { Card } from '@/components/ui/Card';
import { Button } from '@/components/ui/Button';
import { ImageHandler } from '@/components/ui/ImageHandler';
import { Calendar, Clock, MapPin, Pencil, Trash2, Star } from 'lucide-react';
import { formatEventDate, isEventPast } from '@/components/Events/utils';
import { toast } from 'sonner';
import { deleteEvent } from '@/lib/events';

export const EventList: React.FC = () => {
  const { events, loading, error } = useEvents();
  const [deleting, setDeleting] = useState<string | null>(null);

  const handleDelete = async (id: string) => {
    if (!confirm('Are you sure you want to delete this event?')) return;

    setDeleting(id);
    const toastId = toast.loading('Deleting event...');

    try {
      await deleteEvent(id);
      toast.success('Event deleted successfully', { id: toastId });
    } catch (error: any) {
      console.error('Error deleting event:', error);
      toast.error('Failed to delete event', { id: toastId });
    } finally {
      setDeleting(null);
    }
  };

  if (loading) {
    return (
      <div className="space-y-4">
        {[...Array(3)].map((_, i) => (
          <div key={i} className="animate-pulse">
            <Card className="p-6">
              <div className="flex items-center gap-4">
                <div className="w-24 h-24 bg-gray-200 rounded-lg"></div>
                <div className="flex-1">
                  <div className="h-6 bg-gray-200 w-3/4 rounded mb-2"></div>
                  <div className="h-4 bg-gray-200 rounded mb-2"></div>
                  <div className="h-4 bg-gray-200 w-1/2 rounded"></div>
                </div>
              </div>
            </Card>
          </div>
        ))}
      </div>
    );
  }

  if (error) {
    return (
      <div className="text-center py-12">
        <p className="text-red-600 mb-4">{error}</p>
        <Button>Retry</Button>
      </div>
    );
  }

  if (events.length === 0) {
    return (
      <div className="text-center py-12">
        <p className="text-gray-500 mb-4">No events found</p>
        <Link to="/admin/events/new">
          <Button>Create Your First Event</Button>
        </Link>
      </div>
    );
  }

  // Sort events by date
  const sortedEvents = [...events].sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  return (
    <div className="space-y-4">
      {sortedEvents.map(event => (
        <Card key={event.id} className="p-6">
          <div className="flex flex-col sm:flex-row gap-4">
            <div className="w-full sm:w-48 shrink-0">
              <div className="aspect-[16/9] sm:aspect-square bg-gray-100 rounded-lg overflow-hidden">
                <ImageHandler
                  src={event.image}
                  alt={event.title}
                  className="w-full h-full object-cover"
                />
              </div>
            </div>

            <div className="flex-1">
              <div className="flex flex-col sm:flex-row sm:items-start justify-between gap-4">
                <div>
                  <div className="flex items-center gap-2 mb-2">
                    <span
                      className={`inline-block px-2 py-1 text-xs font-medium rounded ${
                        isEventPast(event.date)
                          ? 'bg-gray-100 text-gray-800'
                          : 'bg-amber-100 text-amber-800'
                      }`}
                    >
                      {event.type}
                    </span>
                    {event.featured && (
                      <span className="inline-flex items-center text-amber-600">
                        <Star className="w-4 h-4" />
                        <span className="sr-only">Featured Event</span>
                      </span>
                    )}
                  </div>
                  <h3 className="text-xl font-medium">{event.title}</h3>
                  <div className="mt-2 space-y-1 text-sm text-gray-600">
                    <div className="flex items-center">
                      <Calendar className="w-4 h-4 mr-2" />
                      {formatEventDate(event.date, event.endDate)}
                    </div>
                    {event.time && (
                      <div className="flex items-center">
                        <Clock className="w-4 h-4 mr-2" />
                        {event.time}
                      </div>
                    )}
                    <div className="flex items-center">
                      <MapPin className="w-4 h-4 mr-2" />
                      {event.location || 'Show Pony Gallery'}
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap gap-2">
                  <Link to={`/admin/events/edit/${event.id}`}>
                    <Button size="sm" variant="secondary" className="flex items-center gap-1">
                      <Pencil size={14} />
                      Edit
                    </Button>
                  </Link>
                  <Button
                    variant="secondary"
                    size="sm"
                    onClick={() => handleDelete(event.id)}
                    disabled={deleting === event.id}
                    className="flex items-center gap-1 text-red-600 hover:text-red-700"
                  >
                    <Trash2 size={14} />
                    {deleting === event.id ? 'Deleting...' : 'Delete'}
                  </Button>
                </div>
              </div>

              <p className="text-gray-600 mt-2 line-clamp-2">{event.description}</p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};
